const ENQUIRY_OPTIONS = {
  Options: [
    // {
    //   name: "CreateNewRequest",
    //   label: "Create a new request",
    //   trans: "EnquiryOptions.CreateNewRequest",
    //   options: [
    //     {
    //       name: "NewRequest",
    //       label: "New Repair Service Request",
    //       trans: "EnquiryOptions.NewRequest",
    //     },
    //   ]
    // },
    {
      name: "NewRequest",
      label: "New Repair Service Request",
      trans: "EnquiryOptions.NewRequest",
    },
    {
      name: "In-WarrantyRequest",
      label: "Create an In-Warranty Request",
      trans: "EnquiryOptions.In-WarrantyRequest",
    },
    {
      name: "ResumeRequest",
      label: "Resume an existing Request",
      trans: "EnquiryOptions.ResumeRequest",
    },
    {
      name: "ChangeSchedule",
      label: "Change Pick-up / Return Schedule",
      trans: "EnquiryOptions.ChangeSchedule",
    },
    {
      name: "CancelRequest",
      label: "Cancel an existing request",
      trans: "EnquiryOptions.CancelRequest",
    },
    {
      name: "GeneralEnquiry",
      label: "General Enquiry",
      trans: "EnquiryOptions.GeneralEnquiry",
    },
  ],
};

export const NEW_ENQUIRY_OPTIONS = [
  {
    name: "NewRequest Battery",
    label: "Battery Replacement",
  },
  {
    name: "NewRequest Screen & Battery",
    label: "Screen Repair and Battery Replacement",
  },
]

export const EnumEnquiryModules = {
  NewRequest: 'NewRequest',
  InWarrantyRequest: 'In-WarrantyRequest',
  ResumeRequest: 'ResumeRequest',
  ChangeSchedule: 'ChangeSchedule',
  CancelRequest: 'CancelRequest',
  GeneralEnquiry: 'GeneralEnquiry',
  CreateNewRequest: 'CreateNewRequest',
  NewRequestBatteryReplacement: 'NewRequest Battery',
  NewRequestScreenAndBattery: 'NewRequest Screen & Battery'
};
//TODO - Revert
// export const INCIDENT_SCREEN_REPAIR = "Screen Repair";
// export const INCIDENT_SCREEN_REPAIR_WARRANTY = "Screen Repair Warranty";
export const INCIDENT_SCREEN_REPAIR = "Fault Repair";
export const INCIDENT_SCREEN_REPAIR_WARRANTY = "Fault Repair";
export const INCIDENT_BATTERY_REPLACEMENT = "Battery Replacement";
export const INCIDENT_BATTERY_AND_SCREEN = "Bat Scr Replacement";

export const PERIL_TYPES = {
  [INCIDENT_SCREEN_REPAIR]: INCIDENT_SCREEN_REPAIR,
  [INCIDENT_SCREEN_REPAIR_WARRANTY]: INCIDENT_SCREEN_REPAIR_WARRANTY,
  [INCIDENT_BATTERY_REPLACEMENT]: "Battery Replacement",
  [INCIDENT_BATTERY_AND_SCREEN]: "Screen Repair and Battery Replacement"
}

export const PUR = "PUR";
export const WALKIN = "WALKIN";

export const REPAIR_TYPES = {
  [PUR]: "Pick-up and Return",
  [WALKIN]: "Walk-in"
}

export const PROGRAM_NAME = "SmartSupport 3.0";
// export const PROGRAM_NAME = "Starhub - Smart Support 2.1 (Singapore)";

export default ENQUIRY_OPTIONS;
