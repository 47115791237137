import {
  addJourneyMessages,
  sleep,
} from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import {
  PROGRESS_DATA,
  ACTIVITY,
  API_PROGRESS,
  COMPONENTS,
} from "../../../../helpers/constants";
import {
  clearApiProgress,
  serviceUnavailable,
  updateApiProgress,
  updateFlowProgress,
} from "../../../../actions/flowWindow";
import updateVisitor from "../../../Appsync/updateVisitor";
import { confirmToCancelRequest } from "./cancelRequestOperator";
import { isEmpty } from "../../../../helpers/formatUtils";
import {
  getFulFillmentApi,
  processIncidentApi,
  setFulFillmentApi,
} from "../actions/actions";
import { getServiceFee } from "../actions/paymentApi";
import { initializePickupSchedule } from "./scheduleOperator";
import i18n from 'i18next';

export const submitUserAcceptanceOnTerms = (status) => async (
  dispatch,
  getStore
) => {
  const isCustomerAgreed = status === "Accept";
  let text = "",
    showComponent = "",
    module = "";
  if (isCustomerAgreed) {
    text = i18n.t("TermsAndConditions.AcceptTerms");
    
    dispatch(addJourneyMessages([createMessage("TEXT", "user", text)]));

    const {
      selectedAsset: {
        AssetCatalog: { AssetCatalogId },
        IMEI
      }
    } = getStore().claim.deviceConfirmation;
    const { CacheId } = getStore().session.sessionData.Configurations;
    const { triageDetails, comments } = getStore().claim.troubleshoot;
    const { caseType } = getStore().journeyMessages;

    const {
      ServiceRequestId,
      CustomerCaseId,
    } = getStore().serviceRequest.serviceRequestDetails;

    const screenRepairQuestions = triageDetails.concat({
      key: "TERMS_CHECKED",
      Ans: true,
    });

    await dispatch(
      updateApiProgress(i18n.t("Constants.API_PROGRESS.DATA"), 40, COMPONENTS.TERMS_N_CONDITIONS)
    );

    const processIncidentResponse = await dispatch(
      processIncidentApi(
        CacheId,
        CustomerCaseId,
        ServiceRequestId,
        caseType,
        AssetCatalogId,
        IMEI,
        screenRepairQuestions,
        comments
      )
    ).catch((err) => dispatch(serviceUnavailable()));
    if (isEmpty(processIncidentResponse)) return;

    let {
      CustomerCase,
      CustomerCase: { Eligibility: { EligibilityOutcome = "" } = {} } = {},
    } = processIncidentResponse.CreateIncidentResults;

    if (
      EligibilityOutcome === "Rejected" ||
      EligibilityOutcome === "Void" ||
      EligibilityOutcome === "VOID"
    ) {
      // device in-eligible
      module = "DEVICE_CONFIRMATION";
      showComponent = "deviceNotEligible";
    } else if (CustomerCase.Holds.length > 0) {
      // device hold
      module = "DEVICE_CONFIRMATION";
      showComponent = "deviceHold";
    } else {
      // let getFulFillmentApiResponse = await dispatch(
      //   getFulFillmentApi(CacheId, ServiceRequestId)
      // ).catch((err) => dispatch(serviceUnavailable()));
      // if (isEmpty(getFulFillmentApiResponse)) return;

      // let setFulFillmentApiResponse = await dispatch(
      //   setFulFillmentApi(caseType, CacheId, ServiceRequestId)
      // ).catch((err) => dispatch(serviceUnavailable()));
      // if (isEmpty(setFulFillmentApiResponse)) return;

      // calling service fee api here
      // let serviceFeeResponse = await dispatch(
      //   getServiceFee(CacheId, ServiceRequestId)
      // ).catch((err) => {
      //   console.log("getServiceFee ERROR", err)
      //   dispatch(serviceUnavailable())
      // });
      // if (isEmpty(serviceFeeResponse)) return;
      let encodedPerilString = encodeURIComponent(`\"${caseType.toUpperCase()}\"`);
      let serviceFeeResponse = await dispatch(getServiceFee(CacheId, ServiceRequestId, encodedPerilString))
      .catch((err) => {
        console.log("getServiceFee ERROR", err)
        dispatch(serviceUnavailable())
      });
      console.log("serviceFeeResposnse", serviceFeeResponse)
      if (isEmpty(serviceFeeResponse)) return;
      
      // await dispatch(initializePickupSchedule());
      // showComponent = "confirmPickupAddress";
      // module = "PICKUP_AND_DELIVERY";

      module = "SELECT_REPAIR_OPTION";
      showComponent = "SelectRepairOption";

      const initial = getStore().journeyMessages.flowProgress.percentage;
      
      // dispatch(
      //   updateFlowProgress(
      //     i18n.t("Constants.PROGRESS_DATA.CONFIRM_PICKUP_DETAILS.title"),
      //     i18n.t("Constants.PROGRESS_DATA.CONFIRM_PICKUP_DETAILS.count"),
      //     initial
      //   )
      // );

      dispatch(
        updateFlowProgress(
          i18n.t("Constants.PROGRESS_DATA.SELECT_REPAIR_OPTIONS.title"),
          i18n.t("Constants.PROGRESS_DATA.SELECT_REPAIR_OPTIONS.count"),
          initial
        )
      );
      dispatch(updateVisitor({ lastActivity: ACTIVITY.TERMS_N_CONDITIONS }));
    }
    await dispatch(
      updateApiProgress(
        i18n.t("Constants.API_PROGRESS.DATA_SUCCESS"),
        100,
        COMPONENTS.TERMS_N_CONDITIONS,
        40
      )
    );
    await sleep(1000);
    await dispatch(clearApiProgress());
  } else {
    text = i18n.t("TermsAndConditions.DeclineTerms");
    await dispatch(addJourneyMessages([createMessage("TEXT", "user", text)]));

    dispatch(confirmToCancelRequest("", "T&C declined", true));
  }

  if (!isEmpty(showComponent)) {
    dispatch(
      addJourneyMessages([createMessage(module, "system", { showComponent })])
    );
  }
};
