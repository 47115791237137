import React from "react";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
// import { ReactComponent as AgentUnavailableIcon } from "../../../images/true/icon-chat-unavailable.svg";
import { ReactComponent as AgentUnavailableIcon } from "../../../images/true/icon-agent-unavailable.svg";

const NoAgentsAvailable = () => {
  return (
    <div className="w-full my-8 px-5">
      {/* <BaseIcon icon={agentUnavailableIcon} name="Agent" center /> */}
      <AgentUnavailableIcon className="app-BaseIcon-center" />
      <div className="text-center text-20px leading-tight text-srgray-primary">
        Apologies, we do not have available agents at the moment.
        <br />
        Our chat support hours are between 8am – 10pm, Monday to Sunday.
        <br />
        Please try again later and thank you for connecting with us.
      </div>
    </div>
  );
};

export default NoAgentsAvailable;
