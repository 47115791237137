import React from "react";
import { ReactComponent as SmsIcon } from "../../../images/true/icon-sms.svg";
import { ReactComponent as EmailIcon } from "../../../images/true/icon-email.svg";
import { TextLight } from "../../../base/BaseText";

const ConfirmMobileNumber = {
  icon: <SmsIcon className="app-BaseIcon-center" />,
  title: (
    <TextLight>
      Could you please confirm if this is the registered phone number to receive confirmation SMS and for our courier to contact you regarding pick up and delivery?
    </TextLight>
  ),
};

const ConfirmEmail = {
  icon: <EmailIcon className="app-BaseIcon-center" />,
  title: (
    <TextLight>
      Is this your enrolled email address for email delivery notification?
    </TextLight>
  ),
};

const ChangeMobileNumber = {
  icon: <SmsIcon className="app-BaseIcon-center" />,
  title: (
    <TextLight>
      Please provide your mobile number to notify the status of your request by SMS after submission
    </TextLight>
  ),
};

const ChangeEmail = {
  icon: <EmailIcon className="app-BaseIcon-center" />,
  title: (
    <TextLight>
      Please provide your email address to notify the status of your request by
      email after submission
    </TextLight>
  ),
};

export default {
  ConfirmMobileNumber,
  ConfirmEmail,
  ChangeMobileNumber,
  ChangeEmail,
};
