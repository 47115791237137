import React from 'react'
import {useTranslation } from "react-i18next";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { richTextOptions } from '../../../helpers/formatUtils';

import { TextMedium } from "../../../base/BaseText";

function SubBanner({title, description}) {
    return(
      <div className="bg-ghost-white lg:flex flex-col justify-evenly items-center xl:px-40r md:px-20r px-8 py-16">
        <h2 className="px-8 lg:px-0 text-rblack leading-none lg:text-56px text-30px text-center leading-5">
          <TextMedium>{title}</TextMedium>
        </h2>
        <br/>
        <div>
          <div className="px-8 lg:px-0 text-rblack leading-normal lg:text-24px text-22px leading-5">
            <TextMedium className="">{documentToReactComponents(description, richTextOptions)}</TextMedium>
          </div>
        </div>
      </div>
    )
}

export default SubBanner