import React from "react";
import PropTypes from "prop-types";
import SubmitCardDetails from "./SubmitCardDetails";
import SubmitCardDetailsNew from "./SubmitCardDetailsNew";
import IncorrectCardDetails from "./IncorrectCardDetails";
import SelectPaymentMethod from "./SelectPaymentMethod";

const View = ({ showComponent }) => {
  let isBGBT = process.env.REACT_APP_IS_BGBT === "true";
  return (
    <>
      {showComponent === "SubmitCardDetails" &&
        <>
          {
            isBGBT
              ? (<SubmitCardDetailsNew />) 
              : (<SubmitCardDetails />)
          }
        </>        
      }
      {showComponent === "IncorrectCardDetails" && <IncorrectCardDetails />}
      {showComponent === "SelectPaymentMethod" && <SelectPaymentMethod />}
    </>
  );
};

View.propTypes = {
  showComponent: PropTypes.string.isRequired,
};

export default View;
