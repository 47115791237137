import React from 'react'
import { TextMedium } from '../../base/BaseText'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { richTextOptions } from '../../helpers/formatUtils';

function Body({title, content}) {
    console.log(title)
    console.log(content)
  return (
    <div className="flex flex-col flex-grow">
        <div className="flex-col flex gap-5 items-center lg:px-20r md:px-11r px-10 py-16">
            <h2 className="px-8 lg:px-0 text-rblack leading-none lg:text-72px text-30px text-center leading-5">
                <TextMedium>{title}</TextMedium>
            </h2>
            <div>{documentToReactComponents(content, richTextOptions)}</div>
        </div>
    </div>
  )
}

export default Body