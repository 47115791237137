import React from "react";
import { ReactComponent as AgentIcon } from "../images/true/icon-agent.svg";
import { TextMedium } from "../base/BaseText";

const FindingAgent = () => {
  return (
    <>
      <div className="h-40 mt-8 flex items-center justify-center overflow-hidden relative">
        <div className="z-50 p-2">
          {/* <img
            className="pb-6 w-32"
            src={agentIcon}
            alt="Agent"
            style={{ paddingRight: "0.9rem", paddingBottom: "1.2rem" }}
          /> */}
          <AgentIcon
            className="app-BaseIcon-center pb-6 w-32"
            style={{ paddingRight: "0.9rem", paddingBottom: "1.2rem" }}
          />
        </div>
        <div
          className="rounded-50% w-32 h-32 absolute opacity-50 bg-app-primary"
          style={{
            animation: "scaleIn 1.5s infinite cubic-bezier(.36, .11, .89, .32)",
          }}
        ></div>
      </div>
      <div className="text-center text-22px leading-none mb-8">
        <TextMedium>Connecting to an agent for you</TextMedium>
      </div>
    </>
  );
};

export default FindingAgent;
