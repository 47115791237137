const Config = {
  "AppSync": {
    "EndPoint": process.env.REACT_APP_GRAPHQL_ENDPOINT,
    "Region": process.env.REACT_APP_GRAPHQL_REGION,
    "AuthenticationType": process.env.REACT_APP_GRAPHQL_AUTHENTICATION_TYPE
  },
  "Cognito": {
    "IdentityPoolId": process.env.REACT_APP_COGNITO_IDENTITY_POOLID,
    "Region": process.env.REACT_APP_COGNITO_REGION,
    "UserPoolId": process.env.REACT_APP_COGNITO_USERPOOLID,
    "WebClientId": process.env.REACT_APP_COGNITO_USERPOOLWEBCLIENTID
  },
  "Twilio": {
    "ApiGatewayKey": process.env.REACT_APP_TWILIO_API_GATEWAY_KEY,
    "TaskName": process.env.REACT_APP_TWILIO_TASK_NAME,
    "EndPoint": process.env.REACT_APP_TWILIO_TASK_ENDPOINT,
    "Region": process.env.REACT_APP_TWILIO_TASK_REGION,
    "WorkflowId": process.env.REACT_APP_WORKFLOWS_ID,
    "Timeout": process.env.REACT_APP_TASK_TIMEOUT,
    "ChannelName": process.env.REACT_APP_TASK_CHANNEL_NAME,
    "SkillId": process.env.REACT_APP_SKILL_ID,
    "language": "en-US"
  },
  "WPAPIServer": {
    "baseUrl": process.env.REACT_APP_WP_BASE_ENDPOINT,
    "env": process.env.REACT_APP_ENV,
    "faq": process.env.REACT_APP_WP_FAQ_ENDPOINT,
    "prepare": process.env.REACT_APP_WP_PREPARE_ENDPOINT,
    "termsAndCondition": process.env.REACT_APP_WP_TERMS_AND_CONDITIONS_ENDPOINT,
    "termsOfUse": process.env.REACT_APP_WP_TERMS_OF_USE_ENDPOINT,
  },
  "Captcha": {
    "SiteKey": process.env.REACT_APP_CAPTCHA_SITE_KEY,
  },
  "App": {
    "Client": process.env.REACT_APP_CLIENT,
    "Country": process.env.REACT_APP_COUNTRY,
  },
  "TaskService": {
    "baseUrlSEA": process.env.REACT_APP_TS_BASE_ENDPOINT_SEA,
    "apiKey": process.env.REACT_APP_API_KEY_ASURION,
  },
  "GTM": {
    "gtmId": process.env.REACT_APP_GTM_ID
  }
}

export default Config;