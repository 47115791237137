import React from "react";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { useTranslation } from "react-i18next";
import { ReactComponent as RegPhoneIcon } from "../../../images/image-phone-huawei.svg";
import { toPascalCase } from "../../../helpers/formatUtils";
import { TextLight } from "../../../base/BaseText";
import BaseLabel from "../../../base/BaseLabel";

const SelectCurrentDevice = (props) => {
  const { t } = useTranslation();

  const registeredPhone = `${toPascalCase(props.deviceDetails.Make.Name)} ${
    props.deviceDetails.AssetCatalog.AssetCatalogName
  }`;

  const handleSelect = () => {
    props.handleSelect(props.deviceDetails);
  };

  return (
    <div className="flex-shrink-0 p-6 mx-auto border-solid border-1px border-grey2 rounded-lg md:max-w-xs">
      <BaseLabel>{props.data}</BaseLabel>
      <div className="flex mx-auto my-4">
        <div className="mr-4">
          <RegPhoneIcon />
        </div>

        <div className="text-lg">
          <TextLight>{registeredPhone}</TextLight>
        </div>
      </div>

      <div className="flex flex-col justify-around mt-5">
        <BaseButtonGroup>
          <BaseButton 
          text={t("SelectButton")}
          onClick={() => handleSelect()}
          disabled={props.disabled} />
        </BaseButtonGroup>
      </div>
    </div>
  );
};

export default SelectCurrentDevice;
