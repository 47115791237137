// Packages
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
// Base Components
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseInformationBox from "../../../base/BaseInformationBox";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { TextMedium, TextRegular } from "../../../base/BaseText";
import BaseTable, {
  BaseTR,
  BaseTH,
  BaseTD,
} from "../../../base/BaseTable/BaseTable";
// Icons
import { ReactComponent as CalendarCheckedIcon } from "../../../images/true/icon-calendar-selected.svg";
import { ReactComponent as DeliveryCalendarIcon } from "../../../images/true/icon-calendar-selected.svg";
// Operator
import { confirmationOfDateAndTime } from "../state/operators/scheduleOperator";
import { getFormmatedDate } from "../../../helpers/dateUtils";

const ConfirmDateTime = ({ isPickup }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const scheduleData = useSelector((state) => state.claim.schedule);
  const whichUpper = isPickup ? "Pick-up" : "Return";
  const dateIcon = isPickup ? (
    <CalendarCheckedIcon className="app-BaseIcon-center" />
  ) : (
    <DeliveryCalendarIcon className="app-BaseIcon-center" />
  );
  const which = whichUpper.toLowerCase();
  const selectedDate = isPickup
    ? scheduleData.selectedPickupDate
    : scheduleData.selectedDeliveryDate;
  const date = (
    <span>
      {`${getFormmatedDate(selectedDate, "d MMM yyyy")},`} 
      <br />
      {`${getFormmatedDate(selectedDate, "D")}`}
    </span>
  );
  const slot = isPickup
    ? scheduleData.selectedPickupSlot
    : scheduleData.selectedDeliverySlot;

  const [disabled, setDisabled] = useState(false);
  const [dateStatus, setDateStatus] = useState("");

  const handleSubmitPickupDateTime = (status) => {
    setDisabled(true);
    setDateStatus(status);
    dispatch(confirmationOfDateAndTime(status, isPickup));
  };

  return (
    <BaseCard>
      {/* <BaseIcon icon={dateIcon} name="Calendar" center /> */}
      {dateIcon}
      <BaseLabel className="font-bold leading-none">
        {which === "return" ? (
          <Trans i18nKey="ConfirmDateTime.ReturnTitle">
            <TextMedium>
            </TextMedium>
          </Trans>
        ) : (
          <Trans i18nKey="ConfirmDateTime.PickupTitle">
            <TextMedium>
            </TextMedium>
          </Trans>
        )
        }
        
      </BaseLabel>
      <BaseTable title={t(`ConfirmDateTime.TableTitle.${whichUpper}`)} verticle className="left-title">
        <BaseTR>
          <BaseTH>{whichUpper === 'Return' ? t("ConfirmDateTime.ConfirmReturnTable.ReturnDate") : t("ConfirmDateTime.ConfirmPickupTable.PickupDate")}</BaseTH>
          <BaseTD>{date}</BaseTD>
        </BaseTR>
        <BaseTR>
          <BaseTH>{whichUpper === 'Return' ? t("ConfirmDateTime.ConfirmReturnTable.ReturnTime") : t("ConfirmDateTime.ConfirmPickupTable.PickupTime")}</BaseTH>
          <BaseTD>{slot}</BaseTD>
        </BaseTR>
      </BaseTable>
      <BaseInformationBox className="text-20px text-app-nova leading-none">
        <TextRegular className="block">
          <Trans i18nKey="ConfirmDateTime.Information" />
        </TextRegular>
      </BaseInformationBox>
      <BaseButtonGroup>
        <BaseButton
          text={t("ChangeButton")}
          onClick={() => handleSubmitPickupDateTime("Change")}
          disabled={disabled}
          clicked={dateStatus === "Change"}
          className="bg-white border-app-primary text-app-primary"
        />
        <BaseButton
          className="bg-app-nova border-app-nova"
          text={t("ConfirmButton")}
          onClick={() => handleSubmitPickupDateTime("Confirm")}
          disabled={disabled}
          clicked={dateStatus === "Confirm"}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

ConfirmDateTime.propTypes = {
  isPickup: PropTypes.bool,
};

ConfirmDateTime.defaultProps = {
  isPickup: true,
};

export default ConfirmDateTime;
