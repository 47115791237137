import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
// import { ReactComponent as AgentUnavailableIcon } from "../../../images/icon-chat-unavailable.svg";
import { ReactComponent as AgentUnavailableIcon } from "../../../images/true/icon-agent-unavailable.svg";

import { closeAgentBusy, retryChat } from "../state/operators";

const AgentsBusy = () => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  const handleCloseClick = () => {
    dispatch(closeAgentBusy(t("CloseButton")));
    setDisabled(true);
  };

  const handleRetryClick = () => {
    dispatch(retryChat(t("RetryButton")));
    setDisabled(true);
  };

  return (
    <div className="w-full my-8 px-5">
      {/* <BaseIcon icon={agentUnavailableIcon} name="Agent" center /> */}
      <AgentUnavailableIcon className="app-BaseIcon-center" />
      <div className="text-center text-20px leading-tight text-srgray-primary">
        All the agents are busy at the moment. Please try again later.
        <br />
        Thank you for connecting Repair Service
      </div>
      <div className="w-full justify-center flex">
        <BaseButton
          text={t("RetryButton")}
          onClick={handleRetryClick}
          disabled={disabled}
          className="px-12 mt-8 mr-8"
        />
        <BaseButton
          text={t("CloseButton")}
          onClick={handleCloseClick}
          disabled={disabled}
          className="px-12 mt-8"
        />
      </div>
    </div>
  );
};

export default AgentsBusy;
