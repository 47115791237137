import {
  addJourneyMessages,
  sleep,
} from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import { createGeneralEnquiry, createInquiry } from "../../../../actions/cowrapperapi";
import {
  updateApiProgress,
  clearApiProgress,
} from "../../../../actions/flowWindow";
import {
  API_PROGRESS,
  COMPONENTS,
  ACTIVITY,
} from "../../../../helpers/constants";
import { udpateChatInputVisibility } from "../../../Appsync/state/operators";
import updateVisitor from "../../../Appsync/updateVisitor";
import { setChatReason } from "../../../Appsync/state/reducers";
import { getFormattedUserName } from "../../../../helpers/formatUtils";
import i18n from 'i18next';

export const helpNeededForInquiry = (helpNeeded) => (dispatch) => {
  dispatch(addJourneyMessages([createMessage("TEXT", "user", helpNeeded)]));
  const isHelpNeeded = helpNeeded === "Yes";
  if (isHelpNeeded) {
    dispatch(
      addJourneyMessages([
        createMessage("TEXT", "system", {
          key: "SystemMessage.ProvideEnquiryDetails",
        }),
        createMessage("GENERAL_ENQUIRY", "system", {
          showComponent: "ProvideEnquiryDetails",
        }),
      ])
    );
  } else {
    dispatch(
      addJourneyMessages([createMessage("ENQUIRY_OPTIONS", "system", {})])
    );
  }
};

export const checkIsExistingCustomer = (status) => async (dispatch) => {
  await dispatch(addJourneyMessages([createMessage("TEXT", "user", status)]));
  const isExistingCustomer = status === "Yes";
  if (isExistingCustomer) {
    await sleep(1000);
    await dispatch(
      addJourneyMessages([
        createMessage("NEF", "system", {
          showComponent: "CaptureUserInquiry",
        }),
      ])
    );
  } else {
    await sleep(1000);
    await dispatch(
      addJourneyMessages([
        createMessage("NEF", "system", {
          showComponent: "Faq",
        }),
      ])
    );
  }
};

export const saveEnquiryDetails = (note) => async (dispatch, getStore) => {
  dispatch(addJourneyMessages([createMessage("TEXT", "user", note)]));
  const client = process.env.REACT_APP_CLIENT;
  const country = process.env.REACT_APP_COUNTRY;
  const component = COMPONENTS.CREATE_ENQUIRY;
  const module = i18n.t("Constants.API_PROGRESS.CREATE_ENQUIRY");
  const module_success = i18n.t("Constants.API_PROGRESS.CREATE_ENQUIRY_SUCCESS");
  await dispatch(updateApiProgress(module, 40, component));
  const langCode = getStore().session.interactionData.Interaction.SessionData
    .LanguageCode;
  await dispatch(createGeneralEnquiry(client, country, langCode, note));
  dispatch(updateVisitor({ lastActivity: ACTIVITY.CREATE_ENQUIRY }));
  await dispatch(updateApiProgress(module_success, 100, component, 40));
  await sleep(1000);
  await dispatch(clearApiProgress());
  await dispatch(setChatReason("General Inquiry"));
  dispatch(udpateChatInputVisibility(true));
};

export const createUserInquiry = ({
  name,
  mdn,
  inquiryType,
  inquiryDesc,

}) => async (dispatch, getStore) => {

  await dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", {
        key: "UserInquiry.SubmitDetails",
      })
    ])
  );

  const { ClientId, ClientChannelId } = getStore().session.sessionData.Configurations;
  const CustomerContact = getStore().validation.inputData.mdn || "";
  const { CustomerCaseNumber } = getStore().serviceRequest.determineIncidentDetails || {};
  const customerName = getFormattedUserName(name)

  const params = {
    firstName: customerName.firstName,
    lastName: customerName.lastName,
    mdn: mdn,
    clientId: ClientId,
    clientChannelId: ClientChannelId,
    customerContact: CustomerContact,
    customerCaseNumber: CustomerCaseNumber,
    selectedInquiryType: "Enrolment",
    selectedSubInquiryType: inquiryType,
    CallDriverCategory: "NEF",
    inquiryDesc,
    UnnecessaryInquiry: false,
    resolveStatus: false,
    priority: "High"
  }

  await dispatch(updateApiProgress(i18n.t("Constants.API_PROGRESS.CREATE_ENQUIRY"), 40, COMPONENTS.CREATE_ENQUIRY));
  await dispatch(createInquiry(params));
  await dispatch(updateApiProgress(i18n.t("Constants.API_PROGRESS.CREATE_ENQUIRY_SUCCESS"), 100, COMPONENTS.CREATE_ENQUIRY, 40));
  await sleep(1000);
  await dispatch(clearApiProgress());
  await dispatch(
    addJourneyMessages([
      createMessage("TEXT", "system", {
        key: "SystemMessage.InquirySubmitted",
      })
    ])
  );
}