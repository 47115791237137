import React, { Fragment, useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { createClient } from 'contentful';
import Loader from "../../components/Loader/Loader";

import {
  resetReduxStore,
  toggleFlowWindowVisibility,
} from "../../actions/flowWindow";
import Flow from "../../container/Flow";

import trueLogo from "../../images/true/true-logo.svg";

import Footer from "./Footer";
import { showMaintenanceText } from "../../helpers/dateUtils";
import { Trans, useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { setLocale } from "../../reducers/journeyMessagesReducer";

import Banner from "./PrepareMyPhoneStaticContent/Banner";
import SubBanner from "./PrepareMyPhoneStaticContent/SubBanner";
import Content from "./PrepareMyPhoneStaticContent/Content";

const PreparePhonePage = () => {
  const dispatch = useDispatch();
  const [language, setLanguage] = useState(sessionStorage.getItem("language") || "th-TH");
  const [isLoading, setIsLoading] = useState(true)
  const [bannerData, setBannerData] = useState(null)
  const [subBannerData, setSubBannerData] = useState(null)
  const [contentData, setContentData] = useState(null)
  const showFlowWindow = useSelector(
    (state) => state.journeyMessages.showFlowWindow
  );

  const onToggleFlowWindow = () => {
    dispatch(toggleFlowWindowVisibility(!showFlowWindow));
    dispatch(resetReduxStore());
  };

  const onLanguageToggle = (e) => {
    e.preventDefault();
    let chosenLanguage = e.target.name;
    if (chosenLanguage !== language) {
      setLanguage(chosenLanguage);
      i18n.changeLanguage(chosenLanguage);
      sessionStorage.setItem("language", chosenLanguage);
      dispatch({
        type: setLocale.toString(),
        payload: chosenLanguage,
      });
    }
  }

  useEffect(() => {
    setIsLoading(true)
    const client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    });
    client.getEntry('vkAJ4DsDpZVQrkYQQjslF' , { locale: language }).then((response) => {
      console.log(response.fields)
      const {prepareMyDeviceBannerTitle, prepareMyDeviceBannerDescription, prepareMyDeviceBackupContent, prepareMyDeviceContentTurnOffContent, prepareMyDeviceContentBackupTitle, prepareMyDeviceContentBackupImage,
         prepareMyDeviceContentBackupDescription, prepareMyDeviceContentTurnOffDescription, prepareMyDeviceContentTurnOffImage,
          prepareMyDeviceContentTurnOffTitle, prepareMyDeviceSubBannerDescription, prepareMyDeviceSubBannerTitle
      } = response.fields
      setBannerData({
        title: prepareMyDeviceBannerTitle,
        description: prepareMyDeviceBannerDescription
      })
      setSubBannerData({
        title: prepareMyDeviceSubBannerTitle,
        description: prepareMyDeviceSubBannerDescription
      })

      setContentData([
        {
          title: prepareMyDeviceContentBackupTitle,
          description: prepareMyDeviceContentBackupDescription,
          image: prepareMyDeviceContentBackupImage,
          content: prepareMyDeviceBackupContent
        },
        {
          title: prepareMyDeviceContentTurnOffTitle,
          description: prepareMyDeviceContentTurnOffDescription,
          image: prepareMyDeviceContentTurnOffImage,
          content: prepareMyDeviceContentTurnOffContent
        }
      ])
      setIsLoading(false)
    });
  }, [language]);


  return (
  isLoading ? (<Loader/>) :(
    <Fragment>
      <div className="truerepair__home">
        <header className="box-border flex bg-white p-2">
          <div className="w-full sm:w-4/5 sm:mx-auto">
            <div className="flex justify-center">
            <a href="/" className="py-0 inline-block my-5 mr-auto"><img  src={trueLogo} alt="True Logo" style={{height: "50px"}}/></a>
            <div className="my-auto pr-4">
              <a onClick={onLanguageToggle} href="#" name="th-TH" className={`inline-block text-24px leading-none align-middle mr-4 ${language === "th-TH" ? "text-app-primary font-bold" : "text-black"}`}>ไทย</a>
              <span className="text-24px leading-none align-middle">|</span>
              <a onClick={onLanguageToggle} href="#" name="en-US" className={`inline-block text-24px leading-none align-middle ml-4 ${language === "en-US" ? "text-app-primary font-bold" : "text-black"}`}>English</a>
            </div>
            </div>
          </div>
        </header>
        {showMaintenanceText() && (
            <div>
            <marquee className="pt-2 font-bold text-3xl text-green-500">
              Dear customers, we will be performing our scheduled maintenance.
            </marquee>
          </div>
        )}
        <div
          className={classNames({
            "hidden lg:block xl:block": showFlowWindow,
          })}
        >
          <Banner title={bannerData.title} description={bannerData.description}/>
          <SubBanner title={subBannerData.title} description={subBannerData.description}/>
          <Content data={contentData}/>
          <Footer />
        </div>
      </div>

      {showFlowWindow ? (
        <Flow onToggleFlowWindow={onToggleFlowWindow} />
      ) : (<></>)}
    </Fragment>
    )
  );
};

export default PreparePhonePage;
