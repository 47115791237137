import { setTroubleshootAnswer, setComments } from "../reducers/troubleshootingReducer";
import { addJourneyMessages, sleep } from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import { API_PROGRESS, COMPONENTS, PROGRESS_DATA, ACTIVITY } from "../../../../helpers/constants";
import { clearApiProgress, updateApiProgress, updateFlowProgress } from "../../../../actions/flowWindow";
import updateVisitor from "../../../Appsync/updateVisitor";
import { confirmToCancelRequest } from "./cancelRequestOperator";
import i18n from 'i18next';

/**
 * This is old flow with question and answers,
 * wherein file used is Troubleshoot.js.old
 */

// export const submitTroubleshootAnswer = (
//   question,
//   status,
//   key,
//   cancelReason
// ) => async (dispatch, getStore) => {
//   const isConfirm = status === "Confirm";
//   let showComponent = "";
//   let module = "TROUBLESHOOT";

//   if (!isConfirm) {
//     dispatch(
//       addJourneyMessages([
//         createMessage("TEXT", "user", status),
//         createMessage("TEXT", "system", {
//           key: "SystemMessage.UnableToProcessRequest",
//         }),
//       ])
//     );
//     dispatch(confirmToCancelRequest("", cancelReason, true));
//     return;
//   }

//   switch (key) {
//     case "CameraWorking":
//       showComponent = "PowerOn";
//       break;
//     case "PowerOn":
//       showComponent = "LiquidExposure";
//       break;
//     case "LiquidExposure":
//       showComponent = "PhysicalDamage";
//       break;
//     case "PhysicalDamage":
//       const initial = getStore().journeyMessages.flowProgress.percentage;
//       dispatch(
//         updateFlowProgress(
//           i18n.t("Constants.PROGRESS_DATA.TERMS_N_CONDITIONS.title"),
//           i18n.t("Constants.PROGRESS_DATA.TERMS_N_CONDITIONS.count"),
//           initial
//         )
//       );
//       module = "TERMS_AND_CONDITIONS";
//       await dispatch(
//         updateApiProgress(
//           i18n.t("Constants.API_PROGRESS.TROUBLESHOOT"),
//           40,
//           COMPONENTS.TROUBLESHOOT,
//           0
//         )
//       );
//       await sleep(1000);
//       await dispatch(
//         updateApiProgress(
//           i18n.t("Constants.API_PROGRESS.TROUBLESHOOT_SUCCESS"),
//           100,
//           COMPONENTS.TROUBLESHOOT,
//           40
//         )
//       );
//       await sleep(1000);
//       await dispatch(clearApiProgress());
//       break;
//     default:
//       showComponent = "CameraWorking";
//       break;
//   }
//   dispatch(updateVisitor({ lastActivity: ACTIVITY.TROUBLESHOOT }));
//   dispatch(saveTroubleshootAnswer(key, isConfirm, question));
//   dispatch(
//     addJourneyMessages([
//       createMessage("TEXT", "user", status),
//       createMessage(module, "system", { showComponent }),
//     ])
//   );
// };

export const submitTroubleshootAnswer =
  (triageDetail = [], comments = "") =>
  async (dispatch, getStore) => {
    const status = triageDetail.some((triage) => !triage.value);
    
    if (status) {
      /**
       * Appending triage reasons comma separated
       */
      const cancelReasons = triageDetail
        .map(({ cancelReason }) => cancelReason)
        .filter((a) => a)
        .join(", ");

      dispatch(
        confirmToCancelRequest(
          {
            key: "DeviceSuitabilityChecked",
          },
          cancelReasons,
          true,
          "deviceNotEligibleAfterTroubleshoot"
        )
      );
      return;
    }

    const initial = getStore().journeyMessages.flowProgress.percentage;
    dispatch(
      updateFlowProgress(i18n.t("Constants.PROGRESS_DATA.TERMS_N_CONDITIONS.title"), 
      i18n.t("Constants.PROGRESS_DATA.TERMS_N_CONDITIONS.count"), 
      initial)
    );

    await dispatch(updateApiProgress(i18n.t("Constants.API_PROGRESS.TROUBLESHOOT"), 40, COMPONENTS.TROUBLESHOOT, 0));
    await sleep(500);
    await dispatch(updateApiProgress(i18n.t("Constants.API_PROGRESS.TROUBLESHOOT_SUCCESS"), 100, COMPONENTS.TROUBLESHOOT, 40));
    await dispatch(clearApiProgress());

    dispatch(updateVisitor({ lastActivity: ACTIVITY.TROUBLESHOOT }));

    for (let triage of triageDetail) {
      const { key, value: isConfirm, question } = triage;
      dispatch(saveTroubleshootAnswer(key, isConfirm, question));
    }

    dispatch(saveIWComments(comments));

    dispatch(
      addJourneyMessages([
        createMessage("TEXT", "user", {
          key: "DeviceSuitabilityChecked",
        }),
        createMessage("TERMS_AND_CONDITIONS", "system", { showComponent: "" }),
      ])
    );
  };

export const submitTroubleshootAnswers = () => async (dispatch, getStore) => {
  const { triageDetails } = getStore().claim.troubleshoot;
  const status = triageDetails.some((triage) => !triage.Ans);

  if (status) {
    /**
     * Appending triage reasons comma separated
     */
    const cancelReasons = triageDetails
      .map(({ cancelReason }) => cancelReason)
      .filter((a) => a)
      .join(", ");

    let module = "CANCEL_REPAIR";
    let showComponent = "requestCancelled";
    //let systemMsg = "RequestCancelled";
    let systemMsg = "UnableToProcessRequest";
    

    await dispatch(addJourneyMessages([createMessage(module, "system", { showComponent })]));
    await dispatch(
      addJourneyMessages([
        createMessage("TEXT", "system", {
          key: `SystemMessage.${systemMsg}`,
        }),
      ])
    );

    dispatch(
      confirmToCancelRequest(
        null,
        "",
        true,
        "deviceNotEligibleAfterTroubleshoot"
      )
    );
    return false;
  }
  
  return true;
};

export const submitTroubleshootOthers = () => async (dispatch, getStore) => {
  const initial = getStore().journeyMessages.flowProgress.percentage;
  dispatch(
    updateFlowProgress(
      i18n.t("Constants.PROGRESS_DATA.TERMS_N_CONDITIONS.title"), 
      i18n.t("Constants.PROGRESS_DATA.TERMS_N_CONDITIONS.count"), 
      initial
      )
  );

  await dispatch(updateApiProgress(i18n.t("Constants.API_PROGRESS.TROUBLESHOOT"), 40, COMPONENTS.TROUBLESHOOT, 0));
  await sleep(500);
  await dispatch(updateApiProgress(i18n.t("Constants.API_PROGRESS.TROUBLESHOOT_SUCCESS"), 100, COMPONENTS.TROUBLESHOOT, 40));
  await dispatch(clearApiProgress());

  dispatch(updateVisitor({ lastActivity: ACTIVITY.TROUBLESHOOT }));

  dispatch(
    addJourneyMessages([
      // createMessage("TEXT", "user", {
      //   key: "DeviceSuitabilityChecked",
      // }),
      createMessage("TERMS_AND_CONDITIONS", "system", { showComponent: "" }),
    ])
  );
}
export const saveTroubleshootAnswer = (key, value, question) => (dispatch) => {
  dispatch({
    type: setTroubleshootAnswer.toString(),
    payload: { key, Ans: value, Que: question },
  });
};

export const saveIWComments = (comments) => dispatch => {
  dispatch({
    type: setComments.toString(),
    payload: { comments }
  })
}