import React, { useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseCheckbox from "../../../base/BaseCheckbox/BaseCheckbox";
import BaseInformationBox from "../../../base/BaseInformationBox";
import { TextMedium } from "../../../base/BaseText";

import { ReactComponent as ContractIcon } from "../../../images/true/triage/troubleshoot-terms.svg";
import { submitUserAcceptanceOnTerms } from "../state/operators/termsAndConditionOperator";
import {
  INCIDENT_BATTERY_AND_SCREEN,
  INCIDENT_BATTERY_REPLACEMENT,
  PROGRAM_NAME,
} from "../../../components/EnquiryOptions/Constants";
import { newTAC, oldTAC } from "./termsConfig";

const TermsAndConditions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [status, setStatus] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [iAgree, setIAgree] = useState(false);
  const { caseType } = useSelector(
    (state) => state.journeyMessages
  );
  const { ClientOffer: programName } = useSelector(state => state.validation?.agreement);

  const isNewProgram = programName === PROGRAM_NAME;
  /**
   * For SS3.0, new terms and condition
   * For others, old terms and condition
   */
  let terms = oldTAC;
  if (isNewProgram) {
    terms = newTAC
  }

  const handleSubmitTC = (status) => {
    dispatch(submitUserAcceptanceOnTerms(status));
    setStatus(status);
    setDisabled(true);
  };

  /**
   * Depending on program name change TOC title
   */

  //const title = isNewProgram ? "TermsAndConditions.SS3.0" : "TermsAndConditions.Title"; 
  const title = "TermsAndConditions.True.CheckboxLabel"

  const checkboxLabel = (
    <BaseLabel className="w-full text-20px leading-none text-left my-0">
      <Trans i18nKey={title} />
    </BaseLabel>
  );

  return (
    <BaseCard>
      <ContractIcon className="app-BaseIcon-center" />
      <BaseLabel className="font-bold">
        <TextMedium>
        <Trans i18nKey="TermsAndConditions.Disclaimer" />
        </TextMedium>
      </BaseLabel>
      {/*<BaseInformationBox className="h-25 overflow-y-auto">
        {terms}
  </BaseInformationBox>*/}

      <BaseLabel className="pl-4 text-left">
        <TextMedium>
          <Trans i18nKey="TermsAndConditions.True.TermsTitle" />
        </TextMedium>
      </BaseLabel>

      <ul className="px-10 ml-2 mb-6 list-disc list-outside font-TMedium text-20px text-srgray-base leading-none">
        <li className="my-4">
          <Trans i18nKey="TermsAndConditions.True.Item1" />
        </li>
        <li className="my-4">
          <Trans i18nKey="TermsAndConditions.True.Item2" />
        </li>
        <li className="my-4">
          <Trans i18nKey="TermsAndConditions.True.Item3" />
        </li>
      </ul>

      <BaseCheckbox
        className={`px-3 pt-0 w-full text-20px leading-none ${disabled ? "cursor-not-allowed pointer-events-none" : ""}`}
        id="termsAndConditionCB"
        value={iAgree}
        onChange={() => setIAgree(!iAgree)}
        label={checkboxLabel}
      />
      <BaseButtonGroup>
        <BaseButton
          className={classNames("px-12 border-app-primary", {
            "disabled": !iAgree,
          })}
          text={t("AcceptButton")}
          disabled={disabled || !iAgree}
          onClick={() => handleSubmitTC("Accept")}
          clicked={status === "Accept"}
        />
        <BaseButton
          className="px-12 bg-white text-app-primary"
          text={t("DeclineButton")}
          disabled={disabled}
          onClick={() => handleSubmitTC("Decline")}
          clicked={status === "Decline"}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default TermsAndConditions;
