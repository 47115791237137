import { EnumEnquiryModules } from "../../../../components/EnquiryOptions/Constants";
import {
  API_PROGRESS,
  COMPONENTS,
  PROGRESS_DATA,
  ACTIVITY,
  ONLINE_JOURNEY_STATUS_LIST,
} from "../../../../helpers/constants";
import {
  updateApiProgress,
  clearApiProgress,
  updateFlowProgress,
  serviceUnavailable,
  endFlow,
} from "../../../../actions/flowWindow";
import {
  sleep,
  addJourneyMessages,
} from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import { confirmToCancelRequest } from "./cancelRequestOperator";
import { submitOrder } from "../actions/paymentApi";
import updateVisitor from "../../../Appsync/updateVisitor";
import { isEmpty } from "../../../../helpers/formatUtils";
import i18n from 'i18next';

export const confirmRequestDetailsSubmission = () => async (
  dispatch,
  getStore
) => {
  const enquireyOption = getStore().journeyMessages.enquiryOption;
  switch (enquireyOption) {
    case EnumEnquiryModules.CancelRequest:
      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "user", {key: "SystemMessage.CancelMyRepairRequest"}),
          createMessage("CANCEL_REPAIR", "system", {
            showComponent: "ConfirmRequestCancel",
          }),
        ])
      );
      break;
    case EnumEnquiryModules.NewRequest:
    case EnumEnquiryModules.NewRequestBatteryReplacement:
    case EnumEnquiryModules.NewRequestScreenAndBattery:
    case EnumEnquiryModules.InWarrantyRequest:
    case EnumEnquiryModules.ResumeRequest:
      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "user", {key:"ConfirmRepairDetails.NewRequest.Button"}),
          createMessage("TEXT", "system", {
            key: "SystemMessage.RequestSubmitted",
          }),
        ])
      );
      await dispatch(
        updateApiProgress(
          i18n.t("Constants.API_PROGRESS.REQUEST_SUBMIT"),
          40,
          COMPONENTS.CONFIRM_REQUEST_DETAILS,
          0
        )
      );

      // Call to Submit Request API
      const { CacheId } = getStore().session.sessionData.Configurations;
      // const submitOrderResponse = await dispatch(
      //   submitOrder(CacheId)
      // ).catch((err) => dispatch(serviceUnavailable()));
      // if (isEmpty(submitOrderResponse)) return;
      let submitOrderResponse = null;
      let retrycount = 0;
      while (retrycount < 2) {
        submitOrderResponse = await dispatch(submitOrder(CacheId))
          .catch((err) => { return null }
          );

        if (submitOrderResponse === null) {
          retrycount++;
        } else {
          retrycount = 2;
        }
      }
      // not allowing to go ahead if response is empty
      if (isEmpty(submitOrderResponse)) {
        dispatch(serviceUnavailable())
        return;
      }

      dispatch(
        updateVisitor({
          lastActivity: ACTIVITY.SUBMIT_REQUEST,
          journeyStatus: ONLINE_JOURNEY_STATUS_LIST.COMPLETED,
        })
      );

      await dispatch(
        updateApiProgress(
          i18n.t("Constants.API_PROGRESS.REQUEST_SUBMIT_SUCCESS"),
          100,
          COMPONENTS.CONFIRM_REQUEST_DETAILS,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());
      const initial = getStore().journeyMessages.flowProgress.percentage;
      dispatch(
        updateFlowProgress(
          i18n.t("Constants.PROGRESS_DATA.FEEDBACK.title"),
          i18n.t("Constants.PROGRESS_DATA.FEEDBACK.count"),
          initial
        )
      );
      dispatch(
        addJourneyMessages([
          createMessage("IMPORTANT_NOTE", "system", {}),
          createMessage("TEXT", "system", {
            key: "SystemMessage.ThankYourForUsingService",
          }),
        ])
      );
      dispatch(endFlow())
      break;
    default:
      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "user", {
            key: "SystemMessage.RequestDataConfirmed",
          }),
        ])
      );
      break;
  }
};

export const cancelRepairDetailsConfirmation = () => (dispatch) => {
  dispatch(
    addJourneyMessages([createMessage("TEXT", "user", {key: "SystemMessage.CancelRepairRequest"})])
  );
  dispatch(confirmToCancelRequest("", "Cancel-Confirmation page", true));
};
