import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseInformationBox from "../../../base/BaseInformationBox";
import BaseLabel from "../../../base/BaseLabel";
import { TextLight, TextMedium } from "../../../base/BaseText";

import { handleBackToMainMenu } from "../state/operators/deviceConfirmationOperators";
import { isInWarrantyClaim } from '../state/operators/contactsOperator';
import { ReactComponent as DeviceNotEligibleIcon } from "../../../images/icon-device-not-eligible.svg";
import { FAQ_LINK } from "../../../helpers/constants";

const WhyDeviceNotEligible = () => {
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { caseType, enquiryOption } = useSelector((state) => state.journeyMessages);
  const { WarrantyType:  warrantyType } = useSelector((state) => state.serviceRequest.serviceRequestDetails);
  
  const perilType = caseType && caseType.replace(/\s+/g, "");

  const handleClick = () => {
    setDisabled(true);
    dispatch(handleBackToMainMenu(t("WhyDeviceNotEligible.BackToMainMenu")));
    window.open(FAQ_LINK, "_blank");
  };

  const Reasons = () => {

   
    const reasonsByPeril = t(`WhyDeviceNotEligible.${perilType}.Reason`, {
      returnObjects: true,
      defaultValue: null,
    });

    if (!reasonsByPeril) {
      return null;
    }

    /**
     * if claim is in warranty, only show translations with IW key
     * else show translations other than IW key
     */
    const isInWarranty = isInWarrantyClaim(enquiryOption, warrantyType);
    const keys = Object.keys(reasonsByPeril);
    const reasons = isInWarranty ? keys.filter(k => k === "IW") : keys.filter(k => k !== "IW");

    return (
      <ul className="list-disc list-outside text-srgray-base text-22px leading-none space-y-6 pl-8">
        {reasons.map((reason) => (
          <li>
            <Trans
              i18nKey={`WhyDeviceNotEligible.${perilType}.Reason.${reason}`}
              components={{ light: <TextLight /> }}
            />
          </li>
        ))}
      </ul>
    );
  };

  return (
    <BaseCard className="space-y-5">
      <DeviceNotEligibleIcon className="app-BaseIcon-center" />
      <BaseLabel>
        <Trans
          i18nKey="WhyDeviceNotEligible.Title"
          components={{ medium: <TextMedium /> }}
        />
      </BaseLabel>
      <BaseInformationBox>
        <Reasons />
      </BaseInformationBox>
      <BaseButton
        text={t("WhyDeviceNotEligible.BackToMainMenu")}
        onClick={handleClick}
        disabled={disabled}
        clicked={disabled}
        className="mx-auto w-178px"
      />
    </BaseCard>
  );
};

export default WhyDeviceNotEligible;
