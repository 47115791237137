import isomorphicFetch from "isomorphic-fetch";

export default (url, method, body, noHeaders = false) => {
  let options = {
    method,
    body: method !== "GET" ? JSON.stringify(body) : null,
  };

  if (!noHeaders) {
    options.headers = requestHeaders();
  }

  return isomorphicFetch(url, options).then((ressult) =>
    parseStatus(ressult.status, ressult.json())
  );
};

function parseStatus(status, res) {
  return new Promise((resolve, reject) => {
    if (status >= 200 && status < 300) {
      res.then((response) => resolve(response));
    } else {
      res.then((response) => reject({ status, response }));
    }
  });
}

function requestHeaders() {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    // "x-api-key": "B351EF4C1EC84B648F255C567778DD66",
    // "x-api-key": "74DE6B40C01240CAB9D7A250D4C51BFB",
    "x-api-key": process.env.REACT_APP_SLS_API_KEY,
    "Asurion-channel": "OnlineScreenRepair",
  };
}
