import React from 'react';
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import { Provider } from 'react-redux';
import store from './store';

import HomePage from './pages/Home/Homepage';
import PreparePhonePage from './pages/Home/PreparePhonePage';

import TagManager from "react-gtm-module";
import Config from './modules/Appsync/Config';
import Faq from './pages/Home/Faq';
import TermsAndConditions from './pages/Home/TermsAndConditions/TermsAndConditions';
import TermsOfUse from './pages/Home/TermsAndConditions/TermsOfUse';
import PrivacyPolicy from './pages/Home/TermsAndConditions/PrivacyPolicy';

const tagManagerArgs = {
  gtmId: Config.GTM.gtmId,
};

function App() {

  TagManager.initialize(tagManagerArgs);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/prepare-my-phone">
            <PreparePhonePage />
          </Route>
          <Route path="/faq">
            <Faq />
          </Route>
          <Route path="/terms-and-conditions">
            <TermsAndConditions />
          </Route>
          <Route path="/terms-of-use">
            <TermsOfUse />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          {/* <Route path="/:content" component={WordpressContent} /> */}
        </Switch>
      </Router>
    </Provider>
  );
}

export function getComponentForStaticHtml(Component, message) {
  return (
    <Provider store={store}>
      <Component {...message} {...message.data}/>
    </Provider>
  );
}

export default App;
