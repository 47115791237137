import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import BaseLabel from "../../../base/BaseLabel";
// import BaseInput from "../../../base/BaseInput/BaseInput";
// import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
// import BaseButton from "../../../base/BaseButton/BaseButton";
import { TextLight, TextMedium } from "../../../base/BaseText";

import SecureIcon from "../../../images/true/ic-secure.svg";
// import { ReactComponent as HelpIcon } from "../../../images/icon-help.svg";
// import { ReactComponent as VisaIcon } from "../../../images/payment/icon-visa.svg";
// import { ReactComponent as AmexSvgIcon } from "../../../images/payment/icon-amex.svg";
// import { ReactComponent as MasterCardIcon } from "../../../images/payment/icon-master-card.svg";
import {
  submitPaymentDetailsNew,
} from "../state/operators/paymentOperator";
// import { isEmpty } from "../../../helpers/formatUtils";
// import { REGEX, CARD } from "../../../helpers/constants";
// import { isValidExpiryDate } from "../../../helpers/dateUtils";
// import BaseTooltip from "../../../base/BaseTooltip/BaseTooltip";

import '@backoffice/fast-payments-client-js-sdk/microui/vanilla';
import { BillingGatewayRegion, Environment, Locale, } from '@backoffice/fast-payments-client-js-sdk/microui';
import generateUniqueId from "../../../helpers/generateUniqueId";

const SubmitCardDetailsNew = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const language = sessionStorage.getItem("language");
  const { caseType } = useSelector((state) => state.journeyMessages);
  const perilType = caseType && caseType.replace(/\s+/g,"");
  const fee = useSelector((state) => state.claim.payment.serviceFee.TotalAmount || state.claim.payment.serviceFee[perilType]?.TotalAmount);

  const PCIToken = useSelector((state) => state.claim?.payment?.pciToken?.SecurityToken?.PCIToken);
  const EnrolledAddress = useSelector((state) => state.claim?.deviceConfirmation?.selectedAgreement?.EnrolledAddress || 
  state.validation.agreement[0]?.EnrolledAddress ||
  state.validation.agreement?.EnrolledAddress)
  const EmailAddress = useSelector((state) => state.validation.agreement[0]?.EmailAddress || 
  state.validation.agreement?.EmailAddress ||
  state.validation.verification?.EmailAddress);

  const [isPaymentSuccess, setisPaymentSuccess] = useState(false);
  const microUiId = generateUniqueId();

  useEffect(() => {
    if (PCIToken) {
      window.MicroUI.initializePaymentUI(microUiId, {
        billingServiceConfig: {
          environment: process.env.REACT_APP_BG_TRANSACTION_ENV === "PROD" ? Environment.PROD : Environment.QA,
          region: BillingGatewayRegion.APAC,
          applicationId: "true",
          billingProgramId: "TRUE_BT-HORIZON-SCR-WEB-APAC",
        },
        applicationSession: PCIToken,
        billingInformation: {
          address: {
            address1: EnrolledAddress?.Address1,
            address2: EnrolledAddress?.Address2,
            city: EnrolledAddress?.City,
            stateOrProvince: EnrolledAddress?.StateProvinceCode,
            postalCode: EnrolledAddress?.PostalCode || "819663",
          },
          contact: {
            email: EmailAddress
          }
        },
        onPaymentSuccess: (response) => {
          console.log('Success Transaction: ', response);
          setisPaymentSuccess(true);
          dispatch(
            submitPaymentDetailsNew(
              response.paymentMethod,
              `${response?.billingInformation?.contact?.firstName} ${response?.billingInformation?.contact?.lastName}`,
              `${response?.additionalData?.cardBin}${response?.additionalData?.cardLastFourDigits}`,
              response.additionalData?.cardExpirationDate?.split("/")[0],
              response.additionalData?.cardExpirationDate?.split("/")[1],
              '',
              response.additionalData?.cardBrand,
              response.billingInformation?.address?.postalCode,
              "Continue"
            )
          );
        },
        onPaymentFailure: (response) => {
          console.log('Error Transaction: ', response);
        },
        locale: language === "en-US" ? Locale.enTH : Locale.thTH,
        styleOverride: {
          fontFamily: 'TMedium, sans-serif',
          continueButtonColor: '#ed1c24',
          continueButtonBorderRadius: '999px',
          continueButtonHoverColor: '#ed1c24',
        },
      })
    }
  }, [PCIToken])

  return (
    <BaseCard className={`pb-5 ${isPaymentSuccess ? "pointer-events-none" : ""}`} style={{ maxWidth: "50rem" }}>
      <img className="app-BaseIcon-center" src={SecureIcon} alt="secure-icon"/>
      <BaseLabel className="my-2">
        <Trans i18nKey={`Payment.${perilType}.Title`}>
          A Repair Service fee of <strong>THB{{fee}}</strong><br />(inclusive of VAT) is applicable.
        </Trans>
      </BaseLabel>

      <div id="paymentUI"><div id={microUiId} /></div>

    </BaseCard>
  );
};

export default SubmitCardDetailsNew;