import journeyMessagesReducer from "./journeyMessagesReducer";
import sessionReducer from "./sessionReducer";
import validationReducer from "../modules/Validation/state/reducers";
import repairClaimReducers from "../modules/RepairClaim/state/reducers/index";
import serviceRequestReducer from "./serviceRequestReducer";
import cowrapperReducer from "./cowrapperReducer";
import chatReducer from "./../modules/Appsync/state/reducers";

export default {
  journeyMessages: journeyMessagesReducer,
  session: sessionReducer,
  validation: validationReducer,
  serviceRequest: serviceRequestReducer,
  cowrapper: cowrapperReducer,
  chat: chatReducer,
  claim: repairClaimReducers,
};
