import { TextMedium, TextRegular } from "../../../base/BaseText";

const PickUpAndReturnSchedule = ({ title }) => (
  <div className="bg-white h-56r md:h-full overflow-y-scroll md:overflow-auto">
    <div className="px-0 pb-0 md:px-12 md:pb-12">
      <div className="text-2.5 text-center pop-up-modal-headers">{title}</div>
      <div className="border table-border mt-8 text-center">
        <div className="flex text-1.6 table-header-grey text-srgray-base">
          <div className="w-1/3 px-3 py-4 table-border ">
            <TextMedium>Time that a request <br />is accepted</TextMedium>
          </div>
          <div className="w-1/3 px-3 py-4 border-l border-r table-border">
            <TextMedium>Pick-up time<br />(Monday – Friday)</TextMedium>
          </div>
          <div className="w-1/3 px-3 py-4 table-border">
            <TextMedium>Return time<br />(Monday – Saturday)</TextMedium>
          </div>
        </div>
        <div className="flex text-1.6">
          <div className="w-1/3 px-3 py-4 bg-app-light border-t table-border text-srgray-base">
            <TextMedium>
              Before 2pm, <br />
              Mondays – Fridays
            </TextMedium>
          </div>
          <div className="w-1/3 px-3 py-4 border-l border-t border-r table-border text-srgray-primary">
            <TextRegular>
              By 4pm, on the same <br />
              business day*
            </TextRegular>
          </div>
          <div className="w-1/3 px-3 py-4 border-t table-border text-srgray-primary">
            <TextRegular>2 days* from pick-up day</TextRegular>
          </div>
        </div>
        <div className="flex text-1.6">
          <div className="w-1/3 px-3 py-4 bg-app-light border-t table-border text-srgray-base">
            <TextMedium>
              After 2pm, <br />
              Mondays-Thursdays
            </TextMedium>
          </div>
          <div className="w-1/3 px-3 py-4 border-l border-t border-r table-border text-srgray-primary">
            <TextRegular>
              Before 4pm, on the next <br />
              business day*
            </TextRegular>
          </div>
          <div className="w-1/3 px-3 py-4 border-t table-border text-srgray-primary">
            <TextRegular>2 days* from pick-up day</TextRegular>
          </div>
        </div>
        <div className="flex text-1.6">
          <div className="w-1/3 px-3 py-4 bg-app-light border-b border-t table-border text-srgray-base">
            <TextMedium>
              After 2pm, on Fridays or <br />
              Whole day on Saturdays &<br /> Sundays
            </TextMedium>
          </div>
          <div className="w-1/3 px-3 py-4 border table-border text-srgray-primary">
            <TextRegular>Before 4pm, on Monday*</TextRegular>
          </div>
          <div className="w-1/3 px-3 py-4 border-t border-b table-border text-srgray-primary">
            <TextRegular>2 days* from pick-up day</TextRegular>
          </div>
        </div>
        <div className="flex text-default justify-center text-srgray-primary">
          <div className="w-full px-3 py-8 border-b-4 border-app-primary">
            <TextRegular>
              *Devices picked up on Friday will be returned on Tuesday Business
              days are Mondays to Fridays <br />There will be no pick-up or return
              services on Public Holidays
            </TextRegular>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PickUpAndReturnSchedule;