import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  makeOptions: [],
  modelOptions: [],
  resolvedImeiByChat: false,
};

const deviceConfirmationReducer = createSlice({
  name: "deviceConfirmation",
  initialState,
  reducers: {
    resetStore() {
      return initialState;
    },
    callVerifyImeiApi(state, action) {
      state.imeiVerification = action.payload || {};
    },
    callVerifyImeiApiSuccess(state, action) {
      state.imeiVerification = action.payload.VerifyIMEIResults || {};
      state.programDetails = action.payload?.VerifyIMEIResults?.SessionData?.Agreement?.ClientOffer || {};
    },
    callVerifyImeiApiFailure(state, action) {
      state.error = action.payload;
    },
    saveDeviceMakeAndModel(state, action) {
      state.selectedAsset = action.payload;
    },
    saveDeviceMakeOptions(state, action) {
      state.makeOptions = action.payload.GetAssetsMakeModelResponse;
    },
    saveDeviceModelOptions(state, action) {
      state.modelOptions = action.payload.GetAssetsMakeModelResponse;
    },
    saveResolvedImeiByChat(state, action) {
      state.resolvedImeiByChat = action.payload;
    },
    saveChosenDeviceImei(state, action) {
      state.imeiVerification = {
        IMEI: action.payload,
      };
    },
    saveIsDeviceAppleSamsung(state, action){
      state.isDeviceAppleSamsung = action.payload
    },
    setAgreementAsset(state, action) {
      state.selectedAgreement = action.payload.selectedAgreement;
      state.selectedAsset = action.payload.selectedAsset;
    },
  },
});

const { actions, reducer } = deviceConfirmationReducer;
export const {
  resetStore,
  saveDeviceMakeAndModel,
  callVerifyImeiApi,
  saveDeviceMakeOptions,
  saveDeviceModelOptions,
  saveResolvedImeiByChat,
  saveChosenDeviceImei,
  saveIsDeviceAppleSamsung,
  setAgreementAsset
} = actions;
export default reducer;
