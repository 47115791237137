import {
  addJourneyMessages,
  sleep,
} from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import {
  DEVICE,
  API_PROGRESS,
  COMPONENTS,
  PROGRESS_DATA,
  ACTIVITY,
  ONLINE_JOURNEY_STATUS_LIST,
  INCIDENT_PATH_TYPE
} from "../../../../helpers/constants";
import { setDeviceMakeAndModel, verifyImeiApi } from "../actions/actions";
import { verifyAgreementApi } from "../../../Validation/state/actions";
import {
  updateApiProgress,
  clearApiProgress,
  updateFlowProgress,
  endFlow,
  serviceUnavailable,
} from "../../../../actions/flowWindow";
import { getMakeModelList } from "../../../../actions/cowrapperapi";
import {
  saveChosenDeviceImei,
  saveDeviceMakeOptions,
  saveDeviceModelOptions,
  saveResolvedImeiByChat,
  setAgreementAsset,
} from "../reducers/deviceConfirmationReducer";
import { udpateChatInputVisibility } from "../../../Appsync/state/operators";
import { setChatReason } from "../../../Appsync/state/reducers";
import { createServiceRequest, cancelServiceRequest, determineRequest } from "../../../../actions/serviceRequestApi";
import { EnumEnquiryModules, PROGRAM_NAME } from "../../../../components/EnquiryOptions/Constants";
import updateVisitor from "../../../Appsync/updateVisitor";
import { confirmToCancelRequest } from "./cancelRequestOperator";
import { getMaskedData, isEmpty } from "../../../../helpers/formatUtils";
import i18n from 'i18next';

export const proceedForDeviceConfirmation = (text) => (dispatch, getStore) => {
  // const { replacedAsset } = getStore().validation.verification;

  // let showComponent = isEmpty(replacedAsset)
  //   ? "confirmDevice"
  //   : "displayDevices";

  // if (showComponent) {
  //   const initial = getStore().journeyMessages.flowProgress.percentage;
  //   dispatch(
  //     updateFlowProgress(
  //       i18n.t("Constants.PROGRESS_DATA.CONFIRM_DEVICE.title"),
  //       i18n.t("Constants.PROGRESS_DATA.CONFIRM_DEVICE.count"),
  //       initial
  //     )
  //   );
  // }

  // dispatch(
  //   addJourneyMessages([
  //     createMessage("TEXT", "user", text),
  //     createMessage("DEVICE_CONFIRMATION", "system", { showComponent }),
  //   ])
  // );

  const initial = getStore().journeyMessages.flowProgress.percentage;
  dispatch(
    updateFlowProgress(
      i18n.t("Constants.PROGRESS_DATA.CONFIRM_DEVICE.title"),
      i18n.t("Constants.PROGRESS_DATA.CONFIRM_DEVICE.count"),
      initial
    )
  );

  dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", text),
    ])
  );

  const { selectedAsset } = getStore().claim.deviceConfirmation;
  dispatch(isRegisteredDeviceConfirmed(true, selectedAsset, true));
};

export const isDeviceMakeAccepted = (deviceMake) => {

  return (
    deviceMake.toUpperCase() === DEVICE.APPLE ||
    deviceMake.toUpperCase() === DEVICE.SAMSUNG || 
    // deviceMake.toUpperCase() === DEVICE.HUAWEI || 
    deviceMake.toUpperCase() === DEVICE.REALME|| 
    deviceMake.toUpperCase() === DEVICE.OPPO|| 
    deviceMake.toUpperCase() === DEVICE.VIVO
  );
};



export const isRegisteredDeviceConfirmed = (
  isDeviceRegistered,
  deviceData,
  fromDeviceList = false
) => async (dispatch, getStore) => {
  // const { enrolledAsset } = getStore().validation.verification;
  const { Name: deviceMake } = deviceData.Make;
  const { ClientOffer: programName } = getStore().validation?.agreement;
  let decision;
  let showComponent = "deviceNotEligible";
  const isAppleSamsung = isDeviceMakeAccepted(deviceMake);
  if (isDeviceRegistered) {
    decision = fromDeviceList
      ? `${deviceData.AssetInstance} ${deviceData.Make.Name} ${deviceData.AssetCatalog.AssetCatalogName}`
      : "Yes";

    if(programName === PROGRAM_NAME || isAppleSamsung){
      showComponent = "verifyImei";
    }

    dispatch(setDeviceMakeAndModel(deviceData, isAppleSamsung));
    dispatch(updateVisitor({ lastActivity: ACTIVITY.CONFIRM_DEVICE }));
  } else {
    decision = "No";
    showComponent = "chooseEnrolledDevice";
  }
  const decisionTrans = i18n.t(`${decision}Button`);
  // dispatch(addJourneyMessages([createMessage("TEXT", "user", decisionTrans)]));

  if (showComponent === "verifyImei") {
    const {
      flowProgress: { percentage: initial },
      enquiryOption,
    } = getStore().journeyMessages;
    const { CacheId } = getStore().session.sessionData.Configurations;
    await dispatch(
      updateFlowProgress(
        i18n.t("Constants.PROGRESS_DATA.VERIFY_IMEI.title"),
        i18n.t("Constants.PROGRESS_DATA.VERIFY_IMEI.count"),
        initial
      )
    );
    if (
      [
        EnumEnquiryModules.NewRequest,
        EnumEnquiryModules.NewRequestBatteryReplacement,
        EnumEnquiryModules.NewRequestScreenAndBattery,
        EnumEnquiryModules.InWarrantyRequest
      ].includes(enquiryOption)
    ) {
      await dispatch(
        updateApiProgress(
          i18n.t("Constants.API_PROGRESS.CREATE_SR"),
          40,
          COMPONENTS.CONFIRM_DEVICE,
          0
        )
      );

      const createSRResponse = await dispatch(
        createServiceRequest(CacheId)
      ).catch((err) => dispatch(serviceUnavailable()));
      if (isEmpty(createSRResponse)) return;

      // After API call
      await dispatch(
        updateApiProgress(
          i18n.t("Constants.API_PROGRESS.CREATE_SR_SUCCESS"),
          100,
          COMPONENTS.CONFIRM_DEVICE,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());

      dispatch(
        updateVisitor({
          lastActivity: ACTIVITY.NEW_REQUEST,
          journeyStatus: ONLINE_JOURNEY_STATUS_LIST.INPROGRESS,
          caseNumber:
            createSRResponse.ServiceRequestDetails.CreateServiceRequestResults
              .CustomerCaseNumber,
        })
      );
    }
  }

  dispatch(
    addJourneyMessages([
      createMessage("DEVICE_CONFIRMATION", "system", {
        showComponent,
      }),
    ])
  );
};

export const submitChosenEnrolledDevice = (deviceData) => async (
  dispatch,
  getStore
) => {
  let decision = "cancel";
  let data;
  let module;

  const { ClientOffer: programName } = getStore().validation?.agreement;

  if (deviceData.imei) {
    decision = `${deviceData.make.Make} ${deviceData.model.PopularName}`;

    dispatch(addJourneyMessages([createMessage("TEXT", "user", decision)]));

    const isAppleSamsung = isDeviceMakeAccepted(deviceData.make.Make);
    const isAllowed = programName === PROGRAM_NAME || isAppleSamsung;
    const showComponent = isAllowed
      ? "warrantyConfirmation"
      : "deviceNotEligible";

    const {
      flowProgress: { percentage: initial },
      enquiryOption,
    } = getStore().journeyMessages;
    const { CacheId } = getStore().session.sessionData.Configurations;

    if (
      [
        EnumEnquiryModules.NewRequest,
        EnumEnquiryModules.InWarrantyRequest,
        EnumEnquiryModules.NewRequestBatteryReplacement,
        EnumEnquiryModules.NewRequestScreenAndBattery,
      ].includes(enquiryOption)
    ) {
      await dispatch(
        updateApiProgress(
          i18n.t("Constants.API_PROGRESS.CREATE_SR"),
          40,
          COMPONENTS.CONFIRM_DEVICE,
          0
        )
      );

      const createSRResponse = await dispatch(
        createServiceRequest(CacheId)
      ).catch((err) => dispatch(serviceUnavailable()));
      if (isEmpty(createSRResponse)) return;

      // After API call
      await dispatch(
        updateApiProgress(
          i18n.t("Constants.API_PROGRESS.CREATE_SR_SUCCESS"),
          100,
          COMPONENTS.CONFIRM_DEVICE,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());

      dispatch(
        updateVisitor({
          lastActivity: ACTIVITY.NEW_REQUEST,
          journeyStatus: ONLINE_JOURNEY_STATUS_LIST.INPROGRESS,
          caseNumber:
            createSRResponse.ServiceRequestDetails.CreateServiceRequestResults
              .CustomerCaseNumber,
        })
      );
    }
    
    if (isAllowed) {
      module = "DEVICE_CONFIRMATION";
      data = { showComponent };

      const deviceDetails = {
        AssetCatalog: {
          AssetCatalogId: deviceData.model.CatalogId,
          AssetCatalogName: deviceData.model.AssetCatalogName,
        },
        Make: {
          MakeId: deviceData.make.MakeId,
          Name: deviceData.make.Make,
        },
        Model: {
          ModelId: deviceData.model.ModelId,
          Name: deviceData.model.Model,
        },
        IMEI: deviceData.imei,
      };
      dispatch(setDeviceMakeAndModel(deviceDetails, isAppleSamsung));
      dispatch(setImeiForChosenDevice(deviceData.imei));

      await dispatch(
        updateFlowProgress(
          i18n.t("Constants.PROGRESS_DATA.TROUBLESHOOT.title"),
          i18n.t("Constants.PROGRESS_DATA.TROUBLESHOOT.count"),
          initial
        )
      );

      await dispatch(
        addJourneyMessages([
          createMessage("TEXT", "system", {
            key: "SystemMessage.AnswerFollowingQuestion",
          }),
          createMessage("TEXT", "system", {
            key: "SystemMessage.DeviceWillBeDiagnosed",
          }),
        ])
      );
    } else {
      // device in-eligible
      module = "DEVICE_CONFIRMATION";
      data = { showComponent };
      dispatch(confirmToCancelRequest("", "device-ineligible"));
    }
  } else {
    dispatch(addJourneyMessages([createMessage("TEXT", "user", decision)]));
    module = "ENQUIRY_OPTIONS";
    data = {};
  }

  dispatch(addJourneyMessages([createMessage(module, "system", data)]));
};

export const verifyDeviceImei = (imei) => async (dispatch, getStore) => {
  // Before API call
  const { CacheId } = getStore().session.sessionData.Configurations;

  const {
    imeiVerification,
    resolvedImeiByChat,
    selectedAsset: { AssetInstance: assetInstance },
  } = getStore().claim.deviceConfirmation;
  const imeiVerificationFailedAttempt = imeiVerification
    ? imeiVerification.IMEIVerificationFailedAttempt
    : 0;
  const maskedImei = getMaskedData(imei, "IMEI");
  await dispatch(
    addJourneyMessages([createMessage("TEXT", "user", maskedImei)])
  );
  await dispatch(
    updateApiProgress(i18n.t("Constants.API_PROGRESS.VERIFY_IMEI"), 40, COMPONENTS.VERIFY_IMEI, 0)
  );

  // API call
  const response = await dispatch(
    verifyImeiApi({
      imei,
      cacheId: CacheId,
      assetInstance,
      imeiVerificationFailedAttempt,
    })
  ).catch((err) => dispatch(serviceUnavailable()));
  if (isEmpty(response)) return;

  const {
    VerificationOutcome,
    IMEIVerificationFailedAttempt,
  } = response.VerifyIMEIResults;

  // After API call
  await dispatch(
    updateApiProgress(
      i18n.t("Constants.API_PROGRESS.VERIFY_IMEI_SUCCESS"),
      100,
      COMPONENTS.VERIFY_IMEI,
      40
    )
  );
  await sleep(1000);
  await dispatch(clearApiProgress());
  if (VerificationOutcome) {

    dispatch(updateVisitor({ lastActivity: ACTIVITY.VERIFY_IMEI }));
    const initial = getStore().journeyMessages.flowProgress.percentage;
    await dispatch(
      updateFlowProgress(
        i18n.t("Constants.PROGRESS_DATA.TROUBLESHOOT.title"),
        i18n.t("Constants.PROGRESS_DATA.TROUBLESHOOT.count"),
        initial
      )
    );

    // let showModule = "DEVICE_CONFIRMATION";
    // let showComponent = "warrantyConfirmation";

    // dispatch(
    //   addJourneyMessages([
    //     createMessage(showModule, "system", { showComponent }),
    //   ])
    // );

    dispatch(warrantyConfirmation('Yes'));

  } else {
    if (IMEIVerificationFailedAttempt < 3) {
      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "system", {
            key: "SystemMessage.IncorrectImei",
          }),
          createMessage("DEVICE_CONFIRMATION", "system", {
            showComponent: "verifyImei",
          }),
        ])
      );
    } else {
      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "system", {
            key: "SystemMessage.IncorrectImeiTerminate",
          }),
        ])
      );
      if (!resolvedImeiByChat) {
        await dispatch(setChatReason("Invalid Imei"));
        dispatch(udpateChatInputVisibility(true));
      }
    }
  }
};

export const getDeviceMakeModelOptions = (makeId) => async (
  dispatch,
  getStore
) => {
  const { ClientId } = getStore().session.sessionData.Configurations;
  if (makeId) {
    const models = await dispatch(getMakeModelList(ClientId, makeId));
    await dispatch(saveDeviceModelOptions(models));
  } else {
    const makes = await dispatch(getMakeModelList(ClientId));
    await dispatch(saveDeviceMakeOptions(makes));
  }
  return;
};

export const handleDeviceHoldClose = (text) => async (dispatch) => {
  dispatch(addJourneyMessages([createMessage("TEXT", "user", text)]));
  dispatch(endFlow());
};

export const initiateChatForImei = () => async (dispatch) => {
  dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", {
        key: "VerifyImei.DontKnowLink",
      }),
    ])
  );
  await dispatch(setChatReason("I Don't Know My IMEI"));
  dispatch(udpateChatInputVisibility(true));
};

export const warrantyConfirmation = (status) => async (dispatch, getStore) => {

  // await dispatch(
  //   addJourneyMessages([createMessage("TEXT", "user", status)])
  // );

  const isWarrantyConfirmed = status === "Yes";

  const { caseType, enquiryOption } = getStore().journeyMessages;
  const inWarranty = enquiryOption === "In-WarrantyRequest";

  let perilType = caseType && caseType.replace(/\s+/g, "");
  if(inWarranty){
    perilType = `InWarranty${perilType}`
  }

  if (isWarrantyConfirmed) {
    dispatch(
      addJourneyMessages([
        // createMessage("TEXT", "system", {
        //   key: "SystemMessage.PerformEligibilityCheck1",
        // }),
        // createMessage("TEXT", "system", {
        //   key: "SystemMessage.PerformEligibilityCheck2",
        // }),
        // createMessage("TEXT", "system", {
        //   key: "SystemMessage.PerformEligibilityCheck3",
        // }),
        createMessage("TEXT", "system", {
          key: "SystemMessage.PerformEligibilityCheck4",
        }),
        createMessage("TEXT", "system", {
          key: "SystemMessage.PerformEligibilityCheck5",
        }),
        // createMessage("TROUBLESHOOT", "system", {
        //   showComponent: "Troubleshoot",
        //   perilType,
        //   inWarranty
        // }),
        createMessage("TROUBLESHOOT", "system", {
          showComponent: "TroubleshootLiquid",
          perilType,
          inWarranty
        }),
      ])
    );
  } else {
    dispatch(confirmToCancelRequest("", "declined warranty confirmation", true));
  }

}

export const setImeiResolvedByChat = () => async (dispatch) => {
  dispatch({
    type: saveResolvedImeiByChat.toString(),
    payload: true,
  });
};

export const setImeiForChosenDevice = (imei) => async (dispatch) => {
  dispatch({
    type: saveChosenDeviceImei.toString(),
    payload: imei,
  });
};

export const handleWhyDeviceNotEligible = (text) => async dispatch => {
  dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", text),
      createMessage("DEVICE_CONFIRMATION", "system", { showComponent: "whyDeviceNotEligible" }),
    ])
  )
}

export const handleBackToMainMenu = (text) => dispatch => {
  dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", text),
      createMessage("ENQUIRY_OPTIONS", "system", {})
    ])
  )
}

export const selectAgreementAsset = (
  selectedAgreement, selectedAsset
) => async (dispatch, getStore) => {
  const { Name: deviceMake } = selectedAsset.Make;
  // const programName  = getStore().claim?.payment?.serviceFees?.ProgramName;
  const isAppleSamsung = isDeviceMakeAccepted(deviceMake);
 
  // dispatch(setDeviceMakeAndModel(selectedAsset));
  dispatch(setAgreementAsset({ selectedAgreement, selectedAsset }));

  dispatch(updateVisitor({ lastActivity: ACTIVITY.CONFIRM_DEVICE }));

  if (isAppleSamsung) {

    const { CacheId } = getStore().session.sessionData.Configurations;
    const {
      inputData: { firstName },
    } = getStore().validation;

    await dispatch(
      updateApiProgress(
        API_PROGRESS.ASK_NAME,
        40,
        COMPONENTS.CONFIRM_DEVICE,
        0
      )
    );

    // verify new asset and agrement selected
    const verifyAgreementRes = await dispatch(
      verifyAgreementApi({
        cacheId: CacheId,
        AgreementId: selectedAgreement.AgreementId,
      })
    ).catch((err) => {
      dispatch(serviceUnavailable())
    });

    if (isEmpty(verifyAgreementRes)) {
      await dispatch(clearApiProgress());
      return;
    }

    let determineResponse = await dispatch(
      determineRequest(CacheId)
    ).catch((err) => dispatch(serviceUnavailable()));

    // not allowing to go ahead if response is empty
    if (isEmpty(determineResponse)) {
      return;
    }

      // After API call
      await dispatch(
        updateApiProgress(
          API_PROGRESS.ASK_NAME_SUCCESS,
          100,
          COMPONENTS.CONFIRM_DEVICE,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());

    const { Type: incidentPathType, IncidentType: incidentType } = determineResponse.IncidentPath;

    if (incidentPathType === INCIDENT_PATH_TYPE.WAIT_FOR_HOLD) {
      await dispatch(
        updateApiProgress(
          API_PROGRESS.ASK_NRIC_SUCCESS,
          100,
          COMPONENTS.ASK_NRIC,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());

      // device hold
      dispatch(
        addJourneyMessages([
          createMessage("DEVICE_CONFIRMATION", "system", {
            showComponent: "deviceHold",
          }),
        ])
      );

      return;
    }

    const initial = getStore().journeyMessages.flowProgress.percentage;
    await dispatch(
      updateFlowProgress(
        PROGRESS_DATA.ENQUIRY_OPTIONS.title,
        PROGRESS_DATA.ENQUIRY_OPTIONS.count,
        initial
      )
    );

    if (incidentPathType === INCIDENT_PATH_TYPE.NO_ACTION_REQUIRED) {
      if (incidentType &&
        (incidentType.toUpperCase() === "SWAP" ||
          incidentType.toUpperCase() === "MALFUNCTION" ||
          incidentType.toUpperCase() === "REPLACEMENT")
      ) {
        // dispatch(
        //   addJourneyMessages([
        //     createMessage("CANCEL_REPAIR", "system", { showComponent: "ConfirmDPRequestCancel" }),
        //   ])
        // );
        dispatch(
          addJourneyMessages([
            createMessage("DEVICE_CONFIRMATION", "system", {
              showComponent: "existingDPRequest",
            }),
          ])
        );
      } else {
        dispatch(
          addJourneyMessages([
            createMessage("TEXT", "system", {
              key: "SystemMessage.HowCanWeAssist",
              values: { firstName },
            }),
            createMessage("ENQUIRY_OPTIONS", "system", {}),
          ])
        );
      }
    } else {
      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "system", {
            key: "SystemMessage.HowCanWeAssist",
            values: { firstName },
          }),
          createMessage("ENQUIRY_OPTIONS", "system", {}),
        ])
      );
    }

    // if (isSingtel) {
    //   dispatch(
    //     addJourneyMessages([
    //       createMessage("TEXT", "system", {
    //         key: "SystemMessage.HowCanWeAssist",
    //         values: { firstName },
    //       }),
    //       createMessage("ENQUIRY_OPTIONS", "system", {}),
    //     ])
    //   );
    // } else { //Celcom
    //   if (incidentPathType === INCIDENT_PATH_TYPE.NO_ACTION_REQUIRED) {
    //     if (incidentType &&
    //       (incidentType.toUpperCase() === "SWAP" ||
    //         incidentType.toUpperCase() === "MALFUNCTION" ||
    //         incidentType.toUpperCase() === "REPLACEMENT")
    //     ) {
    //       dispatch(
    //         addJourneyMessages([
    //           createMessage("CANCEL_REPAIR", "system", { showComponent: "ConfirmDPRequestCancel" }),
    //         ])
    //       );
    //     } else {
    //       dispatch(
    //         addJourneyMessages([
    //           createMessage("TEXT", "system", {
    //             key: "SystemMessage.HowCanWeAssist",
    //             values: { firstName },
    //           }),
    //           createMessage("ENQUIRY_OPTIONS", "system", {}),
    //         ])
    //       );
    //     }
    //   } else {
    //     dispatch(
    //       addJourneyMessages([
    //         createMessage("TEXT", "system", {
    //           key: "SystemMessage.HowCanWeAssist",
    //           values: { firstName },
    //         }),
    //         createMessage("ENQUIRY_OPTIONS", "system", {}),
    //       ])
    //     );
    //   }
    // }
  } else {
    dispatch(
      addJourneyMessages([
        createMessage("DEVICE_CONFIRMATION", "system", {
          showComponent: "deviceNotEligible",
        }),
      ])
    );
  }
};