import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { TextRegular } from "../../../base/BaseText";
import { ReactComponent as DeviceNotEligibleIcon } from "../../../images/true/icon-incorrect.svg";

const ExistingDPRequest = () => {
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();

  const handleMoveToSmartSupport = () => {
    setDisabled(true);
    window.open(process.env.REACT_APP_SMART_SUPPORT_LINK);
  };

  return (
    <BaseCard className="space-y-5">
      <DeviceNotEligibleIcon className="app-BaseIcon-center" />
      <BaseLabel>
        <Trans i18nKey={`EXISTING_ONLINE_CHANNEL_REQUEST.CONTENT`}>
          <TextRegular>
            message
          </TextRegular>
        </Trans>
      </BaseLabel>
      <BaseButton
        text={t("EXISTING_ONLINE_CHANNEL_REQUEST.BUTTON")}
        onClick={handleMoveToSmartSupport}
        disabled={disabled}
        clicked={disabled}
        className="mx-auto w-full bg-white border-srgray-borderInput text-srgray-base"
      />
    </BaseCard>
  );
};

export default ExistingDPRequest;
