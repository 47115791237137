import PropTypes from "prop-types";
import CaptureUserInquiry from "./CaptureUserInquiry";
import Faq from "./FAQ";
const View = ({ showComponent }) => {
  return (
    <>
      {showComponent === "CaptureUserInquiry" && <CaptureUserInquiry />}
      {showComponent === "Faq" && <Faq />}
    </>
  );
};

View.propTypes = {
  showComponent: PropTypes.string.isRequired,
};

export default View;
