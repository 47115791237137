import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import BaseCard from "./../../base/BaseCard/BaseCard";
import BaseLabel from "../../base/BaseLabel";
import BaseButtonGroup from "./../../base/BaseButton/BaseButtonGroup";
import BaseButton from "./../../base/BaseButton/BaseButton";
import BaseInput from "./../../base/BaseInput/BaseInput";
import { TextRegular } from "../../base/BaseText";

import { validateName } from "./state/operators";
import { ReactComponent as IdCardIcon } from "./../../images/true/icon-id-card.svg";
import { isEmpty } from "../../helpers/formatUtils";
import { FIELD_IDS, REGEX } from "../../helpers/constants";
import { TextMedium } from "../../base/BaseText";

export default function AskName() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [disabled, setDisabled] = useState(false);

  const handleFirstName = (val) => {
    if (!val.match(REGEX.Text)) {
      setFirstName(val);
    }
    setFirstName(val);
  };

  const handleLastName = (val) => {
    if (!val.match(REGEX.Text)) {
      setLastName(val);
    }
    setLastName(val);
  };

  const handleSubmit = () => {
    dispatch(validateName(firstName, lastName));
    setDisabled(true);
  };

  const shouldntSubmit = disabled || isEmpty(firstName) || isEmpty(lastName);

  return (
    <div>
      <BaseCard>
        {/* <BaseIcon icon={IdCardIcon} name="Icon ID Card" center /> */}
        <IdCardIcon className="app-BaseIcon-center" />
        <BaseLabel className="font-bold">
          <TextRegular>
            <Trans i18nKey="AskName.Title" />
          </TextRegular>
        </BaseLabel>
        <BaseInput
          id={FIELD_IDS.FIRST_NAME}
          label={t("AskName.FirstNameLabel")}
          placeholder={t("AskName.FirstNamePlaceholder")}
          className="mt-0"
          value={firstName}
          full
          onChange={(val) => handleFirstName(val)}
          autoFocus
          disabled={disabled}
        />
        <BaseInput
          id={FIELD_IDS.LAST_NAME}
          label={t("AskName.LastNameLabel")}
          placeholder={t("AskName.LastNamePlaceholder")}
          className="mt-0"
          value={lastName}
          full
          onChange={(val) => handleLastName(val)}
          disabled={disabled}
          onEnterPress={shouldntSubmit ? () => { } : handleSubmit}
        />
        <BaseButtonGroup>
          <BaseButton
            id={FIELD_IDS.SUBMIT_NAME}
            name="Submit"
            text={t("SubmitButton")}
            onClick={handleSubmit}
            disabled={shouldntSubmit}
            clicked={disabled}
            focused
          />
        </BaseButtonGroup>
      </BaseCard>
    </div>
  );
}
