import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import classNames from "classnames";
import PropTypes from "prop-types";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseCheckbox from "../../../base/BaseCheckbox/BaseCheckbox";
import { TextBold, TextRegular } from "../../../base/BaseText";
import BaseTextarea from "../../../base/BaseTextarea/BaseTextarea";

import { submitTroubleshootAnswer } from "../state/operators/troubleshootingOperator";
import { handleBackToMainMenu } from "../state/operators/deviceConfirmationOperators";
import tsConfig from "./troubleshootConfig";
import Damage from "./Damage";
import { DEVICE } from "../../../helpers/constants";

import { ReactComponent as TroubleshootIcon } from "../../../images/true/triage/troubleshoot-liquid.svg";
import { saveTroubleshootAnswer } from "../state/operators/troubleshootingOperator";
import { addJourneyMessages, sleep } from "../../../helpers/addJourneyMessages";
import createMessage from "../../../helpers/createMessage";

const TroubleshootLiquid = ({ perilType, inWarranty }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [clicked, setClicked] = useState("");
  const [triage, setTriage] = useState(null);
  const [status, setStatus] = useState(null);
  const [isAgreed, setIsAgreed] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [comments, setComments] = useState("");
  const { isDeviceAppleSamsung = false, selectedAsset } = useSelector(
    (state) => state.claim?.deviceConfirmation
  );
  const make = selectedAsset?.Make?.Name || "Other";

  const handleYesClick = async () => {
    setClicked("Yes");
    setDisabled(true);
    await dispatch(saveTroubleshootAnswer("LiquidExposure", true, "My device has not been exposed to any liquid."));
    dispatch(
      addJourneyMessages([
        createMessage("TEXT", "user", {
          key: "YesButton",
        }),
        createMessage("TROUBLESHOOT", "system", {
          showComponent: "TroubleshootPower",
          perilType,
          inWarranty
        }),
      ])
    );
  };

  const handleNoClick = async () => {
    setClicked("No");
    setDisabled(true);
    await dispatch(saveTroubleshootAnswer("LiquidExposure", false, "My device has not been exposed to any liquid."));
    dispatch(
      addJourneyMessages([
        createMessage("TEXT", "user", {
          key: "NoButton",
        }),
        createMessage("TROUBLESHOOT", "system", {
          showComponent: "TroubleshootPower",
          perilType,
          inWarranty
        }),
      ])
    );
  };

  return (
    <>
      {/*<BaseCard className="space-y-4">
        <BaseLabel className="text-left flex justify-center mb-0">
          <TextBold>
            <Trans i18nKey={`Troubleshoot.${perilType}.Header`} />
          </TextBold>
        </BaseLabel>
        
        <BaseButtonGroup>
          <BaseButton
            className="px-12"
            text={t("ConfirmButton")}
            onClick={() => handleSubmit("Confirm")}
            disabled={confirmBtnDisableStatus}
            clicked={status === "Confirm"}
          />
          <BaseButton
            className="px-16"
            text={t("BackButton")}
            onClick={() => handleSubmit("BackButton")}
            disabled={disabled}
            clicked={status === "BackButton"}
          />
        </BaseButtonGroup>
  </BaseCard>*/}
      <div>
        <BaseCard>
          <TroubleshootIcon className="app-BaseIcon-center" />
          <BaseLabel>
            <Trans
              i18nKey="Troubleshoot.ScreenRepair.LiquidExposure.Title"
            />
          </BaseLabel>
          <BaseButtonGroup>
            <BaseButton
              name="Submit"
              text={t("NoButton")}
              onClick={handleNoClick}
              disabled={disabled}
              clicked={clicked === "No"}
              focused
              className="bg-white border-app-primary text-app-primary"
            />
            <BaseButton
              name="Submit"
              text={t("YesButton")}
              onClick={handleYesClick}
              disabled={disabled}
              clicked={clicked === "Yes"}
              focused
              className="bg-white border-app-primary text-app-primary"
            />
          </BaseButtonGroup>
        </BaseCard>
      </div>
    </>
  );
};

TroubleshootLiquid.defaultProps = {
  inWarranty: false,
};

TroubleshootLiquid.propTypes = {
  perilType: PropTypes.string.isRequired,
  inWarranty: PropTypes.bool,
};

export default TroubleshootLiquid;
