import React, { useState } from "react";
import { TextMedium } from "../../../base/BaseText";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { richTextOptions } from '../../../helpers/formatUtils';

function Content({ data }) {
  const [selectedChildContents, setSelectedChildContents] = useState({});

  const handleClick = (parentIndex, childContent) => {
    if (
      selectedChildContents[parentIndex] &&
      selectedChildContents[parentIndex].sys.id === childContent.sys.id
    ) {
      setSelectedChildContents({
        ...selectedChildContents,
        [parentIndex]: null,
      });
    } else {
      setSelectedChildContents({
        ...selectedChildContents,
        [parentIndex]: childContent,
      });
    }
  };



  return (
    <div className="py-8 xl:px-20r lg:px-11r px-8 gap-8">
      {data.map((parentContent, index) => (
        <div
          className={index === 0 &&`border-b border-solid border-srgray-lavander`}
        >
          <div className="flex flex-col my-8">
            <div className="flex lg:flex-row flex-col justify-between w-full lg:gap-7r gap-8">
              <div className="flex flex-row justify-center lg:min-w-300px lg:min-h-300px min-w-280px min-h-280px">
                <img src={parentContent.image.fields.file.url} alt="test" />
              </div>
              <div className="flex flex-col justify-center">
                <h4 className="lg:text-56px text-30px">
                  <TextMedium>{parentContent.title}</TextMedium>
                </h4>
                <h4 className="lg:text-30px text-22px">
                  <TextMedium>{parentContent.description}</TextMedium>
                </h4>
                <div className="flex lg:flex-row flex-col lg:justify-evenly lg:gap-16 gap-4 mt-8">
                  {parentContent.content.map((childContent, childIndex) => (
                    <BaseButton
                      key={childIndex}
                      onClick={() => handleClick(index, childContent)}
                      text={childContent.fields.primaryText}
                      className="lg:w-50% w-full bg-white border-app-primary text-app-primary"
                    />
                  ))}
                </div>
                {selectedChildContents[index] && (
                  <>
                  {documentToReactComponents(selectedChildContents[index].fields.longRichText, richTextOptions)}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Content;
