import DOMPurify from "dompurify";
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

export const toPascalCase = (text) =>
  text.replace(/\w+/g, function (w) {
    return w[0].toUpperCase() + w.slice(1).toLowerCase();
  });

export const sanitize = (value) => {
  return DOMPurify.sanitize(value);
};

export const isEmpty = (input) => {
  if (typeof input === "undefined" || input === "null") {
    return true;
  }
  if (typeof input === "function") {
    return false;
  }
  if (Array.isArray(input)) {
    return input.length === 0;
  }
  if (typeof input === "string" && input.trim().length === 0) {
    return true;
  }
  return !input || Object.keys(input).length === 0;
};

export function getMaskedCCNum(str) {
  if (str !== "") {
    str = new Array(str.length - 3).join("*") + str.substr(str.length - 4, 4);
  }
  return str;
}

export function getFormattedUserName(name = "") {
  name = name ? name.replace(/\s{2,}/g, " ") : name;
  let isSpaceFound = name.indexOf(" ") > -1;
  name = isSpaceFound ? name.split(" ") : name;

  if (isSpaceFound) {
    var [firstName, ...rest] = name;
  }

  return {
    firstName: isSpaceFound ? firstName : name,
    lastName: isSpaceFound && rest ? rest.join(" ") : "",
    fullName: name.length > 0 && rest ? `${firstName} ${rest.join(" ")}` : name,
  };
}

export const getMaskedData = (code, type) => {
  let maskedCode = code;
  switch (type) {
    case "EMAIL":
      // let split = code.split("@");
      // const nameLength = split[0].length;
      // if (nameLength > 3) {
      //   maskedCode =
      //     code.substr(0, 1) +
      //     new Array(nameLength - 2).fill("*").join("") +
      //     split[0][nameLength - 1] +
      //     "@" +
      //     split[1];
      // } else if (nameLength === 3) {
      //   maskedCode = code.substr(0, 1) + "**";
      // } else {
      //   maskedCode = new Array(nameLength).fill("*").join("");
      // }
      let maskid = "";
      let myemailId = code;

      if (typeof code !== 'undefined' && code.length) {
        let prefix = myemailId.substring(0, myemailId.lastIndexOf("@"));
        let postfix = myemailId.substring(myemailId.lastIndexOf("@"));

        for (let i = 0; i < prefix.length; i++) {
          if (i == 0 || i % 2 == 1) {
            maskid = maskid + prefix[i].toString();
          } else {
            maskid = maskid + "*";
          }
        }
        myemailId = maskid + postfix;
        maskedCode = myemailId;
      }
      break;
    case "MDN":
      maskedCode = code[0] + "**" + code.substr(3, 2) + "**" + code[7];
      break;
    case "IMEI":
      maskedCode = code[0] + "**" + code[3] + "**";
      break;
    case "NRIC":
      maskedCode = code[0] + "**" + code[3];
      break;
    default:
      break;
  }
  return maskedCode;
};

export function getDeviceName({ AssetsInformation, MakeName = '', ModelName = '' }) {
  const deviceMarketingName = !isEmpty(AssetsInformation) ? `${AssetsInformation?.Manufacturer} ${AssetsInformation?.MarketingName}` : ''
  if (deviceMarketingName) {
    return `${deviceMarketingName} (${ModelName})`
  }
  return MakeName ? `${MakeName} ${ModelName}` : ''
}

export function getMdnWithoutUnderscore(mdn = '') {
  const strMdn = mdn.toString()
  const index = strMdn.indexOf('_')
  if (index > 0) {
    return strMdn.slice(0, index)
  }
  return strMdn
}

export function getMaskedIMEI(str) {
  if (str !== "") {
    str = new Array(str.length - 3).join("*") + str.substr(str.length - 3, 3);
  }
  return str;
}

export const richTextOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <span className="text-rblack lg:text-24px text-22px">{children}</span>,
    [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
    [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
    [BLOCKS.HEADING_3]: (node, children) => <h3 className="lg:text-36px text-30px">{children}</h3>,
    [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
    [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
    [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
    [BLOCKS.UL_LIST]: (node, children) => <ul className="list-disc">{children}</ul>,
    [BLOCKS.OL_LIST]: (node, children) => <ol className="list-decimal list-inside lg:text-24px text-22px">{children}</ol>,
    [BLOCKS.LIST_ITEM]: (node, children) => <li className="">{children}</li>,
    [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
    [BLOCKS.HR]: () => <hr />,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { title, description, file } = node.data.target.fields;
      const url = file.url;
      const type = file.contentType;
      const mimeType = type ? type.split('/')[0] : 'image';

      if (mimeType === 'image') {
        const { width, height } = file.details.image;
        return (
          <figure>
            <img src={url} alt={description} width={width} height={height} />
            {/* {title && <figcaption>{title}</figcaption>} */}
          </figure>
        );
      }

      if (mimeType === 'video') {
        return (
          <video src={url} controls>
            {description && <track kind="subtitles" label="English" srcLang="en" src={description} />}
          </video>
        );
      }

      return null;
    },
    [INLINES.HYPERLINK]: (node, children) => (
      <a href={node.data.uri} target="_blank" rel="noopener noreferrer" className="underline text-app-primary">
        {children}
      </a>
    ),
  },
  renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
};
