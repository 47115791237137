import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { TextRegular } from "../../../base/BaseText";

import { ReactComponent as NpsIcon } from "../../../images/icon-nps.svg";
import { submitFeedbackFromUser } from "../state/operators/npsOperator";
import BaseTextarea from "../../../base/BaseTextarea/BaseTextarea";

const SubmitFeedback = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.claim.survey.surveyQuestions);
  const [disabled, setDisabled] = useState(false);
  const [feedback, setFeedback] = useState("");

  const handleSubmit = () => {
    setDisabled(true);
    const question = questions.find((q) =>
      q.QuestionDescription.includes("reason")
    );
    dispatch(
      submitFeedbackFromUser(question.SurveyId, question.QuestionId, feedback)
    );
  };

  return (
    <BaseCard>
      {/* <BaseIcon icon={NpsIcon} name="Rate our service" center /> */}
      <NpsIcon className="app-BaseIcon-center" />
      <BaseLabel>
        <Trans i18nKey="SubmitFeedback.Title">
          <TextRegular>
            Please share with us the most <br />
            important reason for the rating <br />
            you have provided?
          </TextRegular>
        </Trans>
      </BaseLabel>
      <BaseTextarea
        className="w-25"
        name="CustomerFeedback"
        placeholder={t("SubmitFeedback.TypeReason")}
        value={feedback}
        onChange={setFeedback}
        disabled={disabled}
      />
      <BaseButtonGroup>
        <BaseButton
          text={t("NextButton")}
          onClick={handleSubmit}
          disabled={disabled}
          clicked={disabled}
          autoFocus
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default SubmitFeedback;
