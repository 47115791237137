import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseSeparatedInput from "../../../base/BaseSeparatedInput/BaseSeparatedInput";
import BaseLink from "../../../base/BaseLink/BaseLink";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseInformationBox from "../../../base/BaseInformationBox";
import { TextMedium, TextRegular } from "../../../base/BaseText";

import {
  verifyDeviceImei,
  initiateChatForImei,
  handleBackToMainMenu,
} from "../state/operators/deviceConfirmationOperators";
import { isEmpty } from "../../../helpers/formatUtils";
import generateUniqueId from "../../../helpers/generateUniqueId";
import { REGEX } from "../../../helpers//constants";
import { ReactComponent as ImeiIcon } from "../../../images/true/icon-phone-imei.svg";
import BaseNestedPopup from "../../../base/BaseNestedPopup/BaseNestedPopup";
import { ReactComponent as FindImeiImage } from "../../../images/find-imei.svg";


const VerifyImei = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [imei, setImei] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [uniqueId, setUniqueId] = useState("");
  const [showPopUp, setShowPopUp] = useState(false)
  const [selectedButton, setSelectedButton] = useState(null)

  const handleSubmitImei = () => {
    dispatch(verifyDeviceImei(imei));
    setDisabled(true);
    setSelectedButton("Submit");
  };

  useEffect(() => {
    setUniqueId(generateUniqueId());
  }, []);

  const handleDontKnowImei = () => {
    // setDisabled(true);
    // dispatch(initiateChatForImei());
    setShowPopUp(true)
  };

  const handleBackClick = () => {
    setDisabled(true);
    setSelectedButton("Back")
    dispatch(handleBackToMainMenu(t("BackButton")))
  }

  const shouldntSubmit = disabled || isEmpty(imei) || imei.length < 6;

  return (
    <BaseCard>
      {/* <BaseIcon icon={ImeiIcon} name="IMEI" center /> */}
      <ImeiIcon className="app-BaseIcon-center" />
      <BaseLabel className="font-bold">
        <Trans i18nKey="VerifyImei.Title">
          <TextMedium>
            Please enter LAST 6 digits of
            <br /> your device's IMEI code
          </TextMedium>
        </Trans>
      </BaseLabel>
      <BaseSeparatedInput
        name={`ImeiVerify-${uniqueId}`}
        value={imei}
        length={6}
        onChange={setImei}
        disabled={disabled}
        onEnterPress={shouldntSubmit ? () => { } : handleSubmitImei}
        autoFocus
        regex={REGEX.AlphaNumeric}
      />

      {/*<BaseInformationBox>
        <BaseLabel className="text-left m-0">
          <TextMedium>{t("VerifyImei.Information.Title")}</TextMedium>
        </BaseLabel>
        <BaseLabel className="text-left text-default m-0">
          <Trans i18nKey="VerifyImei.Information.Content" components={{ regular: <TextRegular />, medium: <TextMedium /> }} />
        </BaseLabel>
  </BaseInformationBox>*/}
      <BaseLink
        className="text-center my-3 text-22px underline text-black"
        disabled={disabled}
        onClick={handleDontKnowImei}
      >
        <TextMedium>{t("VerifyImei.DontKnowLink")}</TextMedium>
      </BaseLink>
      {/*<BaseButtonGroup>
        <BaseButton
          className="bg-app-nova border-app-nova"
          text={t("SubmitButton")}
          onClick={handleSubmitImei}
          disabled={shouldntSubmit}
          clicked={selectedButton === 'Submit'}
          focused
        />
        <BaseButton
          text={t("BackButton")}
          onClick={handleBackClick}
          disabled={disabled}
          clicked={selectedButton === 'Back'}
        />
</BaseButtonGroup>*/}
      <BaseButton
        className="bg-app-nova border-app-nova mx-auto"
        text={t("NextButton")}
        onClick={handleSubmitImei}
        disabled={shouldntSubmit}
        clicked={selectedButton === 'Submit'}
        focused
      />
        {showPopUp && (
        <BaseNestedPopup
          show={true}
          onClose={() => setShowPopUp(false)}
          className="rounded rounded-2xl w-full absolute top-38-5r pb-4 text-center h-auto left-0 bottom-0"
          isTitleCentered={false}
          closeText="Close"
        >
          <div className="px-24px mb-5">
            <p className="font-TBold text-20px text-srgray-base leading-none text-center text-22px mb-3">
              <Trans i18nKey="VerifyImei.Information.Title" />
            </p>
            <p className="font-TMedium text-20px text-srgray-base leading-none text-center text-22px mb-3">
              <Trans i18nKey="VerifyImei.Information.Content" />
            </p>
          </div>
          <div className="h-19r w-full mb-5 flex flex-row justify-center">
            <FindImeiImage/>
          </div>
        </BaseNestedPopup>
      )}
      
    </BaseCard>
  );
};

export default VerifyImei;
