import React from "react";

export const TextRegular = ({ children }) => {
  return <span className="font-TMedium">{children}</span>;
};

export const TextLight = ({ children }) => {
  return <span className="font-TMedium">{children}</span>;
};

export const TextMedium = ({ children }) => {
  return <span className="font-TMedium">{children}</span>;
};

export const TextBold = ({ children }) => {
  return <span className="font-TBold">{children}</span>;
};