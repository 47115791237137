import { isEmpty } from "./formatUtils";
import holidayList from "./holidayList.json";

const aDay = 86400000;

const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

// Default start and end timings
const defaultStartTime = 9
const defaultEndTime = 21

const Holidays = holidayList.map((d) => new Date(d).toString());

const monToFri = (day) => {
  return day > 0 && day < 6;
};

const monToSat = (day) => {
  return day > 0 && day < 7;
};

const isSaturday = (day) => {
  return day === 6;
};

const isFriday = (day) => {
  return day === 5;
};

// export const getPickupSlot = () => ["9am - 12pm", "12pm - 4pm"];
export const getPickupSlot = () => ["By 5pm", "By 8pm"];

// export const getDeliverySlot = () => ["2pm - 6pm"];
export const getDeliverySlot = () => ["By 8pm"];

export const covertToUTCDate = (date = new Date()) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  ).toString();
};

export const isPublicHoliday = (strDate) => {
  const givenDate = new Date(strDate);
  const holiday = Holidays.find((h) => {
    const hDate = new Date(h);
    return hDate.toLocaleDateString() === givenDate.toLocaleDateString();
  });
  return !isEmpty(holiday);
};

//not necessary next day because nthDay dictates a multiplier
export const getNextDay = (strDate, nthDay = 1) => {
  return new Date(new Date(strDate).getTime() + aDay * nthDay).toString();
};

export const getNextBusinessDay = (
  strDate,
  nthDay = 1,
  includeSaturday = true
) => {
  let daysCovered = 0;
  let newDay = strDate;

  while (daysCovered !== nthDay) {
    newDay = getNextDay(newDay);
    const weekDay = new Date(newDay).getDay();
    if (
      !isPublicHoliday(newDay) &&
      (monToFri(weekDay) || (isSaturday(weekDay) && includeSaturday))
    ) {
      daysCovered++;
    }
  }
  return newDay;
};

export const getPickupDates = (date, province) => {
  const today = getDateFromUtcTimeZone(8, date);
  const todayStr = today.toString();
  const hour = today.getHours();
  const minutes = today.getMinutes();
  const day = today.getDay();
  const slots = getPickupSlot();
  let options = [];
  const isBefore2PM = hour < 14
  const isBangkok = province === "Bangkok"


  if (!isPublicHoliday(todayStr) && monToSat(day)) {
    if(isBangkok){
      if(isBefore2PM){
        options.push(
          { date: todayStr, slots },
          { date: getNextBusinessDay(todayStr, 1), slots },
          { date: getNextBusinessDay(todayStr, 2), slots }
        );
      }
      else{
        options.push(
          { date: getNextBusinessDay(todayStr), slots },
          { date: getNextBusinessDay(todayStr, 2), slots },
          { date: getNextBusinessDay(todayStr, 3), slots }
        );
      }
    }
    else{
      if(isBefore2PM){
        options.push(
          { date: getNextBusinessDay(todayStr, 1), slots },
          { date: getNextBusinessDay(todayStr, 2), slots },
          { date: getNextBusinessDay(todayStr, 3), slots }
        );
      }
      else{
        options.push(
          { date: getNextBusinessDay(todayStr, 2), slots: [slots[1]] },
          { date: getNextBusinessDay(todayStr, 3), slots },
          { date: getNextBusinessDay(todayStr, 4), slots }
        );
      }
    }
  } else {
    options.push(
      { date: getNextBusinessDay(todayStr, 1), slots },
      { date: getNextBusinessDay(todayStr, 2), slots },
      { date: getNextBusinessDay(todayStr, 3), slots }
    );
  }
  return options;
};

export const getDeliveryDates = (pickupDate, province) => {
  const date = new Date(pickupDate);
  const day = date.getDay();
  const slots = getDeliverySlot();
  const isBangkok = province === "Bangkok"
  let options = [];
  if (isFriday(day)) {
    if(isBangkok){
      options.push(
        { date: getNextBusinessDay(pickupDate, 5), slots },
        { date: getNextBusinessDay(pickupDate, 6), slots },
        { date: getNextBusinessDay(pickupDate, 7), slots }
      );
    }
    else{
      options.push(
        { date: getNextBusinessDay(pickupDate, 7), slots },
        { date: getNextBusinessDay(pickupDate, 8), slots },
        { date: getNextBusinessDay(pickupDate, 9), slots }
      );
    }
  } else {
    if(isBangkok){
      options.push(
        { date: getNextBusinessDay(pickupDate, 5, true), slots },
        { date: getNextBusinessDay(pickupDate, 6, true), slots },
        { date: getNextBusinessDay(pickupDate, 7, true), slots }
      );
    }
    else{
      options.push(
        { date: getNextBusinessDay(pickupDate, 7, true), slots },
        { date: getNextBusinessDay(pickupDate, 8, true), slots },
        { date: getNextBusinessDay(pickupDate, 9, true), slots }
      );
    }
  }
  return options;
};

export const getDateFromUtcTimeZone = (offset, date = new Date()) => {
  const offsetMultiplier = 3600000;
  const utcMs = new Date(covertToUTCDate(date)).getTime();
  const returnDate = new Date(utcMs + offsetMultiplier * offset);
  return returnDate;
};

export const getReadableDate = (dateStr) => {
  return new Date(dateStr).toDateString();
};

export const getFormmatedDate = (strDate, format = "d m yy", split = " ") => {
  const formats = format.split(split);
  const dateArray = [];
  const dateObj = new Date(strDate);
  const day = dateObj.getDay();
  const dayText = Days[day];
  const date = dateObj.getDate();
  const month = dateObj.getMonth();
  const monthText = Months[month];
  const year = dateObj.getFullYear().toString();
  formats.map((f) => {
    switch (f) {
      case "d":
        dateArray.push(date);
        return date;
      case "dd":
        const dd = ("0" + date).slice(-2);
        dateArray.push(dd);
        return dd;
      case "D":
        dateArray.push(dayText);
        return dayText;
      case "DDD":
        const dtext = dayText.substr(0, 3);
        dateArray.push(dtext);
        return dtext;
      case "m":
        dateArray.push(month + 1);
        return month + 1;
      case "mm":
        const mm = ("0" + (month + 1)).slice(-2);
        dateArray.push(mm);
        return mm;
      case "M":
        dateArray.push(monthText);
        return monthText;
      case "MMM":
        const mtext = monthText.substr(0, 3);
        dateArray.push(mtext);
        return mtext;
      case "yyyy":
        dateArray.push(year);
        return year;
      case "yy":
        const ytext = year.substr(2, 2);
        dateArray.push(ytext);
        return ytext;
      default:
        return "";
    }
  });
  return dateArray.join(split);
};

export const getFormatedTime = (strDate) => {
  const date = new Date(strDate);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 ? hours % 12 : 12;
  hours = ("0" + hours).slice(-2);
  minutes = ("0" + minutes).slice(-2);
  seconds = ("0" + seconds).slice(-2);
  const strTime = `${hours}:${minutes}:${seconds} ${ampm}`;
  return strTime;
};

export const isToday = (someDate) => {
  const today = new Date();
  return someDate === today.toLocaleDateString();
};

// Adding given time to current date
const setDateTime = (date, time) => {
  const cloned = new Date(date.getTime());
  // Adding hours, minutes and seconds to given cloned date
  const [hours, minutes, seconds = 0] = time.split(":");
  cloned.setHours(Number(hours), Number(minutes), Number(seconds));
  return cloned;
} 

export const isTimeBetweenBusinessHour = (sTime = defaultStartTime, eTime = defaultEndTime) => {
  const today = getDateFromUtcTimeZone(8);
  const hour = today.getHours();

  return hour >= sTime && hour < eTime;
  // return true;
};

export const isValidExpiryDate = (month, year) => {
  const givenMonth = parseInt(month);
  const givenYear = parseInt(year);
  const today = getDateFromUtcTimeZone(8);
  const currentMonth = parseInt(today.getMonth()) + 1;
  const currentYear = parseInt(today.getFullYear());
  return (
    givenMonth > 0 &&
    givenMonth < 13 &&
    (givenYear > currentYear ||
      (givenMonth > currentMonth && givenYear === currentYear))
  );
};

export function showMaintenanceText() {
  let d1 = new Date("2021-05-24 06:00");
  let d2 = new Date();

  return d1 > d2;
}

/**
 * 
 * @param {Object} businessHours 
 * @returns {startTime, endTime}
 * startTime: if business hours found for given day returns start time
 * endTime: if business hours found for given day returns end time
 */
export const getBusinessStartAndEndTime = (businessHours) => {
  const { Active: isActive } = businessHours;
  const today = Days[new Date().getDay()];
  const startDay = `${today}StartTime`;
  const endDay = `${today}EndTime`;
  const [startTime, endTime] = Object.keys(businessHours).filter(val => [startDay, endDay].includes(val));
  
  return {
    isActive,
    startTime: startTime && businessHours[startTime],
    endTime: endTime && businessHours[endTime]
  }
} 