import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseInformationBox from "../../../base/BaseInformationBox";
import BaseInput from "../../../base/BaseInput/BaseInput";
import BaseLabel from "../../../base/BaseLabel";
import { TextMedium, TextRegular } from "../../../base/BaseText";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseDropdown from "../../../base/BaseDropdown/BaseDropdown";

import { changeAddressSchedule } from "../state/operators/scheduleOperator";
import { ReactComponent as AddressIcon } from "../../../images/true/icon-address.svg";
import { isEmpty } from "../../../helpers/formatUtils";

import {getCityStateByZipcodeApi} from "../../../actions/serviceRequestApi"

export const ChangeAddress = ({ isPickup }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const which = isPickup ? "Pick-up" : "Return";
  const [disabled, setDisabled] = useState(false);
  const [unitNumber, setUnitNumber] = useState("");
  // const [buildingName, setBuildingName] = useState("");
  // const [blockNumber, setBlockNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [districts, setDistricts] = useState([]);
  const [district, setDistrict] = useState("");
  const [subdistrict, setSubdistrict] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("")

  const { CacheId } = useSelector((state) => state.session.sessionData.Configurations);

  const handlePostalCodeChange = async (code) => {
    if (!isNaN(code.trim())) setPostalCode(code.trim());

    if (code.trim().length === 5) {
      let payload = {"SessionId":CacheId, "ZipCode": code.trim()}

      // setIsBlur(true);

      let list = await dispatch(getCityStateByZipcodeApi(payload))
          .catch((err) => {
              console.log(err)
              return null;
          });

      // setIsBlur(false);

      if (list && list.GetCityStatebyZipCodeResponse?.length > 0) {
          setPostalCodeError("");
          let respArry = [...list.GetCityStatebyZipCodeResponse];
          let tempArray = []

          respArry.map((item, index) => {
              tempArray.push(
                  {
                      id: index,
                      text: item.City,
                      value: item.City
                  }
              )
          })

          setDistricts([...tempArray]);
          setProvince(respArry[0]["State"])
          setDistrict(tempArray[0].value)
      } else {
          setPostalCodeError(t("ChangeAddress.IncorrectPostalCode"));
          setDistricts([])
          setProvince("")
          setDistrict("")
      }
  } else {
      setDistricts([])
      setProvince("")
      setDistrict("")
  }
  };

  const handleDistrictChange = (value) => {
    setDistrict(value)
}

  const handleSubmit = () => {
    setDisabled(true);
    const address = {
      unitNumber: unitNumber.trim(),
      // buildingName: buildingName.trim(),
      // blockNumber: blockNumber.trim(),
      streetName: streetName.trim(),
      district: district,
      subdistrict: subdistrict.trim(),
      province: province.trim(),
      postalCode: postalCode.trim(),
    };
    dispatch(changeAddressSchedule(address, isPickup));
  };

  const cancelChangingAddress = () => {
    dispatch(changeAddressSchedule({}, isPickup));
  };

  const clearChangeAddress = () => {
    setUnitNumber("");
    setStreetName("");
    setDistricts([]);
    setSubdistrict("");
    setProvince("");
    setPostalCode("");
  }

  const shouldntSubmit =
    disabled ||
    isEmpty(unitNumber) ||
    isEmpty(streetName) ||
    isEmpty(district) || 
    isEmpty(subdistrict) || 
    isEmpty(province) || 
    isEmpty(postalCode);
    
  return (
    <BaseCard>
      {/* <BaseIcon icon={AddressIcon} name="Tracking" center /> */}
      <AddressIcon className="app-BaseIcon-center" />
      <BaseLabel>
        {which === "Return" ? (
          <Trans i18nKey="ChangeAddress.ReturnTitle">
            <TextMedium></TextMedium>
          </Trans>
        ) : (
          <Trans i18nKey="ChangeAddress.PickupTitle">
            <TextMedium></TextMedium>
          </Trans>
        )}
      </BaseLabel>
      <BaseInput
        className="mb-2"
        placeholder={t("ChangeAddress.Input.UnitNo")}
        label={t("ChangeAddress.Input.UnitNoLabel")}
        value={unitNumber}
        onChange={setUnitNumber}
        disabled={disabled}
        full
        autoFocus
      />
      {/*<BaseInput
        className="my-2"
        placeholder={t("ChangeAddress.Input.BuildingName")}
        label={t("ChangeAddress.Input.BuildingNameLabel")}
        value={buildingName}
        onChange={setBuildingName}
        disabled={disabled}
        full
      />
      <BaseInput
        className="my-2"
        placeholder={t("ChangeAddress.Input.BlockNo")}
        label={t("ChangeAddress.Input.BlockNoLabel")}
        value={blockNumber}
        onChange={setBlockNumber}
        disabled={disabled}
        full
  />*/}
      <BaseInput
        className="my-2"
        placeholder={t("ChangeAddress.Input.StreetName")}
        label={t("ChangeAddress.Input.StreetNameLabel")}
        value={streetName}
        onChange={setStreetName}
        disabled={disabled}
        full
      />

      {/* <BaseInput
        className="my-2"
        placeholder={t("ChangeAddress.Input.District")}
        label={t("ChangeAddress.Input.DistrictLabel")}
        value={districts}
        onChange={setDistricts}
        disabled={disabled}
        full
      /> */}

      <BaseInput
        className="my-2"
        placeholder={t("ChangeAddress.Input.Subdistrict")}
        label={t("ChangeAddress.Input.SubdistrictLabel")}
        value={subdistrict}
        onChange={setSubdistrict}
        disabled={disabled}
        full
      />

      <BaseInput
        placeholder={t("ChangeAddress.Input.PostalCode")}
        label={t("ChangeAddress.Input.PostalCodeLabel")}
        value={postalCode}
        onChange={handlePostalCodeChange}
        disabled={disabled}
        full
        maxLength={6}
        errorText={postalCodeError}
      />

      <BaseDropdown
          placeholder={t("ChangeAddress.Input.District")}
          label={t("ChangeAddress.Input.DistrictLabel")}
          value={district}
          onChange={handleDistrictChange}
          options={districts}
          disabled={disabled}
          identifier="value"
          pureArray={false}
          printer="text"
          isNoMargins={true}
      />

      <BaseInput
        className="my-2"
        placeholder={t("ChangeAddress.Input.Province")}
        label={t("ChangeAddress.Input.ProvinceLabel")}
        value={province}
        // onChange={setProvince}
        readOnly={true}
        disabled={disabled}
        full
        onEnterPress={shouldntSubmit ? () => { } : handleSubmit}
      />

      {/*!isPickup && (
        <BaseInformationBox className="text-20px leading-none text-app-nova">
          <Trans i18nKey="ChangeAddress.Information">
            <TextRegular>
              Please note that we’re unable to deliver to shopping malls, coffee
              shops, hawker centers, so kindly provide proper residential or
              office address here.
            </TextRegular>
          </Trans>
        </BaseInformationBox>
      )*/}
      <BaseButtonGroup>
      <BaseButton
          text={t("ClearButton")}
          onClick={clearChangeAddress}
          disabled={disabled}
          className="bg-white border-app-primary text-app-primary"
        />
        <BaseButton
          text={t("NextButton")}
          onClick={handleSubmit}
          disabled={shouldntSubmit}
          clicked={disabled}
        />
        
      </BaseButtonGroup>
    </BaseCard>
  );
};

ChangeAddress.propTypes = {
  isPickup: PropTypes.bool,
};

ChangeAddress.defaultProps = {
  isPickup: true,
};

export default ChangeAddress;
