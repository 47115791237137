import React from "react";
import BaseProgressBar from "../base/BaseProgressBar/BaseProgressBar";

const ApiProgress = ({ text, ...restProps }) => {

  const { percentage } = restProps;

  return percentage !== 0 ? (
    <div
      id="app-ApiProgress"
      className="text-center text-black text-18px my-12"
    >
      {text}
      <BaseProgressBar {...restProps} />
    </div>
  ) : (
    <></>
  );
};

export default ApiProgress;
