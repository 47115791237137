import React from "react";

const BaseButtonGroupVertical = ({ children }) => {
    return (
        <div className="app-BaseButtonGroupVertical">
            {children}
        </div>
    );
};

export default BaseButtonGroupVertical;
