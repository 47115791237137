import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

import BaseCard from "../../base/BaseCard/BaseCard";
import BaseInput from "../../base/BaseInput/BaseInput";
import BaseLabel from "../../base/BaseLabel";
import BaseInformationBox from "../../base/BaseInformationBox";
import BaseButtonGroup from "../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../base/BaseButton/BaseButton";
import BasePopup from "../../base/BasePopup/BasePopup";
import { TextRegular } from "../../base/BaseText";

import { isEmpty } from "../../helpers/formatUtils";
import WelcomeToService from "../../components/WelcomeToService/WelcomeToService";
import Config from "../Appsync/Config";
import { FIELD_IDS } from "../../helpers/constants";
import { validateMdn } from "./state/operators";
import classNames from "classnames";


const ModalTitle = ({ title }) => <span className="font-bold">{title}</span>;

const AskMdn = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [mdn, setMdn] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [freezeWindow, setFreezeWindow] = useState(false);
  const [token, setToken] = useState(null)
  const [error, setError] = useState();
  const agreement = useSelector(state => state.validation?.agreement);
  const stateLocale = useSelector(state => state.journeyMessages?.locale);

  const [recaptchaKey, setRecaptchaKey] = useState(0);
  const [recaptchaLang, setRecaptchaLang] = useState(stateLocale === "th-TH" ? "th" : "en");

  let captcha;

  useEffect(() => {
    if (agreement?.AgreementFailedAttempt > 0) {
      setError(agreement.error)
      const freeze = agreement?.AgreementFailedAttempt === 3;
      setFreezeWindow(freeze)
    }
  }, [agreement?.AgreementFailedAttempt])


  const handleMdnChange = (value) => {
    if (!isNaN(value.trim())) setMdn(value.trim());
  };

  const submitMdn = async () => {
    dispatch(validateMdn(mdn));
    setFreezeWindow(true);
  };

  const verifyCaptcha = (res) => {
    setToken(res)
  };

  const shouldSubmit = freezeWindow || isEmpty(mdn) || mdn.length < 8 || !token;

  useEffect(() => {
    let newVal = stateLocale === "th-TH" ? "th" : "en";
    setRecaptchaKey(recaptchaKey + 1);
    setRecaptchaLang(newVal)
  }, [stateLocale])

  useEffect(() => {
    captcha.reset();
  }, [recaptchaLang])

  const ErrorComponent = () => <BaseLabel className="text-text-error text-18px text-left my-1 mx-2 leading-none">
    <Trans i18nKey={error} />
  </BaseLabel>

  const captchaClass = classNames(
    "captcha-container mt-6 mb-4 text-center",
    { "pointer-events-none": freezeWindow }
  )
  return (
    <>
      <div className="app-AskMdn">
        <BaseCard className="pb-8">
          {/*<WelcomeToService />*/}
          <BaseLabel>
            <div className="text-left text-20px leading-tight">
              <Trans i18nKey="AskMdn.Label">
                For an uninterrupted experience, <br />

                <span
                  className="font-TMedium cursor-pointer font-bold underline"
                  onClick={() => setShowPopup(true)}
                >
                  please check that you are eligible to access this Screen Repair and Battery Replacment Service
                </span>
                and please have ready:
              </Trans>
            </div>
          </BaseLabel>
          <BaseInformationBox>
            <ul className="list-inside text-20px text-app-nova inline-block leading-none">
              <li>
                <Trans i18nKey="AskMdn.Information.3">
                  Your National ID/NRIC/FIN number
                </Trans>
              </li>
            </ul>
          </BaseInformationBox>
          <div className="px-2">
            <BaseInput
              id={FIELD_IDS.MDN}
              className="mb-0 w-full"
              value={mdn}
              label={t("AskMdn.InputLabel")}
              placeholder={t("AskMdn.Placeholder")}
              onChange={handleMdnChange}
              medium
              maxLength={10}
              disabled={freezeWindow}
              autoFocus
              onEnterPress={shouldSubmit ? () => { } : submitMdn}
            />
          </div>
          {error && <ErrorComponent />}
          {!freezeWindow &&
            <div className={captchaClass}>
              <ReCAPTCHA
                key={recaptchaKey}
                sitekey={Config.Captcha.SiteKey}
                onChange={verifyCaptcha}
                onExpired={() => setToken(null)}
                onErrored={() => setToken(null)}
                hl={recaptchaLang}
                ref={el => { captcha = el; }}
              />
            </div>
          }
          <BaseButtonGroup>
            <BaseButton
              id={FIELD_IDS.SUBMIT_MDN}
              name="Submit"
              text={t("AskMdn.LetsBegin")}
              onClick={submitMdn}
              clicked={freezeWindow}
              disabled={shouldSubmit}
              focused
            />
          </BaseButtonGroup>
        </BaseCard>
      </div>
      {showPopup && (
        <BasePopup
          show={true}
          title={<ModalTitle title={t("AskMdn.Popup.Title")} />}
          onClose={() => setShowPopup(false)}
          className="rounded rounded-2xl"
          isTitleCentered={false}
        >
          <ul className="px-8 list-disc list-outside font-TMedium text-20px text-srgray-base leading-none">
            <li className="my-4">
              <Trans i18nKey="AskMdn.Popup.6" />
            </li>
            <li className="my-4">
              <Trans i18nKey="AskMdn.Popup.7" />
            </li>
          </ul>

          <p className="font-TMedium text-20px text-srgray-base leading-none"><Trans i18nKey="AskMdn.Popup.8" /></p>
        </BasePopup>
      )}

    </>
  );
};

export default AskMdn;
