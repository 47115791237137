import React from "react";

const Spacer = () => <span className="pl-4"></span>;

const oldTAC = (
  <>
    1.
    <Spacer />
    <strong>How you may be Eligible for the service</strong>
    <br />
    <br />
    1.1
    <Spacer />
    In order to be eligible for the Screen Repair service, you must be an active
    subscriber of StarHub Mobile Pte Ltd’s (“<strong>us</strong>”, “
    <strong>we</strong>” or “<strong>our</strong>”) SmartSupport device
    protection programme (“<strong>SmartSupport</strong>”) and the international
    mobile equipment identity number (“<strong>IMEI</strong>”) of your mobile
    device must be registered and active under SmartSupport. Additionally, your
    mobile device make and model must be suitable for the Screen Repair service,
    as determined by us at our sole and absolute discretion from time to time.
    <br />
    <br />
    1.2 <Spacer />
    Subject to these terms and conditions, NEW Asurion Singapore Pte Ltd (“
    <strong>Asurion</strong>”), as our appointed service provider, will carry
    out a repair or replacement of the screen of your mobile device (“
    <strong>Screen Repair</strong>”) and, if any other repairs are required, the
    Screen Repair will not be carried out on your mobile device.
    <br />
    <br />
    2.
    <Spacer />
    <strong>Scope of the service</strong>
    <br />
    <br />
    2.1 <Spacer />
    Upon receipt of your mobile device, the suitability of your mobile device
    will be assessed for Screen Repair. We reserve the right not to proceed with
    the Screen Repair for any reason, whether relating to make, model and/or
    condition of the device or otherwise. In such event, your mobile device will
    not be accepted for repair and/or the unrepaired mobile device will be
    returned to you with the Repair Fee (as defined below in paragraph 5.1)
    refunded to you in full if you have paid for it.
    <br />
    <br />
    2.2 <Spacer />
    You acknowledge and agree that a Screen Repair (i) may contain parts that
    are not from the original manufacturer; and (ii) may void or otherwise
    affect the relevant manufacturer’s warranty coverage. You agree that we
    shall not be liable in connection with the same.
    <br />
    <br />
    2.3 <Spacer />
    In the event that:
    <br />
    <br />
    2.3.1
    <Spacer />
    a Screen Repair is performed and the mobile device returned to you before 11
    August 2021, Asurion will provide you with a 3-month warranty for any
    manufacturing malfunctions or defects in only the screen (as repaired or
    replaced) of your mobile device; or
    <br />
    <br />
    2.3.2
    <Spacer />
    a Screen Repair is performed and the mobile device returned to you on or
    after 11 August 2021, Asurion will provide you with a 12-month warranty for
    any manufacturing malfunctions or defects in your mobile device (excluding,
    for the avoidance of doubt, battery degradation).
    <br />
    <br />
    The warranty commences from the date of return of your mobile device to you.
    For the avoidance of doubt, the warranty by Asurion is not an extension of
    the relevant manufacturer’s warranty coverage. We shall not be responsible
    for the fulfilment of the warranty by Asurion. You have no claim against us
    in relation to (i) any manufacturing malfunctions and/or defects; and/or
    (ii) performance or non-performance of the warranty.
    <br />
    <br />
    2.4 <Spacer />
    You may only make 1 request for a Screen Repair in any rolling 12 months
    period, with such period commencing from the time of your first request for
    a Screen Repair.
    <br />
    <br />
    2.5 <Spacer />
    You acknowledge that this Screen Repair service is separate from
    SmartSupport. The Repair Fee payable is in respect of the Screen Repair only
    and is not made in connection with your subscription to SmartSupport.
    <br />
    <br />
    3.
    <Spacer />
    <strong>Privacy</strong>
    <br />
    <br />
    3.1
    <Spacer />
    You have provided your consent for the collection, use or disclosure of your
    personal data in connection with the Screen Repair in accordance with our
    prevailing data protection policy.
    <br />
    <br />
    4.
    <Spacer />
    <strong>Your Responsibilities</strong>
    <br />
    <br />
    4.1 <Spacer />
    Your mobile device (including the operating system and hardware) must not be
    modified in any manner. Prior to the handover of your mobile device for
    repair, you must:
    <br />
    <br />
    4.1.1 <Spacer />
    delete all user content and data from your mobile device. You acknowledge
    and accept that in carrying out the Screen Repair, all mobile device data
    and user content will be deleted and the mobile device will be reset to its
    factory default settings;
    <br />
    <br />
    4.1.2 <Spacer />
    disable all personal device locks (including FindMyiPhone) on your mobile
    device; and
    <br />
    <br />
    4.1.3 <Spacer />
    remove any accessories, stylus, SIM cards, SD cards or screen protectors
    from your mobile device and not provide any of these to us or Asurion.
    <br />
    <br />
    5. <Spacer />
    <strong>Paying for the Service</strong>
    <br />
    <br />
    5.1 <Spacer />
    The Screen Repair fee is:
    <br />
    <br />
    5.1.1 <Spacer />
    S$99.00 (inclusive of GST); or
    <br />
    <br />
    5.1.2 <Spacer />
    S$299 (inclusive of GST) if the mobile device has a folding screen and had a
    recommended SGD retail price (as published by the mobile device
    manufacturer) greater than S$2,300 (inclusive of GST) as at the date of
    launch in Singapore by StarHub.
    <br />(<strong>"Repair Fee".</strong>)
    <br />
    <br />
    5.2 <Spacer />
    Your mobile device will be collected and returned to you at a mutually
    agreed location and time. You accept and acknowledge that the timing of the
    return of your mobile device is subject to the availability of replacement
    parts and availability of our delivery personnel. Our delivery personnel
    will verify your identity at the time of collection and return of your
    mobile device and will seek your written acknowledgement and acceptance at
    both times. You may be charged an additional fee if you are not present when
    we attempt to collect your mobile device from you or at the time we attempt
    to return your mobile device to you at the indicated times. Access to Screen
    Repair is available in Singapore only.
    <br />
    <br />
    6. <Spacer />
    <strong>Liability</strong>
    <br />
    <br />
    6.1 <Spacer />
    We and Asurion shall not be liable for:
    <br />
    <br />
    6.1.1 <Spacer />
    any loss of data and/or user content on your mobile device and any misuse of
    data and/or user content on your mobile device arising from your failure to
    delete the same;
    <br />
    <br />
    6.1.2 <Spacer />
    any loss associated with timing for collection of your mobile device or
    return of your mobile device following a Screen Repair;
    <br />
    <br />
    6.1.3 <Spacer />
    any damage sustained to your mobile device arising from the inspection
    and/or diagnostics of your mobile device or the Screen Repair service
    generally;
    <br />
    <br />
    6.1.4 <Spacer />
    any loss or damage to any accessory, stylus, SIM card or SD card; and
    <br />
    <br />
    6.1.5 <Spacer />
    any damage sustained to your mobile device in transit from the time of
    collection from you until the return of the mobile device to you.
    <br />
    <br />
    7. <Spacer />
    <strong>Changes to this Agreement</strong>
    <br />
    <br />
    7.1 <Spacer />
    These terms and conditions, the service and/or price may be revised by us
    from time to time and your use of the service shall constitute your
    acceptance of these terms and conditions and their revisions.
    <br />
  </>
);

const newTAC = (
  <>
    1.
    <Spacer />
    <strong>How you may be Eligible for the Services </strong>
    <br />
    <br />
    1.1 <Spacer />
    In order to be eligible for the Services (as defined below), you must be an
    active subscriber of StarHub Mobile Pte Ltd’s (“<strong>us</strong>”, “
    <strong>we</strong>” or “<strong>our</strong>”) SmartSupport device
    protection programme (“<strong>SmartSupport</strong>”) and the international
    mobile equipment identity number (“<strong>IMEI</strong>”) of your mobile
    device must be registered and active under SmartSupport. Additionally, your
    mobile device make and model must be suitable for the Services, as
    determined by us from time to time at our sole and absolute discretion.
    <br />
    <br />
    1.2 <Spacer />
    Subject to these terms and conditions, NEW Asurion Singapore Pte Ltd (“
    <strong>Asurion</strong>”), as our appointed service provider, will carry
    out:
    <br />
    <br />
    1.2.1 <Spacer />a repair or replacement of the screen of your mobile device
    (“<strong>Screen Repair</strong>”); or
    <br />
    1.2.2 <Spacer />a replacement of the battery of your mobile device (“
    <strong>Battery Replacement</strong>”); or
    <br />
    1.2.3 <Spacer />a Screen Repair and a Battery Replacement,
    <br />
    (each a “<strong>Service</strong>” and collectively the “
    <strong>Services</strong>”)and, if any other repairs are required apart from
    the Screen Repair and/or the Battery Replacement, the Screen Repair and/or
    the Battery Replacement will not be carried out on your mobile device.
    <br />
    <br />
    2. <Spacer />
    <strong>Scope of the Services</strong>
    <br />
    <br />
    2.1 <Spacer />
    Upon receipt of your mobile device, the suitability of your mobile device
    will be assessed for the applicable Service or Services. We reserve the
    right not to proceed with the Service or Services for any reason, whether
    relating to make, model, age and/or condition of the device or otherwise. In
    such event, your mobile device will not be accepted for repair and/or the
    unrepaired mobile device will be returned to you with the Fee (as
    particularised in paragraph 7) refunded to you in full if you have paid for
    it.
    <br />
    <br />
    2.2 <Spacer />
    Without prejudice to the generality of paragraph 2.1 above, the following
    mobile devices are not suitable for Battery Replacement, mobile devices:
    <br />
    2.2.1 <Spacer />
    with a battery capacity of 80% or more (for iPhones), not graded as “bad”
    (for Samsung mobile devices) nor other Android operating devices with
    batteries which pass battery health status checks as may be indicated on
    screenrepair.starhub.com from time to time; and
    <br />
    2.2.2
    <Spacer />
    which have a battery that is bloated or swollen or is subject to (i) a
    recall by the manufacturer or (ii)a manufacturer’s service program.
    <br />
    <br />
    2.3 <Spacer />
    You acknowledge and agree that (a) a Screen Repair and/or (b) a Battery
    Replacement, as the case may be, may (i) contain parts that are not from the
    original manufacturer; and (ii) void or otherwise affect the relevant
    manufacturer’s warranty coverage. You agree that we shall not be liable in
    connection with the same.
    <br />
    <br />
    2.4 <Spacer />
    In the event that:
    <br />
    <br />
    2.4.1 <Spacer /> only a Screen Repair is performed, Asurion will provide you
    with a 12-month warranty for any manufacturing malfunctions or defects in
    your mobile device (excluding, for the avoidance of doubt, battery
    degradation); or
    <br />
    2.4.2 <Spacer />
    only a Battery Replacement is performed, Asurion will provide you with a
    3-month warranty for any manufacturing malfunctions or defects in only the
    battery (as replaced) of your mobile device; or
    <br />
    2.4.3 <Spacer />a Screen Repair and a Battery Replacement are performed,
    Asurion will provide you with:
    <br />
    2.4.3.1 <Spacer />a 3-month warranty for any manufacturing malfunctions or
    defects in only the battery (as replaced) with respect to the Battery
    Replacement; and
    <br />
    2.4.3.2 <Spacer />a 12-month warranty for any manufacturing malfunctions or
    defects in your mobile device excluding the battery with respect to the
    Screen Repair, of your mobile device The warranty commences from the date of
    return of your mobile device to you. For the avoidance of doubt, the
    warranty by Asurion is not an extension of the relevant manufacturer’s
    warranty coverage. We shall not be responsible for the fulfilment of the
    warranty by Asurion. You have no claim against us in relation to (i) any
    manufacturing malfunctions and/or defects; and/or (ii) performance or
    non-performance of the warranty.
    <br />
    <br />
    3 <Spacer /> <strong>Limits</strong>
    <br />
    <br />
    3.1 <Spacer />
    For Screen Repair, you may make 3 requests for a Screen Repair in any
    rolling 12 months period, with such period commencing from the time of your
    first request for a Screen Repair;
    <br />
    <br />
    3.2 <Spacer />
    For Battery Replacement you may only make 1 request for Battery Replacement
    per mobile device; and
    <br />
    <br />
    3.3 <Spacer />
    For a Screen Repair and Battery Replacement paragraph 3.1 applies with
    respect to Screen Repair component and paragraph 3.2 applies with respect to
    the Battery Replacement.
    <br />
    <br />
    4 <Spacer />
    <strong>Acknowledgment</strong>
    <br />
    <br />
    4.1 <Spacer />
    You acknowledge that the Services are separate from SmartSupport. The Fee
    payable for the Services are in respect of the Services only and are not
    made in connection with your subscription to SmartSupport.
    <br />
    <br />
    5 <Spacer />
    <strong>Privacy</strong>
    <br />
    <br />
    5.1 <Spacer />
    You have provided your consent for the collection, use or disclosure of your
    personal data in connection with the Services in accordance with our
    prevailing data protection policy.
    <br />
    <br />
    6 <Spacer />
    <strong>Your Responsibilities</strong>
    <br />
    <br />
    6.1 <Spacer />
    Your mobile device (including the operating system and hardware) must not be
    modified in any manner. Prior to the handover of your mobile device for
    repair, you must:
    <br />
    6.1.1 <Spacer />
    delete all user content and data from your mobile device. You acknowledge
    and accept that in carrying out the Screen Repair and/or Battery
    Replacement, all mobile device data and user content will be deleted and the
    mobile device will be reset to its factory default settings;
    <br />
    6.1.2 <Spacer />
    disable all personal device locks (including FindMyiPhone) on your mobile
    device; and
    <br />
    6.1.3 <Spacer />
    remove any accessories, stylus, SIM cards, SD cards or screen protectors
    from your mobile device and not provide any of these to us or Asurion.
    <br />
    <br />
    7 <Spacer />
    <strong>Paying for the Service</strong>
    <br />
    <br />
    7.1 <Spacer />
    The fee for:
    <br />
    7.1.1 <Spacer />
    Screen Repair is:
    <br />
    7.1.1.1 <Spacer />
    S$79.00 (inclusive of GST); or
    <br />
    7.1.1.2 <Spacer />
    S$299 (inclusive of GST) if the mobile device has a folding screen and had a
    recommended SGD retail price (as published by the mobile device
    manufacturer) greater than S$2,300 (inclusive of GST) as at the date of
    launch in Singapore by StarHub.
    <br />
    7.1.2 <Spacer />
    Battery Replacement is S$79 (inclusive of GST); and
    <br />
    7.1.3 <Spacer />
    Screen Repair and Battery Replacement fee is $158 (inclusive of GST) or $378
    (inclusive of GST) if the mobile device has a folding screen,
    <br />
    (each a "<strong>Fee</strong>").
    <br />
    <br />
    7.2 <Spacer />
    Your mobile device will be collected and returned to you at a mutually
    agreed location and time. You accept and acknowledge that the timing of the
    return of your mobile device is subject to the availability of replacement
    parts and availability of our delivery personnel. Our delivery personnel
    will verify your identity at the time of collection and return of your
    mobile device and will seek your written acknowledgement and acceptance at
    both times. You may be charged an additional fee if you are not present when
    we attempt to collect your mobile device from you or at the time we attempt
    to return your mobile device to you at the indicated times. Access to the
    Services are available in Singapore only.
    <br />
    <br />
    8 <Spacer />
    <strong>Liability</strong>
    <br />
    <br />
    8.1 <Spacer />
    We and Asurion shall not be liable for:
    <br />
    8.1.1 <Spacer />
    any loss of data and/or user content on your mobile device and any misuse of
    data and/or user content on your mobile device arising from your failure to
    delete the same;
    <br />
    8.1.2 <Spacer />
    any loss associated with timing for collection of your mobile device or
    return of your mobile device following a Screen Repair and/or Battery
    Replacement;
    <br />
    8.1.3 <Spacer />
    any damage sustained to your mobile device arising from the inspection
    and/or diagnostics of your mobile device or the Screen Repair and/or Battery
    Replacement generally;
    <br />
    8.1.4 <Spacer />
    any loss or damage to any accessory, stylus, SIM card or SD card; and
    <br />
    8.1.5 <Spacer />
    any damage sustained to your mobile device in transit from the time of
    collection from you until the return of the mobile device to you.
    <br />
    <br />
    9 <Spacer />
    <strong> Changes to this Agreement</strong>
    <br />
    These terms and conditions, the Service(s) and/or Fee may be revised by us
    from time to time and your use of the Service(s) shall constitute your
    acceptance of these terms and conditions and their revisions
  </>
);

export { oldTAC, newTAC };
