import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { TextLight, TextMedium } from "../base/BaseText";
import BaseButton from "../base/BaseButton/BaseButton";
import { ReactComponent as ErrorIcon } from "../images/icon-error.svg";
import BaseIcon from "../base/BaseIcon/BaseIcon";

const ErrorNote = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full bg-white flex flex-col overflow-hidden relative rounded rounded-3xl">
      <div className="w-full">
        <div className="mx-auto text-22px mt-32 text-center relative z-10">
          {/* <BaseIcon icon={ErrorIcon} name="Error" center /> */}
          <ErrorIcon className="app-BaseIcon-center" />
          <div>
            <TextMedium>503</TextMedium> Service Unavailable
          </div>
          <Trans i18nKey="ErrorNote.Title">
            <TextLight>
              We were unable to process your request due to a technical issue.{" "}
              <br /> Please try after sometime.
              <br />
              <br />
              Thank you for using Repair Service!
            </TextLight>
          </Trans>
          <BaseButton
            text={t("CloseButton")}
            onClick={onClose}
            autoFocus
            className="mx-auto w-11r mt-8"
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorNote;
