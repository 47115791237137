import React, { useState } from "react";
import { TextBold, TextLight, TextMedium } from "../../../base/BaseText";
import WalkInImage from "../../../images/true/image-repair-option-walkin.svg";
import PURImage from "../../../images/true/image-repair-option-pur.svg";
import BaseCard from "../../../base/BaseCard/BaseCard";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import { confirmationOfRepairOption } from "../state/operators/RepairOptionsOperator";

const SelectRepairOption = () => {
  const { t }= useTranslation();
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);
  const [isWalkIn, setWalkIn] = useState(false);
  const [isPUR, setPUR] = useState(false);

  function selectOption(option) {
    setDisabled(true);
    let repairOption, textToPrint;
    if (option === "WalkIn") {
      setWalkIn(true);
      repairOption = "WALKIN";
      textToPrint = t("SelectRepairOption.OptionWalkIn")
        .replace(/[<0><0/><1><1/><3><3/>]/g, "")
        .trim();
    } else {
      setPUR(true);
      repairOption = "PUR";
      textToPrint = t("SelectRepairOption.OptionPUR")
        .replace(/[<0><0/><1><1/><3><3/>]/g, "")
        .trim();
    }

    dispatch(confirmationOfRepairOption(repairOption, textToPrint));
  }

  return (
    <div>
      <div className="text-center mx-auto">
        <div className="my-0 mt-4 text-22px leading-none font-bold">
          <Trans i18nKey="SelectRepairOption.title">
            <TextMedium>Please select your preferred repair option:</TextMedium>
          </Trans>
        </div>

        <div className="my-0 mt-4 mb-6 text-22px leading-none">
          <Trans i18nKey="SelectRepairOption.subTitle">
            <TextMedium>You will not be able to change the repair option for this request after this step.</TextMedium>
          </Trans>
        </div>
        {/* <Trans i18nKey="SelectRepairOption.subTitle">
          <TextLight className="text-xl">
            You will not be able to change the repair option for this request
            after this step.
          </TextLight>
        </Trans> */}
      </div>
      <div className="flex justify-center gap-4 px-4 mx-auto" disabled={disabled} style={{ maxWidth: "430px"}}>
        <BaseCard
          className={`w-50% flex flex-col p-0 mx-0 ${
            isWalkIn ? "bg-accordion-bg border-app-primary border-2" : "border"
          }`}
        >
          <div
            className={`${disabled ? "cursor-not-allowed pointer-events-none" : "cursor-pointer"}`}
            onClick={() => !disabled && selectOption("WalkIn")}
          >
            <div className="h-48">
              <img className="h-full p-4 mx-auto" src={WalkInImage} alt="Walk-In" />
            </div>
            <div className="text-center text-20px leading-none mt-2 px-4 pb-4">
              <Trans i18nKey="SelectRepairOption.OptionWalkIn">
                <TextMedium>
                  Walk-In
                  <br /> <TextMedium>to service Center</TextMedium>
                </TextMedium>
              </Trans>
            </div>
          </div>
        </BaseCard>

        <BaseCard
          className={`w-50% flex flex-col p-0 mx-0 ${
            isPUR ? "bg-accordion-bg border-app-primary border-2" : "border"
          }`}
        >
          <div
            className={`${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
            onClick={() => !disabled && selectOption("PUR")}
          >
            <div className="h-48">
              <img className="h-full p-4 mx-auto" src={PURImage} alt="PUR" />
            </div>
            <div className="text-center text-20px leading-none mt-2 px-4 pb-4">
              <Trans i18nKey="SelectRepairOption.OptionPUR">
                <TextMedium>
                  Pick-up and Return
                  <br />
                  <TextMedium> to my address</TextMedium>
                </TextMedium>
              </Trans>
            </div>
          </div>
        </BaseCard>
      </div>
    </div>
  );
};

export default SelectRepairOption;
