import React from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { TextLight } from "../../base/BaseText";
import IncorrectMdnIcon from "../../images/true/icon-incorrect.svg";
import IncorrectScreen from "../../components/IncorrectScreen";

const IncorrectMdn = () => {
  const agreementFailedAttempt = useSelector(
    (state) => state.validation.agreement.AgreementFailedAttempt
  );
  const messageKey = agreementFailedAttempt > 2 ? "MdnTerminate" : "Mdn";

  return (
    <IncorrectScreen icon={IncorrectMdnIcon} name="Incorrect Mdn">
      <Trans i18nKey={`IncorrectMessage.${messageKey}`}>
        <TextLight>
          We regret to inform you that the mobile number you've provided doesn't
          match the details on our record.
        </TextLight>
      </Trans>
    </IncorrectScreen>
  );
};

export default IncorrectMdn;
