import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { TextMedium, TextRegular } from "../../../base/BaseText";

import { isRegisteredDeviceConfirmed } from "../state/operators/deviceConfirmationOperators";
import { toPascalCase } from "../../../helpers/formatUtils";
//import { ReactComponent as RegPhoneIcon } from "../../../images/image-phone-huawei.svg";
import { ReactComponent as RegPhoneIcon } from "../../../images/true/icon-device-confim.svg";
import moment from 'moment';

const ConfirmRegisteredDevice = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const enrolledAssetData = useSelector((state) => state.validation.verification.enrolledAsset);
  const replacedAssetData = useSelector((state) => state.validation.verification.replacedAsset);

  const getLatestAssetData = () => {
    let latestAsset = enrolledAssetData;

    if (enrolledAssetData.EndDate) {
      if (!moment(enrolledAssetData.EndDate).isValid() || moment().isAfter(moment(enrolledAssetData.EndDate))) {
        if (replacedAssetData &&
          replacedAssetData.StartDate &&
          (moment(replacedAssetData.StartDate).isAfter(moment(enrolledAssetData.StartDate)) ||
            moment(replacedAssetData.CreatedDate).isAfter(moment(enrolledAssetData.CreatedDate)))) {
          latestAsset = replacedAssetData;
        }
      }
    } else {
      if (replacedAssetData) {
        if (moment(enrolledAssetData.StartDate).format('YYYYMMDD') !==
          moment(replacedAssetData.StartDate).format('YYYYMMDD') &&
          moment(replacedAssetData.StartDate).isAfter(moment(enrolledAssetData.StartDate))) {
            latestAsset = replacedAssetData;
        }
      }
    }

    return latestAsset;
  }
  
  let assetData = getLatestAssetData();

  const registeredPhone = `${toPascalCase(assetData.Make.Name)} ${assetData.AssetCatalog.AssetCatalogName}`;
  const [disabled, setDisabled] = useState(false);
  const [isCorrectDevice, setIsCorrectDevice] = useState("");

  const handleSubmit = (isDeviceRegistered) => {
    setIsCorrectDevice(isDeviceRegistered ? "Yes" : "No");
    setDisabled(true);
    dispatch(isRegisteredDeviceConfirmed(isDeviceRegistered, assetData));
  };

  return (
    <BaseCard>
      <BaseLabel className="py-2">
        <Trans i18nKey="ConfirmRegisteredDevice.Title2" components={{ medium: <TextMedium /> }} />
      </BaseLabel>
      <div className="flex mb-5">
        <div className="ml-auto">
          <RegPhoneIcon />
        </div>
        <div className="ml-4 mr-auto my-auto">
          <div className="text-20px text-left w-20r mx-auto leading-7 text-srgray-base">
            <TextMedium>{registeredPhone}</TextMedium>
          </div>
          <div className="text-20px text-left w-full leading-7 text-srgray-base font-bold">
            <Trans i18nKey="ConfirmRegisteredDevice.RegisteredDevice">
              <TextRegular>Registered Device</TextRegular>
            </Trans>
          </div>
        </div>

      </div>
      
      <BaseButtonGroup>
        <BaseButton
          text={t("YesButton")}
          onClick={() => handleSubmit(true)}
          disabled={disabled}
          clicked={isCorrectDevice === "Yes"}
          className="bg-white border-app-primary text-app-primary"
          focused
        />
        <BaseButton
          text={t("NoButton")}
          onClick={() => handleSubmit(false)}
          disabled={disabled}
          clicked={isCorrectDevice === "No"}
          className="bg-white border-app-primary text-app-primary"
          focused
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ConfirmRegisteredDevice;
