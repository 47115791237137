import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { ReactComponent as ExpandIcon } from "../../images/expand-icon.svg";
import { ReactComponent as CollapseIcon } from "../../images/collapse-icon.svg";
import { ReactComponent as CheckIcon } from "../../images/icon-check.svg";

const BaseAccordianOption = ({ id, onClick, selected, disabled, ...data }) => {
  const { name, label, options, trans } = data;
  const optionsAvailable = options.length > 0;
  const isSelected = name === selected;
  const { t } = useTranslation();
  const [expand, setExpand] = useState(false);
  const handleOptionClick = () => {
    if (!disabled) {
      if (optionsAvailable) setExpand(!expand);
      else onClick({ name, label, trans });
    }
  };

  return (
    <>
      <div
        id={id}
        className={classNames(
          "app-BaseAccordian__option",
          {
            "app-BaseAccordian__option-expanded":
              expand && !isSelected,
          },
          {
            "app-BaseAccordian__option-normal":
              !expand && !isSelected,
          },
          {
            "app-BaseAccordian__option-selected": isSelected,
          },
          {
            "border-srgray-septenary bg-srgray-septenary text-srgray-senary cursor-not-allowed": disabled,
          }
        )}
        onClick={handleOptionClick}
      > 
        <div className="app-BaseAccordian__label">{t(`${trans}`)}</div>
        {optionsAvailable && (
          <div className="app-BaseAccordian__expandBtn">
            {expand ? <CollapseIcon /> : <ExpandIcon />}
          </div>
        )}
        {/*isSelected && (
          <div className="app-BaseAccordian__checkedIcon">
            <CheckIcon />
          </div>
        )*/}
      </div>
      {optionsAvailable && (
        <div
          id={`${id}__childOptions`}
          className={`app-BaseAccordian__child-${
            expand ? "show" : "hide"
          }`}
        >
          {options.map((option, i) => (
            <BaseAccordianOption
              key={`${id}_${option.name}`}
              id={`${id}_${option.name}`}
              onClick={onClick}
              selected={selected}
              disabled={disabled}
              {...option}
            />
          ))}
        </div>
      )}
    </>
  );
};

BaseAccordianOption.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  trans: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  onClick: PropTypes.func.isRequired,
};

BaseAccordianOption.defaultProps = {
  options: [],
};

const BaseAccordian = ({
  options,
  onClick,
  selected,
  disabled,
  disabledKeys,
}) => {
  return (
    <div className="app-BaseAccordian">
      {options.map((option, i) => (
        <BaseAccordianOption
          key={`${option.name}_${i}`}
          id={`${option.name}_${i}`}
          selected={selected}
          onClick={onClick}
          disabled={disabled || disabledKeys.includes(option.name)}
          {...option}
        />
      ))}
    </div>
  );
};

BaseAccordian.defaultProps = {
  disabled: false,
};

BaseAccordian.propTypes = {
  options: PropTypes.array.isRequired,
  disabledKeys: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.string,
  disabled: PropTypes.bool,
};

export default BaseAccordian;
