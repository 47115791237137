import {callInteractionApi} from "./../reducers/sessionReducer";

import CONSTANTS from "./../helpers/constants";

const interactionApi = (cacheId, username, language = "th-TH") => ({
    type: callInteractionApi.toString(),
    meta: {
        async: true,
        blocking: true,
        path: `/incidentidentification/v1/interaction/create`,
        method: "POST",
        body: {
            CreateInteraction: {
                Interaction: {
                    SessionId: cacheId,
                    AgentName: username,
                    InteractionType: CONSTANTS.INTERACTION_TYPE,
                    InteractionStatus: CONSTANTS.INTERACTION_STATUS,
                    CacheId: cacheId,
                    StartTime: new Date().toISOString(),
                    ChannelName: CONSTANTS.CHANNEL_NAME,
                    ClientProfileId: CONSTANTS.CLIENT_PROFILE_ID,
                    SourceIPAddress: '127.0.0.1',
                    ExternalSystemName: CONSTANTS.EXTERNAL_SYSTEM_NAME,
                    SystemName: "Online",
                    InteractionLine: {
                        InteractionLineReason: CONSTANTS.INTERACTION_LINE_REASON,
                        Language: language,
                    },
                },
            }
        }
    },
});

export default interactionApi;