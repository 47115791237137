import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "../../../helpers/formatUtils";

let initialState = {
  inputData: {},
};

const validationReducer = createSlice({
  name: "validation",
  initialState,
  reducers: {
    resetStore() {
      return initialState;
    },
    callAgreementsApi(state, action) {
      state.agreement = action.payload || {};
    },
    callAgreementsApiSuccess(state, action) {
      state.agreement = action.payload.Agreement || {};
      // Setting error messages if mdn is invalid
      if(action.payload.Agreement && !action.payload.Agreement?.AgreementFound){
        const { AgreementFailedAttempt } = action.payload.Agreement;
        state.agreement.error = `IncorrectMessage.MdnFailedAttempt.${AgreementFailedAttempt}`;
      }
    },
    callAgreementsApiFailure(state, action) {
      state.error = action.payload;
    },


    callVerifyApi(state, action) {
      state.verification = action.payload || {};
    },

    // callVerifyApiSuccess(state, action) {
    //   const {
    //     VerificationOutcome: { Agreement, ...restData },
    //   } = action.payload;
    //   if (!isEmpty(Agreement)) {
    //     /**
    //      * ClientOffer & ClientProductSkuNbr was getting replaced by below Agreement
    //      * Need to use those value to show/hide some inquiry options
    //      */
    //     const { ClientProductSkuNbr, ClientOffer } = state.agreement;
    //     state.agreement = {
    //       ...Agreement,
    //       ClientProductSkuNbr,
    //       ClientOffer
    //     };
    //   }
    //   state.verification = restData;
    // },
    callVerifyApiSuccess(state, action) {
      const {
        VerificationOutcome: { Agreement, ...restData },
      } = action.payload;
      if (!isEmpty(Agreement)) {
        const { ClientProductSkuNbr, ClientOffer } = state.agreement
        // state.agreement = Agreement;
        state.agreement = {
          ...Agreement[0],
          ClientProductSkuNbr,
          ClientOffer
        }
      }

      const { VerificationOutcome } = action.payload;
      state.verification = VerificationOutcome;
    },

    callVerifyApiFailure(state, action) {
      state.error = action.payload;
    },


    callVerifyAgreementsApi(state, action) {
      state.verifyAgreement = action.payload || {};
    },
    callVerifyAgreementsApiSuccess(state, action) {
      state.verifyAgreement = action.payload;
    },
    callVerifyAgreementsApiFailure(state, action) {
      state.error = action.payload;
    },


    setMdn(state, action) {
      state.inputData.mdn = action.payload.mdn;
    },
    setNames(state, action) {
      state.inputData.firstName = action.payload.firstName;
      state.inputData.lastName = action.payload.lastName;
    },
    setNric(state, action) {
      state.inputData.nric = action.payload.nric;
    },
    setContactDetails(state, action) {
      state.inputData.phoneNumber = action.payload.phoneNumber;
      state.inputData.emailAddress = action.payload.emailAddress;
    },
  },
});

const { actions, reducer } = validationReducer;
export const {
  resetStore,
  callAgreementsApi,
  callAgreementsApiFailure,
  callVerifyApi,
  callVerifyAgreementsApi,
  setMdn,
  setNames,
  setNric,
  setContactDetails,
} = actions;
export default reducer;
