import React from "react";
import classNames from "classnames";
import Timestamp from "./Timestamp";
import { useTranslation } from "react-i18next";
import { isEmpty, sanitize } from "../helpers/formatUtils";

const TextMessage = ({ source, data, time }) => {
  const { t } = useTranslation();
  const isUser = source === "User";
  const isSystem = source === "System" || source === "Agent";

  return (
    <div
      className={classNames(
        "app__message",
        { "app__message-left": isSystem },
        { "app__message-right": isUser }
      )}
    >
      <Timestamp time={time}>
        <div
          className={classNames(
            "app__message-content",
            { "app__message-received": isSystem },
            { "app__message-send": isUser }
          )}
          dangerouslySetInnerHTML={{
            __html: sanitize(!isEmpty(data) && !isEmpty(data.key)
              ? t(data.key, { ...data.values })
              : data)
          }}
        >
          {/*!isEmpty(data) && !isEmpty(data.key)
            ? t(data.key, { ...data.values })
        : data*/}
        </div>
      </Timestamp>
    </div>
  );
};

export default TextMessage;
