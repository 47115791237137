import { useState } from "react";
import { useTranslation } from "react-i18next";

import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";

import BaseCard from "../../../base/BaseCard/BaseCard";
import Config from "../../../modules/Appsync/Config";

const Faq = () => {
  const [src] = useState(`${Config.WPAPIServer.baseUrl}${Config.WPAPIServer.faq}`);
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => {
    setDisabled(true);
    window.location.reload();
  };

  return (
    <BaseCard>
      <iframe src={src} width="100%" className="block h-52r w-full" frameBorder="0" title="FAQ" />
      <BaseButtonGroup>
        <BaseButton text={t("CloseButton")} disabled={disabled} onClick={handleClose}></BaseButton>
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default Faq;
