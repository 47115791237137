import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseInput from "../../../base/BaseInput/BaseInput";
import { TextBold, TextLight, TextRegular } from "../../../base/BaseText";
import { EnumEnquiryModules } from "../../../components/EnquiryOptions/Constants";
import { isEmpty } from "../../../helpers/formatUtils";
import { Trans, useTranslation } from "react-i18next";
import {
  confirmationOfRepairCenter,
  displayRepairDetails,
  displayRepairOption,
} from "../state/operators/RepairOptionsOperator";
import RepairCenter from "./RepairCenter";

const SelectRepairCenter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);
  const [searchKey, setSearchKey] = useState('');

  const enquiryOption = useSelector(
    (state) => state.journeyMessages.enquiryOption
  );

  const isChangeSchedule = enquiryOption === EnumEnquiryModules.ChangeSchedule;

  const storeList = useSelector(
    (state) => state.claim.walkIn.stores.ASPStoresResponse
  );

  const [displayedStores, setDisplayedStores] = useState(storeList);
  const [selectedAsp, setSelectedAsp] = useState({});

  const handleSubmit = () => {
    setDisabled(true);
    dispatch(confirmationOfRepairCenter(selectedAsp));
  };

  const goBack = () => {
    const textPrint = t("GoBack");
    setDisabled(true);
    if (isChangeSchedule) {
      dispatch(displayRepairDetails(textPrint));
    } else {
      dispatch(displayRepairOption(textPrint));
    }
  };

  const saveSelectedASP = (asp) => {
    setSelectedAsp(asp);
  };

  const displayStoreList = (asp, i) => {
    return (
      <RepairCenter
        key={i}
        asp={asp}
        saveASP={saveSelectedASP}
        disabled={disabled}
      />
    );
  };

  useEffect(() => {
    if (searchKey === '') {
      setDisplayedStores(storeList);
    } else {
      setDisplayedStores(storeList.filter(store => {
        return store.POSTAL_CODE?.toLowerCase().includes(searchKey.toLowerCase()) || store.STATE_PROVINCE_NAME?.toLowerCase().includes(searchKey.toLowerCase()) || store.CITY_NAME?.toLowerCase().includes(searchKey.toLowerCase());
      }));
    }
  }, [searchKey])

  const shouldSubmit = disabled || isEmpty(selectedAsp);

  return (
    <BaseCard>
      <BaseLabel className="text-22px leading-none">
        <Trans i18nKey="WalkIn.RepairCenter.title">
          <TextBold>
            Select preferred Repair Centre
            <br /> <TextBold>location below</TextBold>
          </TextBold>
        </Trans>
      </BaseLabel>
      {displayedStores.length > 0 && displayedStores.map((e, i) => displayStoreList(e, i))}
      <BaseInput
        placeholder={t("ChangeAddress.Input.ZipProvince")}
        label={t("ChangeAddress.Input.ZipCityLabel")}
        value={searchKey}
        onChange={(value) => setSearchKey(value)}
        full
      />
      <div className="mt-6 mx-auto">
        <BaseButtonGroup>
          <BaseButton
            text={t("GoBack")}
            onClick={goBack}
            disabled={disabled}
            className="bg-white border-app-primary text-app-primary"
          />
          <BaseButton
            text={t("SubmitButton")}
            onClick={handleSubmit}
            clicked={disabled}
            disabled={shouldSubmit}
          />
        </BaseButtonGroup>
      </div>
    </BaseCard>
  );
};

export default SelectRepairCenter;
