import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import classNames from "classnames";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import { TextRegular } from "../../../base/BaseText";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseTable, {
  BaseTR,
  BaseTD,
  BaseTH,
} from "../../../base/BaseTable/BaseTable";

import { ReactComponent as ConfirmIcon } from "../../../images/true/triage/troubleshoot-others.svg";
import {
  confirmRequestDetailsSubmission,
  cancelRepairDetailsConfirmation,
} from "../state/operators/repairDetailsOperator";
import {
  EnumEnquiryModules,
  INCIDENT_BATTERY_AND_SCREEN,
  PERIL_TYPES,
  REPAIR_TYPES
} from "../../../components/EnquiryOptions/Constants";
// import { getFormmatedDate } from "../../../helpers/dateUtils";
import { getDeliverySlot, getFormatedTime, getFormmatedDate } from "../../../helpers/dateUtils";
import { isEmpty, sanitize } from "../../../helpers/formatUtils";
import { INCIDENT_PATH_TYPE } from "../../../helpers/constants";
import { isClaimInWarranty } from "../state/operators/contactsOperator";
import moment from 'moment-timezone';


/**
 * For Battery and screen replacement
 * We calculate fee using screen repair node + battery replacement node
 * 
 * For IW - battery and screen replacement
 * We calculate fee using screen repair IW node + battery replacement node
 */
const calcFeeForBatAndScr = (state) => {
  const { BatteryReplacement, ScreenRepairWarranty } = state;
  const calcFeeNodes = [ScreenRepairWarranty, BatteryReplacement];

  const batScrReplacementFees = calcFeeNodes.reduce((acc, peril) => {
    const { FeeAmount, TaxAmount, TotalAmount, FeeType } = peril;
    const type = acc.FeeType ? `${acc.FeeType}-${FeeType}` : FeeType;
    const fee = (acc.FeeAmount ? parseFloat(acc.FeeAmount) + parseFloat(FeeAmount) : FeeAmount).toString();
    const tax = (acc.TaxAmount ? parseFloat(acc.TaxAmount) + parseFloat(TaxAmount) : TaxAmount).toString();
    const total = (acc.TotalAmount ? parseFloat(acc.TotalAmount) + parseFloat(TotalAmount) : TotalAmount).toString();
    acc = { ...peril, FeeType: type, FeeAmount: fee, TaxAmount: tax, TotalAmount: total };
    return acc;
  }, {})

  return batScrReplacementFees
}

const ConfirmRepairDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const { caseType } = useSelector((state) => state.journeyMessages);
  const perilType = caseType && caseType.replace(/\s+/g, "");

  const enquiryOption = useSelector(
    (state) => state.journeyMessages.enquiryOption
  );

  const { type: incidentPathType, serviceRequestDetails } = useSelector((state) => state.serviceRequest);

  const isCancelRequest = enquiryOption === EnumEnquiryModules.CancelRequest;
  const isChangeSchedule = enquiryOption === EnumEnquiryModules.ChangeSchedule;
  const isSingtel = false;
  const currency = "THB";

  const getAddressText = (address) => {

    const {
      Address1,
      Address2,
      Address3,
      City,
      PostalCode,
      StateProvinceCode,
    } = address ?? {};

    return `${Address1 || ""} ${Address2 || ""} ${Address3 || ""} 
      ${City || ""} ${PostalCode || ""} ${!isSingtel ? StateProvinceCode : ""}`;
  };

  const determinDetails = useSelector(
    (state) => state.serviceRequest.determineIncidentDetails
  );
  const isNoActionRequired =
    incidentPathType === INCIDENT_PATH_TYPE.NO_ACTION_REQUIRED;
  let determinePickupSlot = "";
  let determineDeliverySlot = getDeliverySlot()[0]; // at the moment rerurn slot is fixed as "2pm - 6pm"
  if (isNoActionRequired || isCancelRequest || isChangeSchedule) {
    const slotH = determinDetails?.ShippingOrderOutbound?.DeliveryWindow;
    determinePickupSlot =
      slotH === "16H" ? "12pm - 4pm" : slotH === "12H" ? "9am - 12pm" : "";
  }
  const scheduleData = useSelector((state) => state.claim.schedule);

  const repairOption = useSelector((state) =>
    isNoActionRequired || isCancelRequest || isChangeSchedule
      ? state.serviceRequest.determineIncidentDetails.RepairRequest
        .RepairRequestType
      : state?.serviceRequest?.determineIncidentDetails?.RepairRequest?.RepairRequestType ?? state.journeyMessages.repairOption
  );
  const isWalkIn = repairOption === "WALKIN";

  const walkInData = useSelector((state) => state.claim.walkIn);

  const { selectedAsset } = useSelector(
    (state) => state.claim.deviceConfirmation
  );

  const deviceName =
    isNoActionRequired || isCancelRequest || isChangeSchedule
      ? `${determinDetails.ClaimedAsset.Make} ${determinDetails.ClaimedAsset.Model}`
      : `${selectedAsset.Make.Name} ${selectedAsset.Model.Name}`;

  let paymentMethod = useSelector((state) => state.claim?.payment?.paymentMethod?.method);
  if (isNoActionRequired || isCancelRequest || isChangeSchedule)
    paymentMethod = determinDetails.ChargeOrder?.PaymentMethodType || paymentMethod;

  const pickupAddress = !isWalkIn ? isNoActionRequired || isCancelRequest || isChangeSchedule
    ? determinDetails.ShippingOrderOutbound.Address
    : scheduleData.selectedPickupAddress : "";

  const pickupDate = !isWalkIn ? isNoActionRequired || isCancelRequest || isChangeSchedule
    ? determinDetails.ShippingOrderOutbound.ExpectedDeliveryDate
    : scheduleData.selectedPickupDate : "";

  const pickupSlot = !isWalkIn ? isNoActionRequired || isCancelRequest || isChangeSchedule
    ? determinePickupSlot
    : scheduleData.selectedPickupSlot : "";

  const returnAddress = !isWalkIn ? isNoActionRequired || isCancelRequest || isChangeSchedule
    ? determinDetails?.ShippingOrderInbound?.Address
    : scheduleData.selectedDeliveryAddress : "";

  const returnDate = !isWalkIn ? isNoActionRequired || isCancelRequest || isChangeSchedule
    ? determinDetails?.ShippingOrderInbound?.ExpectedDeliveryDate
    : scheduleData.selectedDeliveryDate : "";

  const returnSlot = !isWalkIn ? isNoActionRequired || isCancelRequest || isChangeSchedule
    ? determineDeliverySlot
    : scheduleData.selectedDeliverySlot : "";

  const customerCaseNumber = useSelector((state) =>
    isNoActionRequired || isCancelRequest || isChangeSchedule
      ? state.serviceRequest.determineIncidentDetails.CustomerCaseNumber
      : state.serviceRequest.serviceRequestDetails.CustomerCaseNumber
  );
  const srFee = useSelector((state) =>
    isNoActionRequired || isCancelRequest || isChangeSchedule
      ? state.serviceRequest.determineIncidentDetails.ServiceFee
      : state.claim.payment.serviceFee.TotalAmount || state.claim.payment.serviceFee[perilType]?.TotalAmount
  );

  const { WarrantyType } = useSelector((state) =>
    isNoActionRequired || isCancelRequest || isChangeSchedule
      ? state.serviceRequest.determineIncidentDetails.ServiceRequest
      : incidentPathType === INCIDENT_PATH_TYPE.RESUME_SERVICE_REQUEST &&
        serviceRequestDetails.Actions !== "CaptureIncidentDetails"
        ? state.serviceRequest.serviceRequestDetails
        : state.claim.termsAndConditions.processIncident.CreateIncidentResults
          .CustomerCase.ServiceRequest
  );
  const isInWarranty = enquiryOption === EnumEnquiryModules.InWarrantyRequest || isEmpty(WarrantyType) ? srFee === 0 : WarrantyType === "IW";


  const repairCenterLocation = isWalkIn ?
    isNoActionRequired || isCancelRequest || isChangeSchedule
      ? `${determinDetails.RepairRequest.ServiceProviderName
      }, ${getAddressText(determinDetails.ShippingAddress)}`
      : walkInData && walkInData.selectedStore
        ? `<strong>${walkInData.selectedStore.SERVICE_PROVIDER_NAME}</strong><br/> 
      ${walkInData.selectedStore.ADDRESS_LINE_1 || ""} ${walkInData.selectedStore.ADDRESS_LINE_2 || ""} ${walkInData.selectedStore.ADDRESS_LINE_3 || ""} ${walkInData.selectedStore.CITY_NAME} ${walkInData.selectedStore.POSTAL_CODE} ${walkInData.selectedStore.STATE_PROVINCE_NAME || ""}`
        : "" : "";

  // const appointmentDateTime = isWalkIn
  //   ? isNoActionRequired || isCancelRequest || isChangeSchedule
  //     ? `${getFormmatedDate(
  //       determinDetails.RepairRequest.AppointmentDate,
  //       "d MMM yyyy"
  //     )}, ${getFormatedTime(
  //       determinDetails.RepairRequest.AppointmentDate,
  //       true,
  //       "hh mm A"
  //     )}`
  //     : walkInData && walkInData.selectedAppointmentDate
  //       ? `${getFormmatedDate(
  //         walkInData.selectedAppointmentDate,
  //         "d MMM yyyy"
  //       )} ${walkInData.selectedAppointmentSlot}`
  //       : ""
  //   : "";

  const appointmentDateTime = isWalkIn
    ? isNoActionRequired || isCancelRequest || isChangeSchedule
      ? `${moment(determinDetails.RepairRequest.AppointmentDate).tz("Asia/Bangkok").format("d MMM yyyy")},
       ${moment(determinDetails.RepairRequest.AppointmentDate).tz("Asia/Bangkok").format("HH:mm")}`
      : walkInData && walkInData.selectedAppointmentDate
        ? `${getFormmatedDate(
          walkInData.selectedAppointmentDate,
          "d MMM yyyy"
        )} ${walkInData.selectedAppointmentSlot}`
        : ""
    : "";

  const tableHeaders = [
    {
      key: "srNo",
      text: t("ConfirmRepairTableDetails.LableforRequestNumber"),
      hide: false,
    },
    {
      key: "device",
      text: t("ConfirmRepairTableDetails.LableforDevice"),
      hide: isSingtel ? true : false,
    },
    {
      key: "requestType",
      text: t("ConfirmRepairTableDetails.LabelforRequestType"),
      hide: isSingtel
        ? true
        : false,
    },
    {
      key: "pickupAddress",
      text: t("ConfirmRepairTableDetails.LabelforPickup"),
      hide: isSingtel
        ? false
        : isWalkIn
          ? true
          : false,
    },
    {
      key: "returnAddress",
      text: t("ConfirmRepairTableDetails.LabelforReturn"),
      hide:
        isWalkIn
          ? true
          : false,
    },
    {
      key: "pickupDateTime",
      text: t("ConfirmRepairTableDetails.LabelforPickupDateTime"),
      hide: isSingtel
        ? false
        : isWalkIn
          ? true
          : false,
    },
    {
      key: "returnDateTime",
      text: t("ConfirmRepairTableDetails.LabelforReturnDateTime"),
      hide:
        isWalkIn
          ? true
          : false,
    },
    {
      key: "repairCenterLocation",
      text: t("ConfirmRepairTableDetails.LabelforRepairCenterLocation"),
      hide: isSingtel
        ? true
        : isWalkIn
          ? false
          : true,
      isInnerHTML: true
    },
    {
      key: "appointmentDateTime",
      text: t("ConfirmRepairTableDetails.LabelforAppointmentDateTime"),
      hide: isSingtel
        ? true
        : isWalkIn
          ? false
          : true,
    },
    {
      key: "srFee",
      text: t("ConfirmRepairTableDetails.LabelforRepairFee"),
      text2: t("ConfirmRepairTableDetails.LabelforRepairFeeInclusion"),
      hide: isEmpty(`${srFee}`) ? true : false,
      isInnerHTML: true
    },
    {
      key: "paidWith",
      text: t("ConfirmRepairTableDetails.LabelforPaidWith"),
      hide: isSingtel || isInWarranty ? true : false,
    }
  ];

  const tableData = [
    {
      srNo: customerCaseNumber,
      pickupAddress: pickupAddress && Object.keys(pickupAddress).length > 0 ? getAddressText(pickupAddress) : "",
      returnAddress: returnAddress && Object.keys(returnAddress).length > 0 ? getAddressText(returnAddress) : "",
      pickupDateTime: pickupDate
        ? `${getFormmatedDate(pickupDate, "d MMM yyyy")} ${pickupSlot}`
        : "",
      returnDateTime: returnDate
        ? `${getFormmatedDate(returnDate, "d MMM yyyy")} ${returnSlot}`
        : "",
      srFee: `<strong>${t("ConfirmRepairTableDetails.LabelForFee")}<br/>${currency} ${srFee}</strong>`,
      device: deviceName,
      requestType: repairOption === "PUR" ? t("Constants.PROGRESS_DATA.PUR.title") : t("Constants.PROGRESS_DATA.WALK_IN.title"),
      repairCenterLocation: repairCenterLocation,
      appointmentDateTime: appointmentDateTime,
      paidWith: !isEmpty(paymentMethod) && paymentMethod === "CRE" ? t("CCButton") : t("CODButton"),
    },
  ];

  const handleSubmit = () => {
    setDisabled(true);
    dispatch(confirmRequestDetailsSubmission());
  };

  const handleCancelClick = () => {
    setDisabled(true);
    dispatch(cancelRepairDetailsConfirmation());
  };

  const newRequestTypes = [
    EnumEnquiryModules.NewRequest,
    EnumEnquiryModules.NewRequestBatteryReplacement,
    EnumEnquiryModules.NewRequestScreenAndBattery,
  ];

  const isNewRequest = newRequestTypes.includes(enquiryOption);
  let title = enquiryOption;
  let tableTitle = enquiryOption;
  let buttonTitle = enquiryOption;

  if (isNewRequest) {
    title = EnumEnquiryModules.NewRequest;
    tableTitle = EnumEnquiryModules.NewRequest;
    buttonTitle = EnumEnquiryModules.NewRequest;
  }

  return (
    <BaseCard>
      <ConfirmIcon className="app-BaseIcon-center" />
      <BaseLabel>
        <Trans i18nKey={`ConfirmRepairDetails.${title}.Title`}>
          <TextRegular>
            Please check and confirm your
            <br />
            request details below.
          </TextRegular>
        </Trans>
      </BaseLabel>
      <BaseTable
        title={t(`ConfirmRepairDetails.${tableTitle}.TableTitle`)}
        verticle
      >
        {tableHeaders.map((header) => {
          return (
            !header.hide && (
              <BaseTR key={header.key}>
                <BaseTH>{header.text}<br/>{header?.text2}</BaseTH>
                {tableData.map((data, i) => (
                  header.isInnerHTML
                    ?
                    <>
                      <td
                        key={`${header.key}_${i}`}
                        className="app-BaseTable__td"
                        dangerouslySetInnerHTML={{
                          __html: sanitize(data[header.key])
                        }}
                      >
                      </td>
                    </>
                    :
                    <><BaseTD key={`${header.key}_${i}`}>{data[header.key]}</BaseTD></>
                ))}
              </BaseTR>
            )
          );
        })}
      </BaseTable>
      <BaseButtonGroup>
        {[...newRequestTypes, EnumEnquiryModules.ResumeRequest].includes(
          enquiryOption
        ) && (
            <BaseButton
              className="w-fit px-4 sm:px-16 bg-white border-app-primary text-app-primary"
              text={t("CancelButton")}
              onClick={handleCancelClick}
              disabled={disabled}
            />
          )}
        <BaseButton
          className="w-fit px-4 sm:px-6"
          text={t(`ConfirmRepairDetails.${buttonTitle}.Button`)}
          onClick={handleSubmit}
          disabled={disabled}
          clicked={disabled}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ConfirmRepairDetails;
