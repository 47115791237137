import initializeApi from "./initializeApi";
import interactionApi from "./interactionApi";
import businessHoursApi from "./businessHoursApi";
import { addJourneyMessages } from "./../helpers/addJourneyMessages";
import createMessage from "./../helpers/createMessage";
import createVisitor from "../modules/Appsync/createVisitor";

export const initProcessFlow = ({language = "th-TH"}) => async (dispatch, getStore) => {
  // const session = getStore().session;
  // const {CacheId, UserName = "User"} = session && session.sessionData && session.sessionData.Configurations;
  //call initialize api and interaction api
  let initializeResponse = await dispatch(initializeApi(language));
  const { CacheId, UserName = "User" } = initializeResponse.Configurations;
  await dispatch(interactionApi(CacheId, UserName, language));
  await dispatch(businessHoursApi());
  await dispatch(createVisitor(language));
  //configure appsync and twilio and then show journey message
  const list = getStore().journeyMessages.messageList;
  // const shouldWelcomeComponentLoad =
  //   list.findIndex((m) => m.type === "WELCOME_TO_SERVICE") === -1;
  const shouldMdnComponentLoad =
    list.findIndex(
      (m) => m.type === "VALIDATION" && m.data.showComponent === "mdn"
    ) === -1;

  // if (shouldWelcomeComponentLoad)
  //   dispatch(
  //     addJourneyMessages([createMessage("WELCOME_TO_SERVICE", "system")])
  //   );

  if (shouldMdnComponentLoad)
    dispatch(
      addJourneyMessages([
        createMessage("VALIDATION", "system", { showComponent: "mdn" }),
        // createMessage("IMPORTANT_NOTE", "system", {}),
      ])
    );
};
