import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import PropTypes from "prop-types";
import CryptoJS from "crypto-js";

const generateGMAK = () => {
  let gmak = process.env.REACT_APP_GMAK;
  let ccId = process.env.REACT_APP_CLIENT_ID;
  let bytes = CryptoJS.AES.decrypt(gmak, ccId);
  let original = bytes.toString(CryptoJS.enc.Utf8);

  return original;
}

const containerStyle = {
  position: "relative",
  width: "100%",
  height: "118px",
};

class MapContainer extends Component {
  render() {
    return (
      <Map
        google={this.props.google}
        zoom={14}
        containerStyle={containerStyle}
        initialCenter={{
          lat: this.props.latitude,
          lng: this.props.longitude,
        }}
      >
        <Marker onClick={this.onMarkerClick} name={"Current location"} />
      </Map>
    );
  }
}

MapContainer.defaultProps = {
  latitude: 0,
  longitude: 0,
};

MapContainer.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
};

export default GoogleApiWrapper({
  apiKey: `${generateGMAK()}`,
})(MapContainer);
