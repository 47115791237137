import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseInformationBox from "../../../base/BaseInformationBox";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import { TextMedium } from "../../../base/BaseText";

import { proceedForDeviceConfirmation } from "../state/operators/deviceConfirmationOperators";
import { ReactComponent as RequirementsIcon } from "../../../images/true/icon-requirements.svg";

const PrepareRequirements = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const submitRequirementPrepared = () => {
    dispatch(proceedForDeviceConfirmation(t("PrepareRequirements.ProceedBtn")));
    setDisabled(true);
  };

  return (
    <div className="app-PrepareRequirements">
      <BaseCard className="space-y-4">
        {/* <BaseIcon icon={RequirementsIcon} name="Prepare Requirements" center /> */}
        <RequirementsIcon className="app-BaseIcon-center" />
        <BaseLabel className="font-bold">
          <TextMedium>{t("PrepareRequirements.Title")}</TextMedium>
        </BaseLabel>
        <BaseLabel className="text-20px text-left">
          <Trans i18nKey="PrepareRequirements.Labels.1" />
        </BaseLabel>
        <BaseLabel className="text-20px text-left">
          <Trans i18nKey="PrepareRequirements.Labels.2" />
        </BaseLabel>
        {/* <BaseInformationBox> */}
          {/*<ul className="list-disc list-outside text-20px text-app-nova inline-block leading-none pl-10">
            <li>
              <Trans i18nKey="PrepareRequirements.Information.1" />
            </li>
            <li>
              <Trans i18nKey="PrepareRequirements.Information.2" />
            </li>
  </ul>*/}
          {/* <span className="text-20px text-app-nova inline-block leading-none ">
          <Trans i18nKey="PrepareRequirements.Information.3" />
          </span> */}
        {/* </BaseInformationBox> */}
        <BaseButtonGroup>
          <BaseButton
            className="mt-0"
            text={t("PrepareRequirements.ProceedBtn")}
            onClick={submitRequirementPrepared}
            disabled={disabled}
            clicked={disabled}
            autoFocus
          />
        </BaseButtonGroup>
      </BaseCard>
    </div>
  );
};

export default PrepareRequirements;
