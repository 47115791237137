import React from "react";
import { Link } from "react-router-dom";
import BaseLink from "../../base/BaseLink/BaseLink";
import { TextRegular } from "../../base/BaseText";
import { FAQ_LINK, PRIVACY_POLICY_LINK, TERMS_AND_CONDITIONS_LINK, TERMS_OF_USE_LINK } from "../../helpers/constants";
import { Trans, useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const handlePrivacyNoticeClick = () => {
    window.open(PRIVACY_POLICY_LINK, "_blank");
  };

  const handleFAQClick = () => {
    window.open(FAQ_LINK, "_blank");
  };

  const handleTnCclick = () => {
    window.open(TERMS_AND_CONDITIONS_LINK, "_blank");
  }

  const handleTermsOfUseClick = () => {
    window.open(TERMS_OF_USE_LINK, "_blank");
  }

  return (
    <footer className="text-2xl font-TMedium pt-8" style={{backgroundColor: "#211830"}}>
      <div className="w-full sm:w-4/5 p-2 px-6 sm:px-0 sm:ml-auto sm:mr-auto text-white sm:py-8 sm:pt-10">
        <BaseLink
            onClick={handlePrivacyNoticeClick}
              className="text-white text-20px"
            > 
          <TextRegular>{t("HomePage.Footer.PrivacyNotice")}</TextRegular>
        </BaseLink>
        <p className="text-srgray-senary font-TLight text-20px leading-none">
        {t("HomePage.Footer.PrivacyNoticeContent")}
          <BaseLink
              onClick={handlePrivacyNoticeClick}
              className="text-white text-20px"
            >
            {t("HomePage.Footer.PrivacyNoticeLinkText")}
          </BaseLink>
        </p>

        <div className="inline-block mt-8 text-white text-20px">
          <TextRegular>{t("HomePage.Footer.LegalNotice")}</TextRegular>
        </div>
        <ul className="inline sm:inline-block sm:mt-6 leading-4r sm:mx-10">
          <li className="inline-block ml-10 sm:ml-0 mr-10">
            <BaseLink
              onClick={handleTnCclick}
              className=" text-white text-20px"
            >
              {t("HomePage.Footer.Link1")}
            </BaseLink>
          </li>
          <li className="inline-block text-20px mr-10">
            <BaseLink
              onClick={handleTermsOfUseClick}
              className=" text-white text-20px"
            >
            {t("HomePage.Footer.Link2")}
            </BaseLink>
          </li>
          <li className="inline-block mr-10">
            <BaseLink
              onClick={handleFAQClick}
              className=" text-white text-20px"
            >
              {t("HomePage.Footer.Link3")}
            </BaseLink>
          </li>
        </ul>
        <div className="pt-8">
          <div className="text-srgray-senary font-TLight mb-12 text-20px leading-none">
            <p className="pb-5">
              {t("PaymentDisclaimer")}
            </p>
            <p>
              {t("HomePage.Footer.CopyrightContent")}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
