import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import i18n from "../../i18n";
import { setLocale } from "../../reducers/journeyMessagesReducer";
import trueLogo from "../../images/true/true-logo.svg";
import classNames from 'classnames';
import Footer from './Footer';
import { TextLight, TextMedium } from '../../base/BaseText';
import { createClient } from 'contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { useTranslation } from "react-i18next";
import Loader from '../../components/Loader/Loader';

const richTextOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <span className="text-rblack lg:text-24px text-22px">{children}</span>,
      [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3 className="lg:text-36px text-30px">{children}</h3>,
      [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
      [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
      [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
      [BLOCKS.UL_LIST]: (node, children) => <ul className="list-disc" style={{ listStyle: "none"}}>{children}</ul>,
      [BLOCKS.OL_LIST]: (node, children) => <ol className="list-decimal list-inside lg:text-24px text-22px">{children}</ol>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li className="">{children}</li>,
      [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
      [BLOCKS.HR]: () => <hr />,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { title, description, file } = node.data.target.fields;
        const url = file.url;
        const type = file.contentType;
        const mimeType = type ? type.split('/')[0] : 'image';
  
        if (mimeType === 'image') {
          const { width, height } = file.details.image;
          return (
            <figure>
              <img src={url} alt={description} width={width} height={height} />
              {/* {title && <figcaption>{title}</figcaption>} */}
            </figure>
          );
        }
  
        if (mimeType === 'video') {
          return (
            <video src={url} controls>
              {description && <track kind="subtitles" label="English" srcLang="en" src={description} />}
            </video>
          );
        }
  
        return null;
      },
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank" rel="noopener noreferrer" className="underline text-app-primary">
          {children}
        </a>
      ),
    },
    renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };

const Faq = () => {
    const [faqData, setFaqData] = useState([])
    const [selectedAccordionQuestions, setSelectedAccordionQuestions] = useState([]);
    const [searchKey, setSearchKey] = useState(null);
    const [displayedData, setDisplayedData] = useState([]);
    const [isLoading, setIsloading] = useState(true)
    const [matchesSmallDevice, setMatchesSmallDevice] = useState(window.matchMedia("(max-width: 1440px)").matches);

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const [language, setLanguage] = useState(sessionStorage.getItem("language") || "th-TH");
    const showFlowWindow = useSelector(
        (state) => state.journeyMessages.showFlowWindow
      );



    const onLanguageToggle = (e) => {
        e.preventDefault();
        let chosenLanguage = e.target.name;
        if (chosenLanguage !== language) {
          setLanguage(chosenLanguage);
          i18n.changeLanguage(chosenLanguage);
          sessionStorage.setItem("language", chosenLanguage);
          dispatch({
            type: setLocale.toString(),
            payload: chosenLanguage,
          });
        }
      }

    const handleInputSearch = (event) => {
        const { value } = event.target;
        console.log(value);
        setSearchKey(value);
        if (!value) {
            setDisplayedData(faqData);
        } 
    };

    useEffect(() => {
        setDisplayedData(faqData.filter((faq) => faq.faqs.some(q => q.fields.question.toLowerCase().includes(searchKey.toLowerCase()))).map(m => ({ ...m, faqs: m.faqs.filter(f => f.fields.question.toLowerCase()?.includes(searchKey.toLowerCase()))})))
    }, [searchKey])

    useEffect(() => {
        setIsloading(true)
        const client = createClient({
            space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
            accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
        });
        // client.getEntry("5M9VUGIWRMSDDFJaWywV1K").then(response => console.log("r", response))
        client.getEntries({ content_type: 'categoryFaQs', 'fields.carrier': "True SUR FAQ", include: 7, locale: language, }).then((response) => {
            console.log(response);
            setFaqData(response.items.map(i => {
                return {
                    category: i.fields.category,
                    // faqs: i.fields.faQs.map(ifaq => ({ question: ifaq.fields.question, answer: ifaq.fields.answer }))
                    faqs: i.fields.faQs
                }
            }));
            setDisplayedData(response.items.map(i => {
                return {
                    category: i.fields.category,
                    // faqs: i.fields.faQs.map(ifaq => ({ question: ifaq.fields.question, answer: ifaq.fields.answer }))
                    faqs: i.fields.faQs
                }
            }))
            // setFaqCategories(response.items.map(i => i.fields.category));
            setIsloading(false)
        })

        window.matchMedia("(max-width: 1440px)").addEventListener("change", (e) => {
            console.log(e, e.matches)
            setMatchesSmallDevice(e.matches)
        })
    }, [language])

    const FaqColumns = ({ faqs, c }) => {
        const length = faqs?.length;
        const mid = Math.ceil(length / 2);

        if(matchesSmallDevice) {
            return (
                <div className='flex flex-col items-start w-full' >
                    {faqs.map(f => <FaqAccordion question={f.fields.question} answer={f.fields.answer} key={f.fields.question}></FaqAccordion>)}
                </div>
            )
        }        

        return (    
            <Fragment>
                <div className='flex flex-col items-start' style={{ width: "540px"}}>
                    {faqs.slice(0, mid).map(f => <FaqAccordion question={f.fields.question} answer={f.fields.answer} key={f.fields.question}></FaqAccordion>)}
                </div>
                <div className='flex flex-col items-start' style={{ width: "540px"}}>
                    {faqs.slice(mid).map(f => <FaqAccordion question={f.fields.question} answer={f.fields.answer} key={f.fields.question}></FaqAccordion>)}
                </div>
            </Fragment>
        )
    }

    const FaqAccordion = ({ question, answer }) => {
        const togglePanel = () => {
            console.log(selectedAccordionQuestions)
            if (selectedAccordionQuestions.includes(question)) {
                
                setSelectedAccordionQuestions(selectedAccordionQuestions.filter((item) => item !== question));
            } else {
                setSelectedAccordionQuestions([...selectedAccordionQuestions, question]);
            }
        };

        return (
            <div className='flex flex-col items-start border-y border-x-0 border-solid p-10px gap-10px border-t border-b border-r-0 border-l-0' style={{ borderColor: "#CED4DA", width: "inherit"}}>
                <div className='flex flex-row items-center gap-10px cursor-pointer' onClick={()=> togglePanel()}>
                    <div className='flex flex-row items-center flex-grow justify-center py-5px w-24px h-34px cursor-pointer px-6px'>
                        {selectedAccordionQuestions.includes(question) ? <svg width="12" height="1" viewBox="0 0 12 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.5 1H0.5C0.22 1 0 0.78 0 0.5C0 0.22 0.22 0 0.5 0H11.5C11.78 0 12 0.22 12 0.5C12 0.78 11.78 1 11.5 1Z" fill="#ED1C24"/>
                        </svg> : 
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.5 5.5H6.5V0.5C6.5 0.22 6.28 0 6 0C5.72 0 5.5 0.22 5.5 0.5V5.5H0.5C0.22 5.5 0 5.72 0 6C0 6.28 0.22 6.5 0.5 6.5H5.5V11.5C5.5 11.78 5.72 12 6 12C6.28 12 6.5 11.78 6.5 11.5V6.5H11.5C11.78 6.5 12 6.28 12 6C12 5.72 11.78 5.5 11.5 5.5Z" fill="#ED1C24"/>
                        </svg>}

                        

                    </div>
                    <h4 className='font-TBold lg:text-30px text-24px leading-26px lg:leading-34px'>{question}</h4>
                </div>
                {
                    selectedAccordionQuestions.includes(question) && (
                        <div className='leading-26px text-text-rblack py-10px pr-0 pl-34px'>
                            {/* <p className='font-TMedium' style={{ fontSize: "24px", fontWeight: "400"}}><TextMedium className="" style={{ listStyle: "none"}}>{documentToReactComponents(answer, richTextOptions)}</TextMedium></p> */}
                            <TextMedium className="" style={{ listStyle: "none"}}>{documentToReactComponents(answer, richTextOptions)}</TextMedium>
                        </div>
                    )
                }
                
            </div>
        )
    }
    
  return (
    isLoading ? (<Loader/>) :(
    <Fragment>
        <div className="truerepair__home">
            <header className="box-border flex bg-white p-2">
                <div className="w-full sm:w-4/5 sm:mx-auto">
                    <div className="flex justify-center">
                        <a href="/" className="py-0 inline-block my-5 mr-auto"><img  src={trueLogo} alt="True Logo" style={{height: "50px"}}/></a>
                        <div className="my-auto pr-4">
                            <button onClick={onLanguageToggle} href="#" name="th-TH" className={`inline-block text-24px leading-none align-middle mr-4 ${language === "th-TH" ? "text-app-primary font-bold" : "text-black"}`}>ไทย</button>
                            <span className="text-24px leading-none align-middle">|</span>
                            <button onClick={onLanguageToggle} href="#" name="en-US" className={`inline-block text-24px leading-none align-middle ml-4 ${language === "en-US" ? "text-app-primary font-bold" : "text-black"}`}>English</button>
                        </div>
                    </div>
                </div>
            </header>
            <div
                className={classNames({
                    "hidden lg:block xl:block": showFlowWindow,
            })}
            >
                {/* <Banner title={bannerData.title} description={bannerData.description}/>
                <SubBanner title={subBannerData.title} description={subBannerData.description}/>
                <Content data={contentData}/> */}
                <div className='flex flex-col justify-evenly items-center xl:px-40r md:px-20r px-8 py-16 gap-30px lg:gap-50px mb-16'>
                    <div className="flex-col flex gap-20px items-center" id="faq-title">
                        <h2 className="px-8 lg:px-0 text-rblack lg:text-72px text-30px text-center lg:leading-86px leading-34px text-text-rblack" style={{color: "#231F20"}}>
                            <TextMedium>{t("FAQPage.Banner.Heading")}</TextMedium>
                        </h2>
                        <h3 className="lg:text-40px text-22px" style={{ color: "#353635"}}>{t("FAQPage.Banner.Subheading")}</h3>
                    </div>
                    <div className="flex flex-col flex-start p-0 w-full lg:w-760px h-40px lg:h-64px">
                        <div className="border rounded-lg flex flex-row flex-start gap-2.5 items-center p-3.5 h-40px lg:h-64px lg:p-15px pt-8px pb-8px pr-10px pl-10px" id="faq-field">
                            <div className="grow" style={{ borderColor: '#CED4DA', flexGrow: "1"}}>
                                {/* <label for="faq-search" ><TextMedium>Search for your question or keyword</TextMedium></label> */}
                                    <input id="faq-search" className="w-full lg:text-30px text-22px h-22px lg:h-34px leading-22px lg:leading-34px font-normal not-italic" type="text" placeholder={t("FAQPage.Search")} style={{fontFamily: 'TMedium', color: "#737380" }}
                                        onChange={e => handleInputSearch(e)}/>
                            </div>
                            <div className="w-8 h-8 relative cursor-pointer">
                                <svg className="h-6 w-6 absolute" style={{left: "12.5%",
                                    right: "15.07%",
                                    top: "12.5%",
                                    bottom: "12.5%"}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.8133 21.8667L17.1067 15.92C18.5733 14.1733 19.3867 11.9733 19.3867 9.69333C19.3733 4.34667 15.0267 0 9.69333 0C4.36 0 0 4.34667 0 9.69333C0 15.04 4.34667 19.3867 9.69333 19.3867C11.6933 19.3867 13.6133 18.7867 15.24 17.6267L21 23.6133C21.24 23.8667 21.56 24 21.9067 24C22.24 24 22.5467 23.88 22.7867 23.6533C23.2933 23.1733 23.3067 22.3733 22.8267 21.8667H22.8133ZM9.69333 2.53333C13.64 2.53333 16.8533 5.74667 16.8533 9.69333C16.8533 13.64 13.64 16.8533 9.69333 16.8533C5.74667 16.8533 2.53333 13.64 2.53333 9.69333C2.53333 5.74667 5.74667 2.53333 9.69333 2.53333Z" fill="#ED1C24"/>
                                </svg>

                            </div>
                        </div>
                        
                    </div>
                    <div id="faq" style={{ gap: "50px"}} className='flex flex-col items-start'>
                        {displayedData.map((faqc) => {
                            return (
                                <div className='flex flex-col items-center gap-20px' key={faqc.category}>
                                    <h4 className='font-TLight lg:text-40px text-36px leading-40px lg:leading-48px text-center'>{faqc.category}</h4>
                                    <div className='flex flex-row gap-100px'>
                                        <FaqColumns faqs={faqc.faqs} c={faqc.category}></FaqColumns>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    </Fragment>)
  )
}

export default Faq



