import React, { useState } from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { TextMedium, TextRegular } from "../../../base/BaseText";

import ContactConfig from "./contactConfirmationConfig";
import { submitConfirmationOfContactDetails } from "../state/operators/contactsOperator";

const ConfirmContactDetails = ({ code }) => {
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ContactIcon = ContactConfig[code].icon;

  const data = useSelector((state) => state.validation.verification);

  const handleSubmitConfirmation = (status) => {
    setDisabled(true);
    setStatus(status);
    const isMobile = code === "ConfirmMobileNumber";
    dispatch(submitConfirmationOfContactDetails(status, isMobile));
  };

  return (
    <BaseCard>
      {/* <BaseIcon icon={ContactConfig[code].icon} name="Contact" center /> */}
      {ContactIcon}
      <BaseLabel>
        <Trans i18nKey={`Contacts.${code}.Title`}>
          <TextRegular>
            {ContactConfig[code].title}
          </TextRegular>
        </Trans>
      </BaseLabel>
      <BaseLabel className="font-bold leading-none">
        <TextMedium>
          {code === "ConfirmMobileNumber"
            ? `${data.PhoneNumber}`
            : data.EmailAddress}
            {/*`${data.enrolledAsset.CountryCallingCode} ${data.PhoneNumber}`*/}
        </TextMedium>
      </BaseLabel>
      <BaseButtonGroup>
        <BaseButton
          className="bg-white border-app-primary text-app-primary"
          text={t("YesButton")}
          disabled={disabled}
          clicked={status === "Yes"}
          onClick={() => handleSubmitConfirmation("Yes")}
        />
        <BaseButton
          className="bg-white border-app-primary text-app-primary"
          text={t("NoButton")}
          disabled={disabled}
          clicked={status === "No"}
          onClick={() => handleSubmitConfirmation("No")}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

ConfirmContactDetails.defaultProps = {
  code: "ConfirmMobileNumber",
};

ConfirmContactDetails.propTypes = {
  code: PropTypes.string.isRequired,
};

export default ConfirmContactDetails;
