import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButton from "../../../base/BaseButton/BaseButton";

import { checkIsExistingCustomer } from "../state/operators/generalEnquiryOperator";
import { ReactComponent as CheckIcon } from "../../../images/icon-check.svg";


const IsExistingCustomer = () => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [selection, setSelection] = useState(null);
  const { t } = useTranslation();

  const handleSubmit = (answer) => {
    setSelection(answer);
    dispatch(checkIsExistingCustomer(answer));
    setDisabled(true);
  };

  const yesBtnClasses = classNames(
    "relative w-full bg-white border m-1",
    { "text-white bg-app-secondary ": selection === "Yes" },
    { "pointer-events-none": disabled }
  )

  const noBtnClasses = classNames(
    "relative w-full bg-white border m-1",
    { "text-white bg-app-secondary": selection === "No" },
    { "pointer-events-none": disabled }
  )

  const adornment = (show) => show && <CheckIcon className="absolute top-0 left-0 bg-app-primary h-full w-16 p-3" />
  return (
    <BaseCard className="border-0">
      <BaseLabel className="text-left">{t("GeneralEnquiry.IsExistingCustomer.Title")}</BaseLabel>
      <BaseButton
        className={yesBtnClasses}
        text={t("YesButton")}
        onClick={() => handleSubmit("Yes")}
        startAdornment={adornment(selection === 'Yes')}
      />
      <BaseButton
        className={noBtnClasses}
        text={t("NoButton")}
        onClick={() => handleSubmit("No")}
        startAdornment={adornment(selection === 'No')}
      />
    </BaseCard>
  );
};

export default IsExistingCustomer;
