import {
  addJourneyMessages,
  sleep,
} from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import {
  setPickupAddress,
  setDeliveryAddress,
  setPickupDateTime,
  setDeliveryDateTime,
  setPickupOptions,
  setRegisteredAddress,
  setCorrectedPickupAddress,
  setCorrectedDeliveryAddress,
  setFailedPickupAddressAttempt,
  setFailedDeliveryAddressAttempt,
} from "../reducers/scheduleReducer";
import {
  API_PROGRESS,
  COMPONENTS,
  PROGRESS_DATA,
  ACTIVITY,
} from "../../../../helpers/constants";
import {
  updateApiProgress,
  clearApiProgress,
  updateFlowProgress,
  serviceUnavailable,
  endFlow,
} from "../../../../actions/flowWindow";
import {
  getPickupDates,
  getDeliveryDates,
  covertToUTCDate,
} from "../../../../helpers/dateUtils";
import { EnumEnquiryModules } from "../../../../components/EnquiryOptions/Constants";
import updateVisitor from "../../../Appsync/updateVisitor";
import { isEmpty } from "../../../../helpers/formatUtils";
import {
  standardizeAddressApi,
  serviceOrderApi,
  getShippingAddressApi,
  updateShippingAddressApi,
  walkinUpdateShippingAddressApi,
  createRepairRequestApi,
  updatePURScheduleApi
} from "../actions/addressApi";
import { updateContactDetailsData } from "./contactsOperator";
import { confirmToCancelRequest } from "./cancelRequestOperator";
import i18n from 'i18next';
import { getFormmatedDate, isToday } from "../../../../helpers/dateUtils";
import { getAppointments } from "../actions/walkInApi";
import { saveAppointmentDateTime } from "./RepairOptionsOperator";
import moment from "moment";

export const confirmationOfDateAndTime = (status, isPickup) => async (
  dispatch,
  getStore
) => {
  let showComponent;
  showComponent =
    status === "Confirm"
      ? isPickup
        ? "confirmDeliveryAddress"
        : "confirmDetails"
      : status === "Change"
        ? isPickup
          ? "changePickupDateTime"
          : "changeDeliveryDateTime"
        : "";

  const initial = getStore().journeyMessages.flowProgress.percentage;
  if (showComponent === "confirmDeliveryAddress") {
    const selectedPickupDate = getStore().claim.schedule.selectedPickupDate
    const {
      StateProvinceCode: pickupProvinceCode,
    } = getStore().claim.schedule.selectedDeliveryAddress;
    const {
      StateProvinceCode: deliveryProvinceCode,
    } = getStore().claim.schedule.selectedDeliveryAddress;
    const isBangkokToBangkok = pickupProvinceCode === deliveryProvinceCode
    const deliveryOptions = getDeliveryDates(selectedPickupDate, isBangkokToBangkok ? pickupProvinceCode : "Not Bangkok");
    const deliverySlot = deliveryOptions[0].slots[0];

    await dispatch(saveDeliveryDateTime(deliveryOptions[0].date, deliverySlot));
    dispatch(
      updateFlowProgress(
        i18n.t("Constants.PROGRESS_DATA.CONFIRM_RETURN_DETAILS.title"),
        i18n.t("Constants.PROGRESS_DATA.CONFIRM_RETURN_DETAILS.count"),
        initial
      )
    );
  } 
  // else if(showComponent === "changeDeliveryDateTime"){


  // }
  else if (showComponent === "confirmDetails") {
    dispatch(
      updateFlowProgress(
        i18n.t("Constants.PROGRESS_DATA.CONFIRM_SCHEDULE.title"),
        i18n.t("Constants.PROGRESS_DATA.CONFIRM_SCHEDULE.count"),
        initial
      )
    );
  }
  dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", i18n.t(`${status}Button`)),
      createMessage("PICKUP_AND_DELIVERY", "system", { showComponent }),
    ])
  );
};

export const confirmationOfAddress = (status, isPickup) => async (
  dispatch,
  getStore
) => {
  let showComponent = "";
  const apiStartMsg = isPickup
    ? i18n.t("Constants.API_PROGRESS.CONFIRM_PICKUP_ADDRESS")
    : i18n.t("Constants.API_PROGRESS.CONFIRM_RETURN_ADDRESS");
  const apiEndMsg = isPickup
    ? i18n.t("Constants.API_PROGRESS.CONFIRM_PICKUP_ADDRESS_SUCCESS")
    : i18n.t("Constants.API_PROGRESS.CONFIRM_RETURN_ADDRESS_SUCCESS");
  dispatch(addJourneyMessages([createMessage("TEXT", "user", i18n.t(`${status}Button`))]));

  if (status === "Confirm") {
    await dispatch(
      updateApiProgress(apiStartMsg, 40, COMPONENTS.CONFIRM_ADDRESS)
    );

    const {
      selectedPickupAddress,
      selectedDeliveryAddress,
      pickUpAddressUpdated,
      deliveryAddressUpdated,
    } = getStore().claim.schedule;
    const address = isPickup ? selectedPickupAddress : selectedDeliveryAddress;
    const {
      Standardized,
      StandardizedSourceName,
      BaseAddressHeaderId,
      ...rest
    } = address;
    const addressObj = { ...rest };
    const isAddressAlreayUpdated = isPickup
      ? pickUpAddressUpdated
      : deliveryAddressUpdated;
    // API call to validate address here
    /*const confirmAddressResponse = await dispatch(
      standardizeAddressApi(CacheId, !isAddressAlreayUpdated, addressObj, false)
    ).catch((err) => dispatch(serviceUnavailable()));
    if (isEmpty(confirmAddressResponse)) return;

    // After API call
    await dispatch(
      updateApiProgress(apiEndMsg, 100, COMPONENTS.CONFIRM_ADDRESS, 40)
    );
    await sleep(1000);
    await dispatch(clearApiProgress());

    // check if address is valid
    if (confirmAddressResponse.QASResult.isValidAddress) {
      const address =
        confirmAddressResponse.QASResult.AddressResult.CorrectedAddress;
      if (!isEmpty(address)) {
        await dispatch(updateCorrectedAddress(address, addressObj, isPickup));
      }
      showComponent = isPickup
        ? "confirmPickupDateTime"
        : "confirmDeliveryDateTime";
    } else {
      // address is not valid
      // show change address component
      showComponent = isPickup
        ? "changePickupAddress"
        : "changeDeliveryAddress";

      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "system", {
            key: "SystemMessage.EnteredInvalidAddress",
          }),
        ])
      );
    }*/

    await dispatch(
      updateApiProgress(apiEndMsg, 100, COMPONENTS.CONFIRM_ADDRESS, 40)
    );
    await sleep(1000);
    await dispatch(clearApiProgress());
    
    await dispatch(updateCorrectedAddress(address, addressObj, isPickup));
    showComponent = isPickup
        ? "confirmPickupDateTime"
        : "confirmDeliveryDateTime";
  } else if (status === "Change") {
    showComponent = isPickup ? "changePickupAddress" : "changeDeliveryAddress";
  }
  dispatch(
    addJourneyMessages([
      createMessage("PICKUP_AND_DELIVERY", "system", { showComponent }),
    ])
  );
};

export const changeAddressSchedule = (address, isPickup) => async (
  dispatch,
  getStore
) => {
  let showComponent = "";
  let addressText = "";

  if (isEmpty(address)) {
    addressText = "Cancel";
    await dispatch(
      addJourneyMessages([createMessage("TEXT", "user", addressText)])
    );
    showComponent = `confirm${isPickup ? "Pickup" : "Delivery"}Address`;
  } else {
    const {
      unitNumber,
      streetName,
      district,
      subdistrict,
      province,
      postalCode
    } = address;

    // let addressObj = {
    //   Address1: this.state.floor.trim(),
    //   Address2: this.state.block.trim(),
    //   Address3: this.state.street.trim(),
    //   PostalCode: this.state.zipcode.trim(),
    //   City: this.state.city.trim(),
    //   StateProvinceCode: this.state.State.trim(),
    //   Country: this.props.data.DEFAULT_CITY || 'Thailand',
    //   CountryCode: 'TH'
    // }

    let addressObj = {
      Address1: unitNumber,
      Address2: streetName,
      Address3: subdistrict,
      PostalCode: postalCode,
      City: district || "Bangkok",
      StateProvinceCode: province,
      Country: "Thailand",
      CountryCode: "TH",
      AddressId: "",
    };

    // call standardrize api
    await dispatch(
      updateApiProgress(
        isPickup
          ? i18n.t("Constants.API_PROGRESS.UPDATE_PICKUP_ADDRESS")
          : i18n.t("Constants.API_PROGRESS.UPDATE_ADDRESS"),
        40,
        COMPONENTS.UPDATE_ADDRESS,
        0
      )
    );
    // API call to change address here
    // const changeAddressResponse = await dispatch(
    //   standardizeAddressApi(CacheId, false, addressObj, false)
    // ).catch((err) => dispatch(serviceUnavailable()));
    // if (isEmpty(changeAddressResponse)) return;

    const {
      selectedPickupAddress,
      selectedDeliveryAddress,
      pickUpAddressUpdated,
      deliveryAddressUpdated,
    } = getStore().claim.schedule;
    const origAddress = isPickup ? selectedPickupAddress : selectedDeliveryAddress;
    const {
      Standardized,
      StandardizedSourceName,
      BaseAddressHeaderId,
      ...rest
    } = origAddress;

    const enrolledAddress = getStore().validation.agreement.EnrolledAddress;

    const changeAddressResponse = {
      QASResult: {
        isValidAddress: true,
        QASAPIFailure: false,
        AddressResult: {
          StandardisedFromAddressId: origAddress.AddressId || enrolledAddress.AddressId,
          CorrectedAddress: {
            AddressLine1: addressObj.Address1,
            AddressLine2: addressObj.Address2,
            AddressLine3: addressObj.Address3,
            City: addressObj.City,
            StateProvinceCode: addressObj.StateProvinceCode,
            PostalCode: addressObj.PostalCode,
            Country: "Thailand",
            Standardized: true,
            StandardizedSourceName: "StubbedQAS",
            BaseAddressHeaderId: "c7730733f6bea936403e881fed322e26"
          },
          Action: "Edit"
        }
      }
    }
    
    // After API call
    await dispatch(
      updateApiProgress(
        isPickup
          ? i18n.t("Constants.API_PROGRESS.UPDATE_PICKUP_ADDRESS_SUCCESS")
          : i18n.t("Constants.API_PROGRESS.UPDATE_ADDRESS_SUCCESS"),
        100,
        COMPONENTS.UPDATE_ADDRESS,
        40
      )
    );
    await sleep(1000);
    await dispatch(clearApiProgress());

    // check for is address valid
    if (changeAddressResponse.QASResult.isValidAddress) {
      // save correct address
      const address =
        changeAddressResponse.QASResult.AddressResult.CorrectedAddress;
      const {
        AddressLine1,
        AddressLine2,
        AddressLine3,
        Country,
        PostalCode,
        City,
        StateProvinceCode
      } = address;
      addressText = `${AddressLine1} ${AddressLine2} ${AddressLine3} ${City} ${StateProvinceCode} ${Country} ${PostalCode}`;
      await dispatch(
        addJourneyMessages([createMessage("TEXT", "user", { key: "ChangeAddressUserMessage",
        values: { 
          addressType: `${isPickup ? i18n.t("PickupLabel") : i18n.t("ReturnLabel")}`, 
          newAddress: addressText 
        }
      })])
        //createMessage("TEXT", "system", { key: "confirmEmail.emailMsg", values: { EmailAddress } }),
      );
      showComponent = await dispatch(
        updateCorrectedAddress(address, addressObj, isPickup)
      );
    } else if (!changeAddressResponse.QASResult.isValidAddress) {
      // address is not valid
      const attemptCount = await dispatch(updateFailedAddressAttempt(isPickup));
      // check retry
      if (attemptCount < 3) {
        dispatch(
          addJourneyMessages([
            createMessage(
              "TEXT",
              "system",
              "Your address is invalid. Please Enter new Adress"
            ),
          ])
        );
        // change address component
        showComponent = isPickup
          ? "changePickupAddress"
          : "changeDeliveryAddress";
      } else {
        // show incorrect address component
        showComponent = isPickup
          ? "incorrectPickupAddress"
          : "incorrectDeliveryAddress";
      }
    }

    dispatch(
      updateVisitor({
        lastActivity: isPickup
          ? ACTIVITY.UPDATE_PICKUP_ADDRESS
          : ACTIVITY.UPDATE_DELIVERY_ADDRESS,
      })
    );
  }
  dispatch(
    addJourneyMessages([
      createMessage("PICKUP_AND_DELIVERY", "system", { showComponent }),
    ])
  );
};

const updateDeliveryDateOnChange = () => async (dispatch, getStore) =>{
  const selectedPickupDate = getStore().claim.schedule.selectedPickupDate
  const {
    StateProvinceCode: pickupProvinceCode,
  } = getStore().claim.schedule.selectedPickupAddress;
  const {
    StateProvinceCode: deliveryProvinceCode,
  } = getStore().claim.schedule.selectedDeliveryAddress;
  const isBangkokToBangkok = pickupProvinceCode === deliveryProvinceCode

  console.log("start schedOperator line 401")
  console.log("selectedPickupDate ", selectedPickupDate)
  const deliveryOptions = getDeliveryDates(selectedPickupDate, isBangkokToBangkok ? pickupProvinceCode : "Not Bangkok");
  console.log("deliveryOptions ", deliveryOptions)
  const deliverySlot = deliveryOptions[0].slots[0];
  console.log("New delivery date ", deliveryOptions[0].date)
  console.log("New delivery slit ", deliverySlot)
  console.log("end schedOperator line 401")
  await dispatch(saveDeliveryDateTime(deliveryOptions[0].date, deliverySlot));
}

const updateCorrectedAddress = (
  correctedAddress,
  oldAddress,
  isPickup
) => async (dispatch, getStore) => {
  let showComponent = "";
  const correctedAddressObj = {
    Address1: correctedAddress.AddressLine1,
    Address2: correctedAddress.AddressLine2,
    Address3: correctedAddress.AddressLine3,
    PostalCode: correctedAddress.PostalCode,
    City: correctedAddress.CityName,
    StateProvinceCode: correctedAddress.StateProvinceCode,
    Country: correctedAddress.Country,
    CountryCode: correctedAddress.CountryCode,
    AddressId: "",
    Standardized: correctedAddress.Standardized,
    StandardizedSourceName: correctedAddress.StandardizedSourceName,
    BaseAddressHeaderId: correctedAddress.BaseAddressHeaderId,
  };
  const pickupOptions = await dispatch(createPickupOptions());

  if (isPickup) {
    await dispatch(savePickupAddress(correctedAddressObj, true));
    await dispatch(savePickupAddress(oldAddress));
    const pickupSlot = pickupOptions[0].slots[0];
    await dispatch(savePickupDateTime(pickupOptions[0].date, pickupSlot));
    showComponent = "confirmPickupDateTime";
  } else {
    await dispatch(saveDeliveryAddress(correctedAddressObj, true));
    await dispatch(saveDeliveryAddress(oldAddress));
    await dispatch(updateDeliveryDateOnChange());
    showComponent = "confirmDeliveryDateTime";
  }

  return showComponent;
  
};

export const changeDateTimeSchedule = (date, slot, isPickup) => async (
  dispatch,
  getStore
) => {
  const journeyMessageRepairOption = getStore().journeyMessages.repairOption;
  const {
    StateProvinceCode,
  } = getStore().claim.schedule.selectedDeliveryAddress;
  let showComponent = "";
  if (isEmpty(date) || isEmpty(slot)) {
    await dispatch(
      addJourneyMessages([createMessage("TEXT", "user", i18n.t("CancelButton"))])
    );
    showComponent = `confirm${isPickup ? "Pickup" : "Delivery"}DateTime`;
  } else {
    await dispatch(

      addJourneyMessages([createMessage("TEXT", "user", i18n.t("SubmitButton"))])
    );
    await dispatch(
      updateApiProgress(
        journeyMessageRepairOption === "PUR" ? i18n.t("Constants.API_PROGRESS.UPDATE_SCHEDULE") : i18n.t("Constants.API_PROGRESS.UPDATE_WALKIN_SCHEDULE"),
        40,
        COMPONENTS.UPDATE_SCHEDULE,
        0
      )
    );
    const initial = getStore().journeyMessages.flowProgress.percentage;
    if (isPickup) {
      // const deliveryOptions = getDeliveryDates(date, StateProvinceCode);
      // const deliverySlot = deliveryOptions[0].slots[0];
      // console.log("new selected delivery date ", deliveryOptions[0].date )
      // dispatch(saveDeliveryDateTime(deliveryOptions[0].date, deliverySlot));
      await dispatch(savePickupDateTime(date, slot));
      showComponent = "confirmDeliveryAddress";
      dispatch(
        updateFlowProgress(
          i18n.t("Constants.PROGRESS_DATA.CONFIRM_RETURN_DETAILS.title"),
          i18n.t("Constants.PROGRESS_DATA.CONFIRM_RETURN_DETAILS.count"),
          initial
        )
      );
    } else {
      await dispatch(saveDeliveryDateTime(date, slot));
      showComponent = "confirmDetails";
      dispatch(
        updateFlowProgress(
          i18n.t("Constants.PROGRESS_DATA.CONFIRM_SCHEDULE.title"),
          i18n.t("Constants.PROGRESS_DATA.CONFIRM_SCHEDULE.count"),
          initial
        )
      );
    }

    const repairOption = getStore().journeyMessages.repairOption;

    await dispatch(
      updateApiProgress(
        repairOption === "PUR" ? i18n.t("Constants.API_PROGRESS.UPDATE_SCHEDULE_SUCCESS") : i18n.t("Constants.API_PROGRESS.UPDATE_WALKIN_SCHEDULE_SUCCESS"),
        100,
        COMPONENTS.UPDATE_SCHEDULE,
        40
      )
    );
    await sleep(1500);
    await dispatch(clearApiProgress());
    dispatch(
      updateVisitor({
        lastActivity: isPickup
          ? ACTIVITY.UPDATE_PICKUP_SCHEDULE
          : ACTIVITY.UPDATE_DELIVERY_SCHEDULE,
      })
    );
  }
  dispatch(
    addJourneyMessages([
      createMessage("PICKUP_AND_DELIVERY", "system", { showComponent }),
    ])
  );
};

// export const confirmationOfDetails = (status) => async (dispatch, getStore) => {
//   await dispatch(addJourneyMessages([createMessage("TEXT", "user", status)]));
//   const enquiryOption = getStore().journeyMessages.enquiryOption;
//   let showComponent = "confirmPickupAddress",
//     module = "PICKUP_AND_DELIVERY";
//   if (status === "Confirm") {
//     module =
//       enquiryOption === EnumEnquiryModules.ChangeSchedule
//         ? "IMPORTANT_NOTE"
//         : [
//             EnumEnquiryModules.NewRequest,
//             EnumEnquiryModules.InWarrantyRequest,
//             EnumEnquiryModules.ResumeRequest,
//           ].includes(enquiryOption)
//         ? "CONTACT_CONFIRMATION"
//         : "";
//     dispatch(updateVisitor({ lastActivity: ACTIVITY.CONFIRM_SCHEDULE }));
//   }
//   if (module === "CONTACT_CONFIRMATION") {
//     await dispatch(updateContactDetailsData());
//     await dispatch(
//       updateApiProgress(
//         i18n.t("Constants.API_PROGRESS.UPDATE_SCHEDULE"),
//         40,
//         COMPONENTS.UPDATE_SCHEDULE,
//         0
//       )
//     );

//     const { selectedAsset } = getStore().claim.deviceConfirmation;
//     const { CacheId } = getStore().session.sessionData.Configurations;
//     const {
//       InteractionLineId,
//     } = getStore().session.interactionData.Interaction;
//     const {
//       ServiceRequestId,
//       CustomerCaseId,
//     } = getStore().serviceRequest.serviceRequestDetails;
//     const {
//       correctedPickupAddress,
//       correctedDeliveryAddress,
//       selectedPickupDate,
//       selectedPickupSlot,
//       selectedDeliveryDate,
//     } = getStore().claim.schedule;
//     const { firstName, lastName } = getStore().validation.inputData;
//     const customerName = `${firstName} ${lastName}`;

//     const {
//       RepairRequest,
//       ServiceOrder,
//     } = getStore().serviceRequest.determineIncidentDetails;

//     let ServiceOrderId;

//     // check for resume if service order is called before or not
//     // call service order api
//     if (isEmpty(ServiceOrder)) {
//       let serviceOrderResponse = await dispatch(
//         serviceOrderApi(
//           CacheId,
//           InteractionLineId,
//           ServiceRequestId,
//           CustomerCaseId,
//           selectedAsset.AssetCatalog.AssetCatalogId
//         )
//       ).catch((err) => dispatch(serviceUnavailable()));
//       if (isEmpty(serviceOrderResponse)) return;
//       ServiceOrderId =
//         serviceOrderResponse.CreateServiceOrderResults.ServiceOrderId;
//     } else {
//       ServiceOrderId = ServiceOrder.ServiceOrderId;
//     }

//     if (isEmpty(RepairRequest)) {
//       // call create repair request
//       let repairRequestResponse = await dispatch(
//         createRepairRequestApi(
//           CacheId,
//           CustomerCaseId,
//           ServiceRequestId,
//           ServiceOrderId
//         )
//       ).catch((err) => dispatch(serviceUnavailable()));
//       if (isEmpty(repairRequestResponse)) return;
//     }

//     // call get shipping order api
//     let shippingAddressResponse = await dispatch(
//       getShippingAddressApi(CacheId)
//     ).catch((err) => dispatch(serviceUnavailable()));
//     if (isEmpty(shippingAddressResponse)) return;

//     // call update shipping api
//     let updateShippingAddressResponse = await dispatch(
//       updateShippingAddressApi(
//         CacheId,
//         customerName,
//         ServiceOrderId,
//         ServiceRequestId,
//         selectedPickupDate,
//         selectedPickupSlot,
//         correctedPickupAddress,
//         selectedDeliveryDate,
//         correctedDeliveryAddress
//       )
//     ).catch((err) => dispatch(serviceUnavailable()));
//     if (isEmpty(updateShippingAddressResponse)) return;

//     await dispatch(
//       updateApiProgress(
//         i18n.t("Constants.API_PROGRESS.UPDATE_SCHEDULE_SUCCESS"),
//         100,
//         COMPONENTS.UPDATE_SCHEDULE,
//         40
//       )
//     );
//     await sleep(1500);
//     await dispatch(clearApiProgress());

//     showComponent = "ConfirmMobileNumber";
//     const initial = getStore().journeyMessages.flowProgress.percentage;
//     dispatch(
//       updateFlowProgress(
//         i18n.t("Constants.PROGRESS_DATA.CONFIRM_CONTACT.title,
//         i18n.t("Constants.PROGRESS_DATA.CONFIRM_CONTACT.count,
//         initial
//       )
//     );
//   }
//   await dispatch(
//     addJourneyMessages([createMessage(module, "system", { showComponent })])
//   );
// };

export const createPickupOptions = () => async (dispatch, getStore) => {
  const {
    StateProvinceCode: selectedProvince,
  } = getStore().claim.schedule.selectedPickupAddress;
  const {
    StateProvinceCode: correctedProvince,
  } = getStore().claim.schedule.correctedPickupAddress;

  const options = getPickupDates(new Date(), correctedProvince ? correctedProvince : selectedProvince);
  dispatch({
    type: setPickupOptions.toString(),
    payload: options,
  });
  return options;
};

export const initializePickupSchedule = () => async (dispatch, getStore) => {
  const {Address: serviceRequestPickupAddress} = getStore().serviceRequest.determineIncidentDetails.ShippingOrderOutbound ?? {}
  const {Address: serviceRequestDeliveryAddress} = getStore().serviceRequest.determineIncidentDetails.ShippingOrderInbound ?? {}
  const {
    StateProvinceCode,
  } = getStore().claim.schedule.selectedDeliveryAddress;
  const address = getStore().validation.agreement.EnrolledAddress;
  const deliveryAddress = serviceRequestDeliveryAddress && Object.keys(serviceRequestDeliveryAddress).length > 0 ? serviceRequestDeliveryAddress : address
  
  const pickupAddress = serviceRequestPickupAddress && Object.keys(serviceRequestPickupAddress).length > 0 ? serviceRequestPickupAddress : address
  await dispatch(savePickupAddress(pickupAddress));
  await dispatch(saveDeliveryAddress(deliveryAddress));
  await dispatch(saveRegisteredAddress(address));
  const pickupOptions = await dispatch(createPickupOptions());
  const pickupSlot = pickupOptions[0].slots[0];
  const deliveryOptions = getDeliveryDates(pickupOptions[0].date, serviceRequestDeliveryAddress?.StateProvinceCode ?? StateProvinceCode);
  const deliverySlot = deliveryOptions[0].slots[0];
  await dispatch(savePickupDateTime(pickupOptions[0].date, pickupSlot));
  await dispatch(saveDeliveryDateTime(deliveryOptions[0].date, deliverySlot));
  return;
};

export const confirmRegisteredAddress = (status, isPickup) => async (
  dispatch,
  getStore
) => {
  const which = isPickup ? "Pick-up" : "Return";

  await dispatch(addJourneyMessages([createMessage("TEXT", "user", i18n.t(`${status}Button`))]));
  if (status === "No") {
    await dispatch(
      addJourneyMessages([
        createMessage("TEXT", "system", {
          key: "SystemMessage.UnableToProcessRequest",
        }),
      ])
    );
    dispatch(confirmToCancelRequest("", `Invalid ${which} Address`, true));
  } else {
    const {
      selectedPickupAddress,
      selectedDeliveryAddress,
    } = getStore().claim.schedule;
    const module = "PICKUP_AND_DELIVERY";
    let showComponent = "";
    if (isPickup) {
      showComponent = "confirmPickupDateTime";
      dispatch(savePickupAddress(selectedPickupAddress, true));
    } else {
      showComponent = "confirmDeliveryDateTime";
      await dispatch(updateDeliveryDateOnChange());
      dispatch(saveDeliveryAddress(selectedDeliveryAddress, true));
    }
    dispatch(
      addJourneyMessages([createMessage(module, "system", { showComponent })])
    );
  }
};

export const saveDeliveryDateTime = (date, slot) => async (dispatch) => {
  await dispatch({
    type: setDeliveryDateTime.toString(),
    payload: { date, slot },
  });
  return date;
};

export const savePickupDateTime = (date, slot) => async (dispatch) => {
  await dispatch({
    type: setPickupDateTime.toString(),
    payload: { date, slot },
  });
  return date;
};

export const savePickupAddress = (address, isCorrected = false) => async (
  dispatch
) => {
  await dispatch({
    type: isCorrected
      ? setCorrectedPickupAddress.toString()
      : setPickupAddress.toString(),
    payload: { address },
  });
  return address;
};

export const saveDeliveryAddress = (address, isCorrected = false) => async (
  dispatch
) => {
  await dispatch({
    type: isCorrected
      ? setCorrectedDeliveryAddress.toString()
      : setDeliveryAddress.toString(),
    payload: { address },
  });
  return address;
};

export const saveRegisteredAddress = (address) => async (dispatch) => {
  await dispatch({
    type: setRegisteredAddress.toString(),
    payload: address,
  });
  return address;
};

const updateFailedAddressAttempt = (isPickup) => async (dispatch, getStore) => {
  const {
    failedPickupAddressAttempt,
    failedDeliveryAddressAttempt,
  } = getStore().claim.schedule;
  const count =
    (isPickup ? failedPickupAddressAttempt : failedDeliveryAddressAttempt) + 1;
  const attemptType = isPickup
    ? setFailedPickupAddressAttempt
    : setFailedDeliveryAddressAttempt;
  dispatch({
    type: attemptType.toString(),
    payload: count,
  });
  return count;
};

export const confirmationOfDetails = () => async (dispatch, getStore) => {
  const repairOption =
  getStore().journeyMessages.repairOption ||
  getStore().serviceRequest.determineIncidentDetails?.ServiceRequest?.FulfillmentOption ||
  getStore().serviceRequest.determineIncidentDetails?.RepairRequest?.RepairRequestType;

  await dispatch(updateContactDetailsData());
  await dispatch(
    updateApiProgress(
      repairOption === "PUR" ? i18n.t("Constants.API_PROGRESS.UPDATE_SCHEDULE") : i18n.t("Constants.API_PROGRESS.UPDATE_WALKIN_SCHEDULE"),
      40,
      COMPONENTS.UPDATE_SCHEDULE,
      0
    )
  );

  const { selectedAsset } = getStore().claim.deviceConfirmation;
  const { CacheId } = getStore().session.sessionData.Configurations;
  const {
    InteractionLineId,
  } = getStore().session.interactionData.Interaction;
  const {
    ServiceRequestId,
    CustomerCaseId,
  } = getStore().serviceRequest.serviceRequestDetails ?? {};
  const {
    correctedPickupAddress,
    correctedDeliveryAddress,
    selectedPickupAddress,
    selectedDeliveryAddress,
    selectedPickupDate,
    selectedPickupSlot,
    selectedDeliveryDate,
  } = getStore().claim.schedule;
  const { firstName, lastName } = getStore().validation.inputData;
  const customerName = `${firstName} ${lastName}`;
  const { caseType } = getStore().journeyMessages ? getStore().journeyMessages : "Fault Repair";
  const {
    RepairRequest,
    ServiceOrder,
    ServiceRequestId: determineServiceRequestId,
  } = getStore().serviceRequest.determineIncidentDetails;

  let ServiceOrderId;

  // check for resume if service order is called before or not
  // call service order api
  if (isEmpty(ServiceOrder)) {
    console.log("SERVICE ORDER EMPTY")
    let serviceOrderResponse = await dispatch(
      serviceOrderApi(
        caseType,
        CacheId,
        InteractionLineId,
        ServiceRequestId,
        CustomerCaseId,
        selectedAsset.AssetCatalog.AssetCatalogId,
        repairOption
      )
    ).catch((err) => dispatch(serviceUnavailable()));
    if (isEmpty(serviceOrderResponse)) return false;
    ServiceOrderId =
      serviceOrderResponse.CreateServiceOrderResults.ServiceOrderId;
  } else {
    console.log("SERVICE ORDER NOT EMPTY")

    ServiceOrderId = ServiceOrder.ServiceOrderId;
  }

  const {
    selectedAppointmentDate,
    selectedAppointmentSlot,
    selectedStore,
  } = getStore().claim.walkIn;

  const isWalkIn = repairOption === "WALKIN";
  const appointmentDate = isWalkIn ? (selectedAppointmentDate ? `${selectedAppointmentDate} ${selectedAppointmentSlot}` : undefined) : undefined;
  const serviceProviderId = isWalkIn ? (selectedStore && selectedStore.SERVICE_PROVIDER_ID || undefined) : undefined;
  const softAllocationTime = isWalkIn ? (selectedAppointmentSlot || undefined) : undefined;
  const localAppointmentDateTime = moment(appointmentDate + " +07:00", "YYYY-MM-DD HH:mm:ss Z")
  // const utcAppointmentDateTime = localAppointmentDateTime.utc();
  const utcAppointmentDateTime = localAppointmentDateTime.toISOString();
  console.log(appointmentDate)
  
  if (isEmpty(RepairRequest)) {
    console.log("RepairRequest IS EMPTY")
    // call create repair request
    let repairRequestResponse = await dispatch(
      createRepairRequestApi(
        CacheId,
        CustomerCaseId,
        ServiceRequestId,
        ServiceOrderId,
        repairOption,
        utcAppointmentDateTime,
        serviceProviderId,
        softAllocationTime
      )
    ).catch((err) => dispatch(serviceUnavailable()));
    if (isEmpty(repairRequestResponse)) return false;
  }

  if(repairOption !== "PUR"){
    console.log("CALL GET SHIPPING ADDRESS!")
    // call get shipping order api
    // let shippingAddressResponse = await dispatch(
    //   getShippingAddressApi(CacheId)
    // ).catch((err) => dispatch(serviceUnavailable()));
    // if (isEmpty(shippingAddressResponse)) return;
    let shippingAddressResponse = null;
    let retrycount = 0;
    while(retrycount < 2) {
      shippingAddressResponse = await dispatch(getShippingAddressApi(CacheId))
      .catch((err) =>
        { return null }
      );
  
      if (shippingAddressResponse === null) {
        retrycount++;
      } else {
        retrycount = 2;
      }
    }
    // not allowing to go ahead if response is empty
    if (isEmpty(shippingAddressResponse)) {
      dispatch(serviceUnavailable())
      return false;
    }
  }
  // call update shipping api
  let updateShippingAddressResponse;

  if (repairOption === "WALKIN") {
    const {
      selectedAppointmentDate,
      selectedAppointmentSlot,
      selectedStore,
    } = getStore().claim.walkIn;

    const utcAppointmentDate = localAppointmentDateTime.format("YYYY-MM-DD");
    const utcAppointmentTime = localAppointmentDateTime.format("HH:mm");

    const QASAddress = {
      AddressLine1: selectedStore.ADDRESS_LINE_1,
      AddressLine2: selectedStore.ADDRESS_LINE_2,
      AddressLine3: selectedStore.ADDRESS_LINE_3,
      CityName: selectedStore.CITY_NAME,
      StateProvinceCode: selectedStore.STATE_PROVINCE_NAME,
      PostalCode: selectedStore.POSTAL_CODE,
      Country: "Thailand",
      CountryCode: "TH",
      Standardized: true,
      StandardizedSourceName: "QAS",
      BaseAddressHeaderId:
        "109f4afca0ecfbf50235f0d5e890495e3d4c78abab6289782e81ea7319eb0d4fb21851a3bf6af03c6df5a08032f63649",
      IsOverridden: false,
      AddressId: selectedStore.ADDRESS_ID,
      IsOverridden: false,
    };
  
    const UpdateShippingOrderParameters = {
      EntityUpdateOptions: {
        ReturnEntity: true,
      },
      SessionId: CacheId,
      ServiceOrderId: ServiceOrderId,
      ServiceRequestId: ServiceRequestId,
      ShippingOrder: {
        ShippingMethodType: isToday(selectedAppointmentDate)
          ? "Same Day"
          : "Next Day",
        CarrierNumber: "347",
        SignatureRequired: true,
        RequestedShipmentDate: utcAppointmentDate,
        ExpectedDeliveryDate: utcAppointmentDate,
        // DeliveryWindow: utcAppointmentTime,
        DeliveryWindow: selectedAppointmentSlot.substring(0, selectedAppointmentSlot.length - 2).replace(new RegExp(":", "g"), ""),
        Remark: "",
        ReceiverName: selectedStore.SERVICE_PROVIDER_NAME,
        ReceiverAddress: QASAddress,
      },
      InboundShippingOrder: undefined
    };

    //console.log("scheduleOperator.js walkin UpdateShippingOrderParameters", UpdateShippingOrderParameters)

    updateShippingAddressResponse = await dispatch(
      walkinUpdateShippingAddressApi(UpdateShippingOrderParameters)
    ).catch((err) => dispatch(serviceUnavailable()));
    if (isEmpty(updateShippingAddressResponse)) return false;
  } else { //PUR
    updateShippingAddressResponse = await dispatch(
      updateShippingAddressApi(
        CacheId,
        customerName,
        ServiceOrderId,
        ServiceRequestId ?? determineServiceRequestId,
        selectedPickupDate,
        selectedPickupSlot,
        correctedPickupAddress || correctedPickupAddress === "" ? selectedPickupAddress : correctedPickupAddress,
        selectedDeliveryDate,
        correctedDeliveryAddress || correctedDeliveryAddress === "" ? selectedDeliveryAddress : correctedDeliveryAddress,
      )
    ).catch((err) => dispatch(serviceUnavailable()));
    if (isEmpty(updateShippingAddressResponse)) return false;
  }

  await dispatch(
    updateApiProgress(
      repairOption === "WALKIN" ? i18n.t("Constants.API_PROGRESS.UPDATE_WALKIN_SCHEDULE_SUCCESS") : i18n.t("Constants.API_PROGRESS.UPDATE_SCHEDULE_SUCCESS"),
      100,
      COMPONENTS.UPDATE_SCHEDULE,
      40
    )
  );
  await sleep(1500);
  await dispatch(clearApiProgress());

  return true;
};

export const redirectToPhoneNumber = (status, textPrint) => async (dispatch, getStore) => {
  await dispatch(addJourneyMessages([createMessage("TEXT", "user", textPrint)]));
  const enquiryOption = getStore().journeyMessages.enquiryOption;
  let showComponent = "confirmPickupAddress";
  let module = "PICKUP_AND_DELIVERY";
  const options = [
    EnumEnquiryModules.NewRequest,
    EnumEnquiryModules.NewRequestBatteryReplacement,
    EnumEnquiryModules.NewRequestScreenAndBattery,
    EnumEnquiryModules.InWarrantyRequest,
    EnumEnquiryModules.ResumeRequest,
  ];

  if (status === "Confirm") {
    module =
      enquiryOption === EnumEnquiryModules.ChangeSchedule
        ? "IMPORTANT_NOTE"
        : options.includes(enquiryOption)
          ? "CONTACT_CONFIRMATION"
          : "";
    dispatch(updateVisitor({ lastActivity: ACTIVITY.CONFIRM_SCHEDULE }));
  }

  if (module === "CONTACT_CONFIRMATION") {
    showComponent = "ConfirmMobileNumber";
    const initial = getStore().journeyMessages.flowProgress.percentage;
    dispatch(
      updateFlowProgress(
        i18n.t("Constants.PROGRESS_DATA.CONFIRM_CONTACT.title"),
        i18n.t("Constants.PROGRESS_DATA.CONFIRM_CONTACT.count"),
        initial
      )
    );
  }
  
  if(module === "IMPORTANT_NOTE"){
    // await dispatch(confirmationOfDetails())
    let res = await dispatch(confirmationOfDetails())
    if (!res) {
      return false;
    }
    await dispatch(
      addJourneyMessages([
        createMessage("TEXT", "system", {
          key: "SystemMessage.ScheduleUpdated",
        }),
        createMessage("TEXT", "system", {
          key: "SystemMessage.ThankYourForUsingService",
        })]),
    dispatch(endFlow())
    );
  }
  else{
    await dispatch(
      addJourneyMessages([
        createMessage(module, "system", { showComponent })])
    );
  }
}

export const changePURSchedule = (status, textPrint) => async (dispatch, getStore) => {

  await dispatch(addJourneyMessages([createMessage("TEXT", "user", textPrint)]));

  if (status === "Confirm") {
    const { CacheId } = getStore().session.sessionData.Configurations;
    const {
      ServiceRequest,
      CustomerCaseId,
      RepairRequest,
      ServiceOrder,
      ShippingOrderInbound,
      ShippingOrderOutbound,
    } = getStore().serviceRequest.determineIncidentDetails;
    const {
      selectedPickupAddress,
      selectedDeliveryAddress,
      selectedPickupDate,
      selectedPickupSlot,
      selectedDeliveryDate,
    } = getStore().claim.schedule;

    let CreateRepairScheduleParameters = {
      CustomerCaseId: CustomerCaseId,
      InboundShippingOrder: {
        ShippingOrderId: ShippingOrderInbound.ShippingOrderId,
        ShippingMethodType: "2BD",
        RequestedDeliveryDate: getFormmatedDate(
          new Date(selectedDeliveryDate),
          "yyyy-mm-dd",
          "-"
        ),
        DeliveryWindow: "16H",
        ReceiverAddress: {
          AddressLine1: selectedDeliveryAddress.Address1,
          AddressLine2: selectedDeliveryAddress.Address2,
          AddressLine3: selectedDeliveryAddress.Address3,
          CityName: selectedDeliveryAddress.City,
          StateProvinceCode: selectedDeliveryAddress.StateProvinceCode,
          PostalCode: selectedDeliveryAddress.PostalCode,
          Country: selectedDeliveryAddress.Country,
          CountryCode: selectedDeliveryAddress.CountryCode,
          Standardized: false,
        },
      },
      RepairRequestId: RepairRequest.RepairRequestId,
      RepairRequestType: "PUR",
      RepairStatus: RepairRequest.RepairStatus,
      SessionId: CacheId,
      ServiceOrderId: ServiceOrder.ServiceOrderId,
      ServiceRequestId: ServiceRequest.ServiceRequestId,
    };

    // update pick-up details only for status type scheduled
    if (RepairRequest.RepairStatus === "SCHEDULED") {
      CreateRepairScheduleParameters.OutboundShippingOrder = {
        ShippingOrderId: ShippingOrderOutbound.ShippingOrderId,
        ShippingMethodType: isToday(selectedPickupDate)
          ? "Same Day"
          : "Next Day",
        CarrierNumber: "347",
        RequestedPickupDate: getFormmatedDate(
          new Date(selectedPickupDate),
          "yyyy-mm-dd",
          "-"
        ),
        DeliveryWindow: selectedPickupSlot === "9am - 12pm" ? "12H" : "16H",
        ReceiverAddress: {
          AddressLine1: selectedPickupAddress.Address1,
          AddressLine2: selectedPickupAddress.Address2,
          AddressLine3: selectedPickupAddress.Address3,
          CityName: selectedPickupAddress.City,
          StateProvinceCode: selectedPickupAddress.StateProvinceCode,
          PostalCode: selectedPickupAddress.PostalCode,
          Country: selectedPickupAddress.Country,
          CountryCode: selectedPickupAddress.CountryCode,
          Standardized: false,
        },
      };
    }

    await dispatch(
      updateApiProgress(
        API_PROGRESS.UPDATE_SCHEDULE,
        40,
        COMPONENTS.UPDATE_SCHEDULE,
        0
      )
    );

    // call update shipping api
    let updatePURScheduleResponse = await dispatch(
      updatePURScheduleApi(CreateRepairScheduleParameters)
    ).catch((err) => dispatch(serviceUnavailable()));

    // not allowing to go ahead if response is empty
    if (isEmpty(updatePURScheduleResponse)) {
      return;
    }

    await dispatch(
      updateApiProgress(
        API_PROGRESS.UPDATE_SCHEDULE_SUCCESS,
        100,
        COMPONENTS.UPDATE_SCHEDULE,
        40
      )
    );
    await sleep(1500);
    await dispatch(clearApiProgress());

    // dispatch(
    //   addJourneyMessages([
    //     createMessage("TEXT", "system", {
    //       key: "SystemMessage.UpdatePURConfirmation",
    //     }),
    //     createMessage("TEXT", "system", {
    //       key: "SystemMessage.RequestSMSConfirmation",
    //     }),
    //   ])
    // );

    await dispatch(
      addJourneyMessages([
        createMessage("TEXT", "system", {
          key: "SystemMessage.ScheduleUpdated",
        })]),
        createMessage("TEXT", "system", {
          key: "SystemMessage.ThankYourForUsingService",
        })
    );

    await sleep(3000);
    dispatch(endFlow());

  } else {
    let showComponent = "confirmPickupAddress";
    let module = "PICKUP_AND_DELIVERY";
    await dispatch(
      addJourneyMessages([
        createMessage(module, "system", { showComponent })])
    );
  }
};