import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  triageDetails: []
};

const troubleshootingReducer = createSlice({
  name: "troubleshoot",
  initialState,
  reducers: {
    resetStore() {
      return initialState;
    },
    setTroubleshootAnswer(state, action) {
      const { key, value } = action.payload;
      state[key] = value;
      state.triageDetails = [action.payload, ...state.triageDetails];
    },
    setComments(state, action){
      const { comments } = action.payload;
      state.comments = comments;
    }
  },
});

const { actions, reducer } = troubleshootingReducer;
export const { resetStore, setTroubleshootAnswer, setComments } = actions;
export default reducer;
