import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CLIENT, INCEDENT_TYPES } from "../../../helpers/constants";
import {
    getDeviceName,
    getFormattedUserName,
    getMdnWithoutUnderscore,
    isEmpty,
    getMaskedIMEI
} from "../../../helpers/formatUtils";
import moment from "moment";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { getEnrolledServiceFee } from "../../../actions/cowrapperapi";
import { TextBold, TextMedium, TextRegular } from "../../../base/BaseText";

import { addJourneyMessages } from "../../../helpers/addJourneyMessages";
// import { useTranslate } from "../../../helpers/languageUtils";
import { selectAgreementAsset } from "../state/operators/deviceConfirmationOperators";
// import BaseImg from "../../../base/BaseImg/BaseImg";
// import {
//     createSystemMessage,
//     createUserMessage,
// } from "../../../helpers/createMessage";
import createMessage from "../../../helpers/createMessage";
import BaseLabel from "../../../base/BaseLabel";
import { useTranslation } from "react-i18next";
import { ReactComponent as RegPhoneIcon } from "../../../images/true/icon-device-confim.svg";

export default function DeviceList() {
    const { Agreement } = useSelector((state) => state.validation.verification);
    const { ClientOffer } = useSelector((state) => state.validation.agreement);
    // const client = useSelector((state) => state.app.client);
    const { t } = useTranslation();
    const name = getFormattedUserName(Agreement[0]?.EnrolledName || "User");
    const refs = useRef([]);
    const dispatch = useDispatch();
    const [updateClicked, setUpdateClicked] = useState("");
    const [selectedAsset, setSeletctedAsset] = useState({});

    const updateSelectedAsset = (asset) => {
        setSeletctedAsset(asset);
    };

    const filterAllAgreementAssets = () => {
        const uniqueDevices = [];
        Agreement.forEach((agreement) => {
            let oldAsset = null;
            agreement.AssetsList.Asset.forEach((asset) => {
                const newAsset = { ...asset, agreement };
                if (["ENROLLED", "REPLACED"].includes(asset.AssetInstance)) {
                    if (oldAsset) {
                        var oldTime = moment(oldAsset.CreatedDate);
                        var newTime = moment(newAsset.CreatedDate);
                        if (newTime.isAfter(oldTime)) oldAsset = newAsset;
                    } else {
                        oldAsset = newAsset;
                    }
                }
            });
            uniqueDevices.push(oldAsset);
        });

        return uniqueDevices.sort((a, b) => a.IMEI - b.IMEI);
    };

    const uniqueAgreementAssets = filterAllAgreementAssets();

    useEffect(() => {
        if (refs.current.length > 1) {
            let intersectionOberverOptions = {
                root: document.querySelector(".devicelist-slider"),
                rootMargin: "0px",
                threshold: 0.85,
            };
            const sliderCallback = (entries, observer) => {
                entries.forEach((entry) => {
                    const { target } = entry;
                    let idx = parseInt(target.getAttribute("slideindex"));
                    if (entry.intersectionRatio >= 0.85) {
                        refs.current[idx] && refs.current[idx].classList.add("highlighted");
                    } else {
                        refs.current[idx] &&
                            refs.current[idx].classList.remove("highlighted");
                    }
                });
            };
            let observer = new IntersectionObserver(
                sliderCallback,
                intersectionOberverOptions
            );
            const deviceSlides = [...document.querySelectorAll(".devicelist-slide")];

            deviceSlides.forEach((section, index) => {
                observer.observe(section);
            });
        }
    }, []);

    // const handleUpdate = (e, imei = "") => {
    //     dispatch(
    //         addJourneyMessages([
    //             createUserMessage("Text", e.target.innerText),
    //             createSystemMessage("CHOOSE_EXCHANGE_OPTIONS", {
    //                 showComponent: "selectExchangeOption",
    //                 selectedimei: imei,
    //             }),
    //         ])
    //     );
    //     setUpdateClicked(imei || "NO_IMEI");
    // };

    return (
        <section className="max-w-2xl mx-auto my-12">
            {/*<div className="text-left my-4 text-default">
        <TextRegular>
          {t("DeviceList.Title", { firstName: name.firstName })}
        </TextRegular>
      </div>
      <h3 className="mb-4 text-default">
        {t("DeviceList.EnroledDevice")}&nbsp;
        <span>({uniqueAgreementAssets.length})</span>
      </h3>*/}
            <div className="devicelist-slider webkit-center flex-grow mb-4">
                {uniqueAgreementAssets.map((asset, i) => {
                    return (
                        <Slide
                            id={asset.AssetId}
                            key={asset.AssetId}
                            agreement={asset.agreement}
                            asset={asset}
                            slideIndex={i + 1}
                            // handleUpdate={handleUpdate}
                            updateClicked={updateClicked}
                            plan={ClientOffer}
                            updateSelectedAsset={updateSelectedAsset}
                            selectedAsset={selectedAsset}
                        ></Slide>
                    );
                })}
            </div>

            {/*<div className="text-center mt-4 text-default">
        <TextMedium>{t("DeviceList.CantFind")}</TextMedium>
      </div>
      <div className="text-center text-default">
        <span
          className={`text-srgreen-tertiary cursor-pointer ${
            updateClicked || selectedAsset.AssetId ? "pointer-events-none" : ""
          }`}
          onClick={handleUpdate}
        >
          {t("DeviceList.UpdateBtn")}
        </span>
      </div>*/}
        </section>
    );
}

const Slide = ({
    agreement,
    asset,
    slideIndex,
    plan,
    //handleUpdate,
    updateClicked,
    updateSelectedAsset,
    selectedAsset,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    // const client = useSelector((state) => state.app.client);
    const currency = "THB";
    const [serviceFees, setServiceFee] = useState("Loading...");
    const {
        interactionData: {
            Interaction: {
                InteractionLineId,
                SessionData: { ClientId, ClientChannelId, ClientName },
            },
        },
    } = useSelector((state) => state.session);

    // async function fetchServiceFee() {
    //     const serviceFeeRes = await dispatch(
    //         getEnrolledServiceFee(
    //             ClientId,
    //             ClientChannelId,
    //             ClientName,
    //             InteractionLineId,
    //             asset.MobileDeviceNumber
    //         )
    //     ).catch((e) => {
    //         setServiceFee("Error in Service Fee");
    //     });
    //     if (isEmpty(serviceFeeRes) || isEmpty(serviceFeeRes.GetServiceFeeResponse))
    //         return;
    //     const serviceFees = {};
    //     serviceFeeRes.GetServiceFeeResponse?.ServiceFees?.forEach((e) => {
    //         serviceFees[e.IncidentType] = e.Fees;
    //     });
    //     setServiceFee(serviceFees);
    // }

    // useEffect(() => {
    //     fetchServiceFee();
    // }, [dispatch]);

    const deviceName = getDeviceName({
        AssetsInformation: asset.AssetsInformation,
        MakeName: asset.Make.Name,
        ModelName: asset.Model.Name,
    });

    const handleProceed = () => {
        dispatch(addJourneyMessages([createMessage("TEXT", "user", `${deviceName}`)]));
        // dispatch(selectAgreementAsset(agreement, { ...asset, serviceFees }));
        dispatch(selectAgreementAsset(agreement, asset));
        updateSelectedAsset(asset);
    };

    function renderFee(type, fee) {
        return (
            <p>
                {type} <TextBold>{`${fee?.CurrencyCode} ${fee?.TotalAmount}`}</TextBold>
            </p>
        );
    }
    const handleMakeModelUpdate = (e) => {
        // handleUpdate(e, asset.IMEI);
    };

    return (
        <article className="devicelist-slide mt-2" slideindex={slideIndex - 1}>
            <div className="border border-gray-400 rounded-2xl p-8 h-full">
                <div className="flex items-center justify-center pb-4 w-2/3 mx-auto">
                    {/*<BaseImg make={asset?.Make?.Name} model={asset?.Model?.Name} />*/}
                    <div style={{width: "70px"}}>
                    <RegPhoneIcon />
                    </div>
                    
                    <div className="flex flex-col pl-3 mr-auto">
                        <BaseLabel className="text-left font-bold leading-none">
                            {deviceName || "Device Unknown"}
                        </BaseLabel>
                        <div className="text-20px leading-none">
                            <TextMedium>
                                {getMaskedIMEI(asset.IMEI)}
                            </TextMedium>
                        </div>

                    </div>
                </div>
                {/*<div className="border border-gray-400 rounded-md overflow-hidden">
                    <table className="w-full text-20px ">
                        <tbody>
                            <tr>
                                <th className="p-2 bg-gray-100" colSpan="2">
                                    {t("DeviceList.CurrentDevice")}
                                </th>
                            </tr>
                            <tr>
                                <td className="bg-white pl-3 pr-1 pt-2 pb-1 w-45% border-none ">
                                    {t("DeviceList.RegisteredName")}
                                </td>
                                <td className="pl-1 pr-1 py-1 border-none align-middl">
                                    {agreement.EnrolledName}
                                </td>
                            </tr>
                            <tr>
                                <td className="bg-white pl-3 pr-1 py-1 w-45% border-none ">
                                    {t("DeviceList.IMEINumber")}
                                </td>
                                <td className="pl-1 pr-1 py-1 border-none">
                                    {asset.IMEI}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>*/}
                <div className="text-center mt-4">
                    {asset.AssetStatus === "ACTV" &&
                        <>
                            {/*(deviceName ? (
                            <BaseButton
                                className="w-40 m-auto"
                                onClick={handleProceed}
                                disabled={
                                    !!selectedAsset.AssetId ||
                                    typeof serviceFees === "string" ||
                                    !!updateClicked
                                }
                                clicked={selectedAsset.AssetId === asset.AssetId}
                                text={t("DeviceList.ProceedBtn")}
                            ></BaseButton>
                            ) : (
                            <BaseButton
                                className="w-40 m-auto"
                                onClick={handleMakeModelUpdate}
                                disabled={!!updateClicked}
                                clicked={updateClicked === selectedAsset.IMEI}
                                text={t("DeviceList.UpdateBtn")}
                            ></BaseButton>
                            ))*/}
                            <BaseButton
                                className="min-w-40 m-auto"
                                onClick={handleProceed}
                                disabled={
                                    !!selectedAsset.AssetId ||
                                    !!updateClicked
                                }
                                clicked={selectedAsset.AssetId === asset.AssetId}
                                text={t("DeviceList.ProceedBtn")}
                            ></BaseButton>
                        </>
                    }
                </div>
            </div>
        </article>
    );
};
