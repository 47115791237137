import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import classNames from "classnames";
import PropTypes from "prop-types";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseCheckbox from "../../../base/BaseCheckbox/BaseCheckbox";
import { TextBold, TextRegular } from "../../../base/BaseText";
import BaseTextarea from "../../../base/BaseTextarea/BaseTextarea";

import { submitTroubleshootAnswer } from "../state/operators/troubleshootingOperator";
import { handleBackToMainMenu } from "../state/operators/deviceConfirmationOperators";
import tsConfig from "./troubleshootConfig";
import Damage from "./Damage";
import { DEVICE } from "../../../helpers/constants";

import { ReactComponent as TroubleshootIcon } from "../../../images/true/triage/troubleshoot-power.svg";
import { saveTroubleshootAnswer, submitTroubleshootAnswers } from "../state/operators/troubleshootingOperator";
import { addJourneyMessages, sleep } from "../../../helpers/addJourneyMessages";
import createMessage from "../../../helpers/createMessage";

const TroubleshootPower = ({ perilType, inWarranty }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [clicked, setClicked] = useState("");
  const [triage, setTriage] = useState(null);
  const [status, setStatus] = useState(null);
  const [isAgreed, setIsAgreed] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [comments, setComments] = useState("");
  const { isDeviceAppleSamsung = false, selectedAsset } = useSelector(
    (state) => state.claim?.deviceConfirmation
  );
  const make = selectedAsset?.Make?.Name || "Other";

  const handleYesClick = async () => {
    setClicked("Yes");
    setDisabled(true);
    handleButtonClick(true)
  }

  const handleNoClick = async () => {
    setClicked("No");
    setDisabled(true);
    handleButtonClick(false)
  }

  const handleButtonClick = async (val) => {
    await dispatch(saveTroubleshootAnswer("PowerOn", val, "My device is able to power on.."));
    
    await dispatch(
      addJourneyMessages([
        createMessage("TEXT", "user", {
          key: val ? "YesButton" : "NoButton",
        })
      ])
    );

    const result = await dispatch(submitTroubleshootAnswers());

    if (result) {
      dispatch(
        addJourneyMessages([
          createMessage("TROUBLESHOOT", "system", {
            showComponent: "TroubleshootOthers",
            perilType,
            inWarranty
          }),
        ])
      );
    }
  };

  return (
    <>
      <div>
        <BaseCard>
          <TroubleshootIcon className="app-BaseIcon-center" />
          <BaseLabel>
            <Trans
              i18nKey="Troubleshoot.ScreenRepair.PowerOn.Title"
            />
          </BaseLabel>
          <BaseButtonGroup>
            <BaseButton
              name="No"
              value="No"
              text={t("NoButton")}
              onClick={handleNoClick}
              disabled={disabled}
              clicked={clicked === "No"}
              focused
              className="bg-white border-app-primary text-app-primary"
            />
            <BaseButton
              name="Yes"
              value="Yes"
              text={t("YesButton")}
              onClick={handleYesClick}
              disabled={disabled}
              clicked={clicked === "Yes"}
              focused
              className="bg-white border-app-primary text-app-primary"
            />
          </BaseButtonGroup>
        </BaseCard>
      </div>
    </>
  );
};

TroubleshootPower.defaultProps = {
  inWarranty: false,
};

TroubleshootPower.propTypes = {
  perilType: PropTypes.string.isRequired,
  inWarranty: PropTypes.bool,
};

export default TroubleshootPower;
