import React, { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseInformationBox from "../../../base/BaseInformationBox";

import { ReactComponent as NotesIcon } from "../../../images/true/icon-notes.svg";
import { importantNoteLoaded } from "../state/operators/importantNoteOperator";

const ImportantNote = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isDeviceAppleSamsung = "" } = useSelector(
    (state) => state.claim?.deviceConfirmation
  );

  useEffect(() => {
    dispatch(importantNoteLoaded());
  }, []);

  // const backUpTextContent = isDeviceAppleSamsung
  //   ? "ImportantNote.Content2.BackupDeviceOnlyAppleAndSamsung"
  //   : "ImportantNote.Content2.BackupDevice";
  const backUpTextContent = "ImportantNote.Content2.BackupDevice";

  return (
    <BaseCard>
      {/* <BaseIcon icon={NotesIcon} name="Important Note" center /> */}
      <NotesIcon className="app-BaseIcon-center" />
      <BaseLabel className="font-bold leading-none">{t("ImportantNote.Title")}</BaseLabel>
      <BaseInformationBox>
        <BaseLabel className="text-left text-20px text-app-nova leading-none">
          <ul className="list-outside list-disc pl-8">
            <li>
              <div className="mb-5">
                <Trans i18nKey="ImportantNote.Content2.PresentDevice">
                  You will need to present your device to our courier who will
                  verify (i) your identity and (ii) the IMEI of your device at
                  the time of pick-up and return.
                </Trans>
              </div>
            </li>
            <li>
              <div className="mb-5">
                <Trans i18nKey={backUpTextContent} />

                {/*isDeviceAppleSamsung
                  ?
                  (<>
                    <ol className="list-outside list-decimal pl-8 pr-4 my-5">
                      <li>{t("ImportantNote.Content2.BackupDeviceOnlyAppleAndSamsungList1")}</li>
                      <li>{t("ImportantNote.Content2.BackupDeviceOnlyAppleAndSamsungList2")}</li>
                      <li>{t("ImportantNote.Content2.BackupDeviceOnlyAppleAndSamsungList3")}</li>
                      <li>{t("ImportantNote.Content2.BackupDeviceOnlyAppleAndSamsungList4")}</li>
                    </ol>
                  </>)
                  :
                  (<>
                    <ol className="list-outside list-decimal pl-8 pr-4 my-5">
                      <li>{t("ImportantNote.Content2.BackUpDeviceList1")}</li>
                      <li>{t("ImportantNote.Content2.BackUpDeviceList2")}</li>
                      <li>{t("ImportantNote.Content2.BackUpDeviceList3")}</li>
                    </ol>
                  </>)
                  */}
                  <>
                  <ol className="list-outside list-decimal pl-8 pr-4 my-5">
                      <li>{t("ImportantNote.Content2.BackUpDeviceList1")}</li>
                      <li>{t("ImportantNote.Content2.BackUpDeviceList2")}</li>
                      <li>{t("ImportantNote.Content2.BackUpDeviceList3")}</li>
                    </ol>
                  </>
                <div className="mb-5">
                  <Trans i18nKey="ImportantNote.Content2.BackupDeviceNote">
                    Note: Any remaining content will be deleted in order to perform the Repair service.
                  </Trans>
                </div>
              </div>
            </li>
          </ul>
        </BaseLabel>
      </BaseInformationBox>
    </BaseCard>
  );
};

export default ImportantNote;
