import React from "react";
import PropTypes from "prop-types";
import PrepareRequirements from "./PrepareRequirements";
import ConfirmRegisteredDevice from "./ConfirmRegisteredDevice";
import ChooseEnrolledDevice from "./ChooseEnrolledDevice";
import DeviceNotEligible from "./DeviceNotEligible";
import VerifyImei from "./VerifyImei";
import DeviceHold from "./DeviceHold";
import DisplayDeviceList from "./DisplayDeviceList";
import WarrantyConfirmation from "./WarrantyConfirmation";
import WhyDeviceNotEligible from "./WhyDeviceNotEligible";
import DeviceList from "./DeviceList";
import ExistingDPRequest from "./ExistingDPRequest";

const View = ({ showComponent }) => {
  return (
    <>
      {showComponent === "preparation" && <PrepareRequirements />}
      {showComponent === "confirmDevice" && <ConfirmRegisteredDevice />}
      {showComponent === "displayDevices" && <DisplayDeviceList />}
      {showComponent === "chooseEnrolledDevice" && <ChooseEnrolledDevice />}
      {showComponent === "deviceNotEligible" && <DeviceNotEligible />}
      {showComponent === "deviceNotEligibleAfterTroubleshoot" && (
        <DeviceNotEligible type="Troubleshoot" showWhyNotEligible={false} />
      )}
      {showComponent === "verifyImei" && <VerifyImei />}
      {showComponent === "deviceHold" && <DeviceHold />}
      {showComponent === "warrantyConfirmation" && <WarrantyConfirmation />}
      {showComponent === "whyDeviceNotEligible" && <WhyDeviceNotEligible />}
      {showComponent === "deviceList" && <DeviceList />}
      {showComponent === "existingDPRequest" && <ExistingDPRequest />}
    </>
  );
};

View.propTypes = {
  showComponent: PropTypes.string.isRequired,
};

export default View;
