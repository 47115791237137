import React, { useState } from "react";
import { TextBold, TextMedium, TextRegular } from "../../../base/BaseText";
import MapContainer from "./MapContainer";
import { useTranslation, Trans } from "react-i18next";

const RepairCenter = ({ asp, saveASP, disabled }) => {
  const [showMap, setShowMap] = useState(false);

  const toggleMapVisibility = () => {
    setShowMap(!showMap);
  };

  const handleASPSelection = (e) => {
    console.log("saveASP ASP", asp)
    saveASP(asp);
  };

  return (
    <div className="p-4 border border-t-0 border-r-0 border-l-0">
      <div className="flex align-center gap-4">
        <div>
          <input
            type="radio"
            name="radio"
            value={asp.aspId}
            disabled={disabled}
            onChange={(e) => handleASPSelection(e)}
          />
        </div>

        <div className="inline-block text-20px leading-none">
          <TextBold className="true_current-form-asp-name">
            {`${asp.SERVICE_PROVIDER_NAME} \(${asp.SERVICE_PROVIDER_LOCATION}\)`}
          </TextBold>
        </div>
        <div
          className={`text-20px leading-none ml-auto inline-block text-app-primary underline cursor-pointer min ${disabled ? "pointer-events-none cursor-not-allowed" : ""}`}
          onClick={toggleMapVisibility}
          disabled={disabled}
          style={{minWidth: "fit-content"}}
        >
          <Trans
            i18nKey={`${
              showMap
                ? "WalkIn.RepairCenter.hideMap"
                : "WalkIn.RepairCenter.mapButton"
            }`}
          >
            <TextMedium></TextMedium>
          </Trans>
        </div>
      </div>
      <div className="text-20px leading-none mt-4 ml-8">
        {/* <TextRegular>{asp.SERVICE_PROVIDER_NAME}</TextRegular> */}
        <TextRegular>{`${asp.ADDRESS_LINE_1} ${asp.ADDRESS_LINE_2 || ""} ${asp.ADDRESS_LINE_3 || ""} ${asp.CITY_NAME} ${asp.POSTAL_CODE} ${asp.STATE_PROVINCE_NAME || ""}`}</TextRegular>
      </div>
      {showMap && (
        <div className="mt-3 w-full">
          <MapContainer latitude={asp.LATITUDE} longitude={asp.LONGITUDE} />
        </div>
      )}
    </div>
  );
};

export default RepairCenter;
