import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CloseIcon from "../../images/icon-close-dark.svg";
import { isEmpty } from "../../helpers/formatUtils";

const BaseNestedPopup = ({ show, title, onClose, children, global, className, isTitleCentered = true, closeText }) => {
  const extraClasses = !isEmpty(className) ? className.split(" ") : [];

  return (
    <div
      className={classNames(
        "app-BaseNestedPopup",
        { "app-BaseNestedPopup-hidden": !show },
        { "app-BaseNestedPopup-global": global }
      )}
    >
      <div
      className={classNames(
        "app-BaseNestedPopup-content",
        ...extraClasses
      )}
      >
        <div
          className="app-BaseNestedPopup-content__icon-close right-1 top-1"
          onClick={onClose}
        >
          {closeText ? <span className="text-22px">{closeText}</span>:<img src={CloseIcon} alt="Close Popup" />}
          
        </div>
        {title && <div className={`app-BaseNestedPopup-content__title ${isTitleCentered ? 'text-center' : 'text-left'}`}>{title}</div>}
        {children}
      </div>
    </div>
  );
};

BaseNestedPopup.defaultProps = {
  show: false,
  global: false,
};

BaseNestedPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  global: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default BaseNestedPopup;
