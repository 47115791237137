import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseInput from "../../../base/BaseInput/BaseInput";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseDropdown from "../../../base/BaseDropdown/BaseDropdown";

import { createUserInquiry } from "../state/operators/generalEnquiryOperator";
import { ReactComponent as IdCardIcon } from "../../../images/icon-id-card.svg";

const CaptureUserInquiry = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [inquiryType, setInquiryType] = useState("");

  const [selected, setSelected] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const handleChange = (val, element) => {
    if (element === "name") {
      setName(val.replace(/[^A-Za-z ]/g, ""));
    }

    if (element === "contactNumber") {
      setContactNumber(val.replace(/[^0-9]/g, ""));
    }
  };

  const handleDropdownChange = (val) => {
    setInquiryType(val);
  };

  const handleSubmit = () => {
    setSelected("Submit");
    setDisabled(true);
    dispatch(
      createUserInquiry({
        name,
        mdn: contactNumber,
        inquiryType,
        inquiryDesc: `${inquiryType}, Contact Number: ${contactNumber}`,
      })
    );
  };

  const handleCancel = () => {
    setSelected("Cancel");
    setDisabled(true);
  };

  const shouldntSubmit = (() => {
    if (name.length === 0 || contactNumber.length < 8 || !inquiryType) {
      return true;
    }
    return false;
  })();

  const dropdownOptions = ["General Inquiry", "Service Request", "Request Call back"];

  return (
    <BaseCard>
      {/* <BaseIcon icon={VerifyPhoneIcon} name="Choose device icon" center /> */}
      <IdCardIcon className="app-BaseIcon-center" />
      <BaseLabel className="font-bold">
        <Trans i18nKey="UserInquiry.Title" />
      </BaseLabel>
      <BaseInput
        label={t("UserInquiry.Input.FullName")}
        placeholder={t("UserInquiry.Input.FullName")}
        value={name}
        onChange={(val) => handleChange(val, "name")}
        maxLength={25}
        disabled={disabled}
        full
      />
      <BaseInput
        label={t("UserInquiry.Input.ContactNumber")}
        placeholder={t("UserInquiry.Input.ContactNumber")}
        value={contactNumber}
        onChange={(val) => handleChange(val, "contactNumber")}
        maxLength={10}
        disabled={disabled}
        full
      />
      <BaseDropdown
        label={t("UserInquiry.Input.InquiryType")}
        value={inquiryType}
        onChange={handleDropdownChange}
        options={dropdownOptions}
        disabled={disabled}
        pureArray
      />
      <BaseButtonGroup>
        <BaseButton
          className="bg-app-nova border-app-nova"
          text={t("SubmitButton")}
          onClick={handleSubmit}
          clicked={selected === "Submit"}
          disabled={shouldntSubmit}
        />
        <BaseButton
          text={t("CancelButton")}
          onClick={handleCancel}
          clicked={selected === "Cancel"}
          disabled={disabled}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default CaptureUserInquiry;
