import { callGetBusinessHoursApi } from "./../reducers/sessionReducer";
import Config from "../modules/Appsync/Config";

const getBusinessHours = () => {
    const clientName = Config.App.Client;
    const appName = 'SUR';
    const product = 'DP';
    const path = `/corewrapper/v1/onlinebusinesshours/get?ClientName=${clientName}&ApplicationName=${appName}&Product=${product}`;

    return {
        type: callGetBusinessHoursApi.toString(),
        meta: {
            async: true,
            blocking: true,
            path,
            method: "GET",
        },
    }
};

export default getBusinessHours;