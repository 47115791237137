import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import classNames from "classnames";
import PropTypes from "prop-types";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseCheckbox from "../../../base/BaseCheckbox/BaseCheckbox";
import BaseLink from "../../../base/BaseLink/BaseLink";
import { TextBold, TextRegular, TextMedium } from "../../../base/BaseText";
import BaseTextarea from "../../../base/BaseTextarea/BaseTextarea";

import { submitTroubleshootAnswer } from "../state/operators/troubleshootingOperator";
import { handleBackToMainMenu } from "../state/operators/deviceConfirmationOperators";
import tsConfig from "./troubleshootConfig";
import Damage from "./Damage";
import { DEVICE } from "../../../helpers/constants";

import { ReactComponent as TroubleshootIcon } from "../../../images/true/triage/troubleshoot-others.svg";
import { saveTroubleshootAnswer, submitTroubleshootAnswers, submitTroubleshootOthers, saveIWComments } from "../state/operators/troubleshootingOperator";
import { addJourneyMessages, sleep } from "../../../helpers/addJourneyMessages";
import createMessage from "../../../helpers/createMessage";

const TroubleshootOthers = ({ perilType, inWarranty }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const checkboxOptions = t("Troubleshoot.Checklist", { returnObjects: true });
  const [disabled, setDisabled] = useState(false);
  const [triage, setTriage] = useState(null);
  const [status, setStatus] = useState(null);
  const [isAgreed, setIsAgreed] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [comments, setComments] = useState("");
  const { isDeviceAppleSamsung = false, selectedAsset } = useSelector(
    (state) => state.claim?.deviceConfirmation
  );
  const make = selectedAsset?.Make?.Name || "Other";

  const [showTextarea, setShowTextarea] = useState(false);

  function getCheckListValues() {
    return checkboxOptions.map((e, index) => ({ value: e.value, checked: false, displayText: e.displayText }))
  }

  const onTextareaChange = () => {
    //console.log("TEXTAREA VAL:", e.target)
    //setComments(e.target.value)
  }

  const [checklistValues, setChecklistValues] = useState(getCheckListValues());

  const handleOnCheckListClick = (id) => {
    console.log("CHANGE")
    const newList = [...checklistValues]
    const newElement = { ...newList[id] }
    newElement.checked = !newList[id].checked
    newList[id] = newElement
    setChecklistValues([...newList])
  }

  const handleShowTextArea = () => {
    setShowTextarea(!showTextarea);
    setComments("");
  }

  const handleConfirm = async () => {
    setDisabled(true);
    await dispatch(
      addJourneyMessages([
        createMessage("TEXT", "user", {
          key: "ConfirmButton",
        })
      ])
    );
    
    let Answer = checklistValues.filter(e => e.checked).map(e => e.displayText).join("|");

    if (showTextarea && comments && comments.trim().length) {
      Answer = `${Answer}|${(comments.trim())}`
    }

    console.log("ANSWER", Answer)
    await dispatch(saveIWComments(Answer));
    await dispatch(submitTroubleshootOthers());
  }

  return (
    <>
      <div>
        <BaseCard>
          <TroubleshootIcon className="app-BaseIcon-center" />
          <BaseLabel className="font-bold mb-0 leading-none">
            <Trans
              i18nKey="Troubleshoot.ChecklistTitle"
            />
          </BaseLabel>
          <BaseLabel>
            <Trans
              i18nKey="Troubleshoot.ChecklistSubTitle"
            />
          </BaseLabel>
          <div className={`pl-6 pr-6 my-4 ${disabled ? "pointer-events-none cursor-not-allowed" : "" }`}>
            {checklistValues.map((e, i) => (
              <BaseCheckbox
                key={`troubleshoot-others-${i}`}
                id={`troubleshootOthersCB${i}`}
                className="px-3 pt-0 pb-2 w-full text-20px leading-none flex flex-row-reverse"
                value={e.value}
                onChange={() => { handleOnCheckListClick(i)}}
                label={e.displayText}
                reverse={true}
              />
            ))}
          </div>
          <div className="text-center">
            <BaseLink
              className="text-center my-3 text-22px underline text-app-primary"
              disabled={disabled}
              onClick={(handleShowTextArea)}
            >
              <TextMedium>{t("Troubleshoot.ChecklistOtherIssuesButton")}</TextMedium>
            </BaseLink>
            {showTextarea && <div>
              <BaseTextarea
                className="mt-2 text-16px leading-none"
                value={comments}
                onChange={setComments}
                disabled={disabled}
              />
            </div>}
            
          </div>

          <BaseButtonGroup>
            <BaseButton
              name="Submit"
              text={t("ConfirmButton")}
              onClick={handleConfirm}
              disabled={disabled}
              clicked={disabled}
              focused
              className="mx-auto"
            />
          </BaseButtonGroup>
        </BaseCard>
      </div>
    </>
  );
};

TroubleshootOthers.defaultProps = {
  inWarranty: false,
};

TroubleshootOthers.propTypes = {
  perilType: PropTypes.string.isRequired,
  inWarranty: PropTypes.bool,
};

export default TroubleshootOthers;
