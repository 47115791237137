import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { sanitize } from "../../helpers/formatUtils";

const BaseTextarea = ({
  value,
  onChange,
  placeholder,
  className,
  disabled,
  rows,
  ...restProps
}) => {
  const extraClasses = className ? className.split(" ") : [];

  const handleInputChange = (e) => {
    const sanitnizedValue = sanitize(e.target.value);
    onChange(sanitnizedValue, e);
  };

  return (
    <div
      className={classNames(
        "app-BaseTextarea",
        { "opacity-50 table-header-grey border-srgray-senary": disabled },
        ...extraClasses
      )}
    >
      <textarea
        className="app-BaseTextarea__input"
        rows={rows}
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        disabled={disabled}
        {...restProps}
      />
    </div>
  );
};

BaseTextarea.defaultProps = {
  disabled: false,
  rows: 3
};

BaseTextarea.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
};

export default BaseTextarea;
