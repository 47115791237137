import React from "react";
import {ReactComponent as AskUsIcon} from "./../../images/new-ask-us.svg";

const WelcomeToService = () => {
  return (
    <>
      <div className="app__welcomeToSupport-container">
        <div><AskUsIcon /></div>
        <div className="app__welcomeToSupport-message">
          Welcome to the Screen <br />Repair and Battery <br />Replacement Service 
        </div>
      </div>
    </>
  );
};

export default WelcomeToService;
