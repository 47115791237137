import React, { useState } from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseInput from "../../../base/BaseInput/BaseInput";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { TextRegular } from "../../../base/BaseText";

import ContactConfig from "./contactConfirmationConfig";
import {
  submitChangesInContactDetails,
} from "../state/operators/contactsOperator";
import { isEmpty } from "../../../helpers/formatUtils";
import { REGEX } from "../../../helpers/constants";


const ChangeContactDetails = ({ code }) => {
  const isMobile = code === "ChangeMobileNumber";
  const [disabled, setDisabled] = useState(false);
  const [selected, setSelected] = useState("");
  const [contact, setContact] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ContactIcon = ContactConfig[code].icon;

  const handleSubmit = (selectedVal) => {
    setSelected(selectedVal);
    setDisabled(true);
    dispatch(submitChangesInContactDetails(contact, isMobile));
  };

  const handleCancel = (selectedVal) => {
    setSelected(selectedVal);
    setDisabled(true);
    // TODO cancel
  };

  const handleChangeContact = (input) => {
    if (isMobile && isNaN(input)) {
      return;
    } else {
      setContact(input);
    }
  };

  const isInputValid = () => {
    if (isMobile) {
      return contact.length >= 8;
    } else {
      return REGEX.Email.test(contact);
    }
  };

  const shouldntSubmit = disabled || isEmpty(contact) || !isInputValid();
  
  return (
    <BaseCard>
      {ContactIcon}
      <BaseLabel>
        <Trans i18nKey={`Contacts.${code}.Title`}>
          <TextRegular>
            {ContactConfig[code].title}
          </TextRegular>
        </Trans>
      </BaseLabel>
      <BaseInput
        label={t(`Contacts.${code}.Label`)}
        placeholder={t(`Contacts.${code}.Placeholder`)}
        value={contact}
        onChange={(val) => handleChangeContact(val.trim())}
        maxLength={isMobile ? 10 : 100}
        full
        autoFocus
        onEnterPress={shouldntSubmit ? () => { } : handleSubmit}
      />
      <BaseButtonGroup>
        <BaseButton
          className="bg-white border-app-primary text-app-primary"
          text={t("SubmitButton")}
          disabled={shouldntSubmit}
          clicked={selected === 'Submit'}
          onClick={() => handleSubmit('Submit')}
          focused={true}
        />
        <BaseButton
          text={t("CancelButton")}
          disabled={true}
          clicked={selected === 'Cancel'}
          onClick={() => handleCancel('Cancel')}
          focused={true}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

ChangeContactDetails.defaultProps = {
  code: "ChangeMobileNumber",
};

ChangeContactDetails.propTypes = {
  code: PropTypes.string.isRequired,
};

export default ChangeContactDetails;
