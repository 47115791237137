import React from 'react'
import { TextMedium } from '../../../base/BaseText';

function Banner({title, description}) {

  
    return (
      <article className="lg:h-600px relative overflow-hidden bg-banner h-375px">
        <div className="h-full">
          <div
            className="h-full hidden lg:flex flex-row justify-end items-center bg-prepare-my-device-banner bg-cover bg-no-repeat"
          >
            <div className="inline-block w-full max-w-58pct relative text-center lg:text-left text-srgray-secondary z-10">
              <div className="ml-auto mr-0 my-20 px-178px">
                <h1 className="px-8 lg:px-0 leading-none text-66px text-rblack text-66px">
                  <TextMedium>{title}</TextMedium>
                </h1>
                <h1 className="px-8 lg:px-0 pt-8 leading-none text-36px text-rblack text-38px">
                  <TextMedium>{description}</TextMedium>
                </h1>
              </div>
            </div>
          </div>
  
          <div className="flex flex-col lg:hidden">
            <div className="inline-block w-full text-left text-srgray-secondary">
              <div
                className="h-185px bg-no-repeat bg-cover bg-prepare-my-device-banner bg-cover bg-no-repeat"
              >
              </div>
              <div className="h-139px my-6 md:px-7r">
                  <h1 className="px-8 lg:px-0 pt-8 lg:pt-16 text-raisin-black leading-none text-30px">
                    <TextMedium>{title}</TextMedium>
                  </h1>
                  <h1 className="px-8 lg:px-0 pt-8 lg:pt-16 text-raisin-black leading-none text-24px">
                    <TextMedium>{description}</TextMedium>
                  </h1>
              </div>
            </div>
          </div>
        </div>
      </article>
    )
}

export default Banner