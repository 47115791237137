import { INCIDENT_SCREEN_REPAIR } from "../../../../components/EnquiryOptions/Constants";
import { isEmpty } from "../../../../helpers/formatUtils";
import {
  saveDeviceMakeAndModel,
  callVerifyImeiApi,
  saveIsDeviceAppleSamsung
} from "../reducers/deviceConfirmationReducer";
import { callGetFulFillmentApi, callProcessIncidentApi, callSetFulFillmentApi } from "../reducers/termsAndConditionsReducer";

export const setDeviceMakeAndModel = (deviceData, isAppleSamsung) => (dispatch) => {
  dispatch({ type: saveDeviceMakeAndModel.toString(), payload: deviceData });
  dispatch({ type: saveIsDeviceAppleSamsung.toString(), payload: isAppleSamsung });
};

export const verifyImeiApi = ({
  cacheId,
  assetInstance,
  imei,
  imeiVerificationFailedAttempt = 0,
}) => ({
  type: callVerifyImeiApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/incidentidentification/v1/verifyimei",
    method: "POST",
    body: {
      VerifyIMEIParameters: {
        IMEI: imei,
        IMEIVerificationFailedAttempt: imeiVerificationFailedAttempt,
        AssetInstance: assetInstance,
        SessionId: cacheId,
      },
    },
  },
});

export const processIncidentApi = (
  CacheId,
  CustomerCaseId,
  ServiceRequestId,
  caseType,
  assetCatalogId,
  imei,
  screenRepairQa,
  failureDescriptiveText = ""
) => ({
  type: callProcessIncidentApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: `/incidentidentification/v1/incident/create`,
    method: "POST",
    body: {
      ProcessIncidentParameters: {
        SessionId: CacheId,
        CustomerCaseId: CustomerCaseId,
        ServiceRequestId: ServiceRequestId,
        Incident: {
          FailureDescriptiveText: failureDescriptiveText,
          IncidentType: caseType,
        },
        AssetDetails: !isEmpty(imei) ? {
          AssetCatalogId: assetCatalogId,
          SerialNumber: imei,
        } : undefined,
        ScreenRepairQA: screenRepairQa,
      },
    },
  },
});

export const getFulFillmentApi = (
  CacheId,
  ServiceRequestId,
) => ({
  type: callGetFulFillmentApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: `/physicalassetfulfillment/v1/getfulfillmentoptions`,
    method: "POST",
    body: {
      GetFulfillmentOptionParameters: {
        SessionId: CacheId,
        ServiceRequestId: ServiceRequestId,
      },
    },
  },
});

export const setFulFillmentApi = (
  repairOption,
  CacheId,
  ServiceRequestId,
) => {
  // const fulfillmentOption = caseType === INCIDENT_SCREEN_REPAIR ? 'SCRNREP': 'PUR'
  return {
    type: callSetFulFillmentApi.toString(),
    meta: {
      async: true,
      blocking: true,
      path: `/physicalassetfulfillment/v1/fulfillmentoptions`,
      method: "POST",
      body: {
        SetFulfillmentOptionParameters: {
          SessionId: CacheId,
          FulfillmentOption: repairOption,
          ServiceRequestId: ServiceRequestId,
        }
      },
    },
  }
};
