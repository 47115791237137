import { combineReducers } from "@reduxjs/toolkit";
import deviceConfirmationReducer from "./deviceConfirmationReducer";
import scheduleReducer from "./scheduleReducer";
import troubleshootingReducer from "./troubleshootingReducer";
import termsAndConditionsReducer from "./termsAndConditionsReducer";
import paymentReducer from "./paymentReducer";
import contactsReducer from "./contactsReducer";
import generalEnquiryReducer from "./generalEnquiryReducer";
import npsReducer from "./npsReducer";
import walkInReducer from "./walkInReducer";

export default combineReducers({
  deviceConfirmation: deviceConfirmationReducer,
  schedule: scheduleReducer,
  troubleshoot: troubleshootingReducer,
  termsAndConditions: termsAndConditionsReducer,
  payment: paymentReducer,
  contacts: contactsReducer,
  generalEnquiry: generalEnquiryReducer,
  survey: npsReducer,
  walkIn: walkInReducer,
});
