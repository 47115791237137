import React from "react";
import PropTypes from "prop-types";
import AgentConnected from "./AgentConnected";
import AgentDisconnected from "./AgentDisconnected";
import NoAgentsAvailable from "./NoAgentsAvailable";
import AgentsBusy from "./AgentsBusy";
import ChatUnresolved from "./ChatUnresolved";
import TechnicalIssue from "./TechnicalIssue";
import UserDisconnected from "./UserDisconnected";

const View = ({ showComponent }) => {
  return (
    <>
      {showComponent === "AgentConnected" && <AgentConnected />}
      {showComponent === "AgentDisconnected" && <AgentDisconnected />}
      {showComponent === "NoAgentsAvailable" && <NoAgentsAvailable />}
      {showComponent === "AgentsBusy" && <AgentsBusy />}
      {showComponent === "TechnicalIssue" && <TechnicalIssue />}
      {showComponent === "UserDisconnected" && <UserDisconnected />}
      {showComponent === "ChatUnresolved" && <ChatUnresolved />}
    </>
  );
};

View.propTypes = {
  showComponent: PropTypes.string.isRequired
};

export default View;
