import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import {
  resetReduxStore,
  toggleFlowWindowVisibility,
} from "../../actions/flowWindow";
import Flow from "../../container/Flow";

//import starhubLogo from "../../images/starhub-logo.svg";
import trueLogo from "../../images/true/true-logo.svg";
import chatIcon from "../../images/icon-ask-us.svg";

import Footer from "./Footer";
import HomeBody from "./HomeBody";
import { showMaintenanceText } from "../../helpers/dateUtils";
import { Trans, useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { setLocale } from "../../reducers/journeyMessagesReducer";

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [language, setLanguage] = useState(sessionStorage.getItem("language") || "th-TH");
  const showFlowWindow = useSelector(
    (state) => state.journeyMessages.showFlowWindow
  );

  const onToggleFlowWindow = () => {
    dispatch(toggleFlowWindowVisibility(!showFlowWindow));
    dispatch(resetReduxStore());
  };

  const onLanguageToggle = (e) => {
    e.preventDefault();
    let chosenLanguage = e.target.name;
    if (chosenLanguage !== language) {
      setLanguage(chosenLanguage);
      i18n.changeLanguage(chosenLanguage);
      sessionStorage.setItem("language", chosenLanguage);
      dispatch({
        type: setLocale.toString(),
        payload: chosenLanguage,
      });
    }
  }

  return (
    <Fragment>
      <div className="truerepair__home">
        <header className="box-border flex bg-white p-2">
          <div className="w-full sm:w-4/5 sm:mx-auto">
            <div className="flex justify-center">
            <img className="py-0 inline-block my-5 mr-auto" src={trueLogo} alt="True Logo" style={{height: "50px"}}/>
            <div className="my-auto pr-4">
              <a onClick={onLanguageToggle} href="#" name="th-TH" className={`inline-block text-24px leading-none align-middle mr-4 ${language === "th-TH" ? "text-app-primary font-bold" : "text-black"}`}>ไทย</a>
              <span className="text-24px leading-none align-middle">|</span>
              <a onClick={onLanguageToggle} href="#" name="en-US" className={`inline-block text-24px leading-none align-middle ml-4 ${language === "en-US" ? "text-app-primary font-bold" : "text-black"}`}>English</a>
            </div>
            </div>
          </div>
        </header>
        {showMaintenanceText() && (
            <div>
            <marquee className="pt-2 font-bold text-3xl text-green-500">
              Dear customers, we will be performing our scheduled maintenance.
            </marquee>
          </div>
        )}
        <div
          className={classNames({
            "hidden lg:block xl:block": showFlowWindow,
          })}
        >
          <HomeBody onGetStarted={onToggleFlowWindow} language={language} />
          <Footer />
        </div>
      </div>

      {showFlowWindow ? (
        <Flow onToggleFlowWindow={onToggleFlowWindow} />
      ) : (<></>)}
    </Fragment>
  );
};

export default Home;
