import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  selectedStore: {},
  selectedAppointmentDate: "",
  selectedAppointmentSlot: "",
  stores: [],
  appointments: [],
  updateAppointment: {},
};

const removeStoreDuplicates = (payload = {ASPStoresResponse: []}) => {
  const storeList = payload.ASPStoresResponse;
  const newASPStoreList = storeList.length ? storeList.filter((ASPStore, index) => 
    storeList.findIndex((store) => 
        ASPStore.SERVICE_PROVIDER_NAME === store.SERVICE_PROVIDER_NAME &&
        ASPStore.SERVICE_PROVIDER_LOCATION === store.SERVICE_PROVIDER_LOCATION &&
        ASPStore.ADDRESS_LINE_1 === store.ADDRESS_LINE_1 &&
        ASPStore.LATITUDE === store.LATITUDE &&
        ASPStore.LONGITUDE === store.LONGITUDE
      ) === index
  ) : [];
  return {ASPStoresResponse: newASPStoreList}
}

const walkInReducer = createSlice({
  name: "walkIn",
  initialState,
  reducers: {
    resetStore() {
      return initialState;
    },
    setSelectedStore(state, action) {
      state.selectedStore = action.payload;
    },
    setSelectedAppointmentDateTime(state, action) {
      state.selectedAppointmentDate = action.payload.date;
      state.selectedAppointmentSlot = action.payload.slot;
    },

    callGetStoresApi(state, action) {
      state.stores = action.payload;
    },
    callGetStoresApiSuccess(state, action) {
      state.stores = removeStoreDuplicates(action.payload);
    },
    callGetStoresApiFailure(state, action) {
      state.error = action.payload;
    },
    callGetAppointmentsApi(state, action) {
      state.appointments = action.payload;
    },
    callGetAppointmentsApiSuccess(state, action) {
      state.appointments = action.payload;
    },
    callGetAppointmentsApiFailure(state, action) {
      state.error = action.payload;
    },
    callUpdateAppointmentApi(state, action) {
      state.updateAppointment = action.payload;
    },
    callUpdateAppointmentApiSuccess(state, action) {
      state.updateAppointment = action.payload;
    },
    callUpdateAppointmentApiFailure(state, action) {
      state.error = action.payload;
    },
  },
});

const { actions, reducer } = walkInReducer;
export const {
  resetStore,
  setSelectedStore,
  setSelectedAppointmentDateTime,
  callGetStoresApi,
  callGetAppointmentsApi,
  callUpdateAppointmentApi,
} = actions;
export default reducer;
