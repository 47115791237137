import React, { Fragment, useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createClient } from 'contentful';
import Loader from "../../../components/Loader/Loader";
import trueLogo from "../../../images/true/true-logo.svg";

import Footer from "../Footer";
import i18n from "../../../i18n";
import { setLocale } from "../../../reducers/journeyMessagesReducer";
import Body from "../../../components/TermsAndConditions/Body";

const TermsAndConditions = () => {
  const dispatch = useDispatch();
  const [language, setLanguage] = useState(sessionStorage.getItem("language") || "th-TH");
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(null)

  const onLanguageToggle = (e) => {
    e.preventDefault();
    let chosenLanguage = e.target.name;
    if (chosenLanguage !== language) {
      setLanguage(chosenLanguage);
      i18n.changeLanguage(chosenLanguage);
      sessionStorage.setItem("language", chosenLanguage);
      dispatch({
        type: setLocale.toString(),
        payload: chosenLanguage,
      });
    }
  }

  useEffect(() => {
    setIsLoading(true)
    const client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    });
    client.getEntry('vkAJ4DsDpZVQrkYQQjslF', { locale: language }).then((response) => {
      console.log(response.fields)
      setData(response.fields)
      setIsLoading(false)
    });
  }, [language]);


  return (
  isLoading ? (<Loader/>) :(
    <Fragment>
      <div className="truerepair__home h-screen flex flex-col">
        <header className="box-border flex bg-white p-2">
          <div className="w-full sm:w-4/5 sm:mx-auto">
            <div className="flex justify-center">
            <a href="/" className="py-0 inline-block my-5 mr-auto"><img  src={trueLogo} alt="True Logo" style={{height: "50px"}}/></a>
            <div className="my-auto pr-4">
              <a onClick={onLanguageToggle} href="#" name="th-TH" className={`inline-block text-24px leading-none align-middle mr-4 ${language === "th-TH" ? "text-app-primary font-bold" : "text-black"}`}>ไทย</a>
              <span className="text-24px leading-none align-middle">|</span>
              <a onClick={onLanguageToggle} href="#" name="en-US" className={`inline-block text-24px leading-none align-middle ml-4 ${language === "en-US" ? "text-app-primary font-bold" : "text-black"}`}>English</a>
            </div>
            </div>
          </div>
        </header>
          <Body title={data.termsAndConditionsTitle} content={data.termsAndConditions}/>
          <Footer />
      </div>
    </Fragment>
    )
  );
};

export default TermsAndConditions;
