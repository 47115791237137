import React from "react";
import PropTypes from "prop-types";
import Troubleshoot from "./Troubleshoot";
import TroubleshootLiquid from "./TroubleshootLiquid";
import TroubleshootPower from "./TroubleshootPower";
import TroubleshootOthers from "./TroubleshootOthers";

const View = ({ showComponent, perilType = "ScreenRepair", inWarranty = false }) => {
  console.log("showComponent", showComponent)
  return (
    <>
      {/*showComponent === "Troubleshoot" && <Troubleshoot perilType={perilType} inWarranty={inWarranty}/>*/}
      {/* {showComponent === "Troubleshoot" && <Troubleshoot perilType="InWarrantyBatteryRepair" inWarranty/>} */}
      {/* {showComponent === "Troubleshoot" && <Troubleshoot perilType="InWarrantyScreenAndBatteryRepair" inWarranty/>} */}
      {showComponent === "TroubleshootLiquid" && <TroubleshootLiquid perilType={perilType} inWarranty={inWarranty}/>}
      {showComponent === "TroubleshootPower" && <TroubleshootPower perilType={perilType} inWarranty={inWarranty}/>}
      {showComponent === "TroubleshootOthers" && <TroubleshootOthers perilType={perilType} inWarranty={inWarranty}/>}
    </>
  );
};

View.propTypes = {
  showComponent: PropTypes.string.isRequired,
  perilType: PropTypes.string.isRequired,
  inWarranty: PropTypes.bool,
};

export default View;
