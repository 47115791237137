import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { TextMedium, TextRegular } from "../../../base/BaseText";
import BaseTable, {
  BaseTR,
  BaseTH,
  BaseTD,
} from "../../../base/BaseTable/BaseTable";

import { redirectToPhoneNumber, changePURSchedule } from "../state/operators/scheduleOperator";
import { getFormmatedDate } from "../../../helpers/dateUtils";
import { ReactComponent as ScheduleIcon } from "../../../images/true/icon-calendar-selected.svg";
import { EnumEnquiryModules } from "../../../components/EnquiryOptions/Constants";

const ConfirmDetails = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const scheduleData = useSelector((state) => state.claim.schedule);

  const enquiryOption = useSelector(
    (state) => state.journeyMessages.enquiryOption
  );
  const isChangeSchedule = enquiryOption === EnumEnquiryModules.ChangeSchedule;

  const tableHeaders = [
    { key: "pickupAddress", text: `${t("ConfirmRepairTableDetails.LabelforPickup")}` },
    { key: "returnAddress", text: `${t("ConfirmRepairTableDetails.LabelforReturn")}` },
    { key: "pickupDateTime", text: `${t("ConfirmRepairTableDetails.LabelforPickupDateTime")}` },
    { key: "returnDateTime", text: `${t("ConfirmRepairTableDetails.LabelforReturnDateTime")}` },
  ];

  const getAddressText = (address) => {
    const { Address1, Address2, Address3, City, PostalCode } = address;
    return `${Address1} ${Address2} ${Address3} ${City} ${PostalCode}`;
  };

  const tableData = [
    {
      pickupAddress: getAddressText(scheduleData.selectedPickupAddress),
      returnAddress: getAddressText(scheduleData.selectedDeliveryAddress),
      pickupDateTime: (
        <span>
          {`${getFormmatedDate(scheduleData.selectedPickupDate, "d MMM yyyy")},`}
          <br />
          {`${getFormmatedDate(scheduleData.selectedPickupDate, "D")}`}
          <br />
          {`${scheduleData.selectedPickupSlot}`}
        </span>
      ),
      returnDateTime: (
        <span>
          {`${getFormmatedDate(
            scheduleData.selectedDeliveryDate,
            "d MMM yyyy"
          )},`}
          <br />
          {`${getFormmatedDate(scheduleData.selectedDeliveryDate, "D")}`}
          <br />
          {`${scheduleData.selectedDeliverySlot}`}
        </span>
      ),
    },
  ];

  const [disabled, setDisabled] = useState(false);
  const [confirmationStatus, setConfirmationStatus] = useState("");

  const handleConfirmDetails = (status) => {
    const textPrint = t(`${status}Button`);
    setConfirmationStatus(status);
    setDisabled(true);
    // dispatch(redirectToPhoneNumber(status));
    if(isChangeSchedule)
      dispatch(changePURSchedule(status, textPrint));
    else
      dispatch(redirectToPhoneNumber(status, textPrint));
  };

  return (
    <BaseCard>
      {/* <BaseIcon icon={ScheduleIcon} name="Tracking" center /> */}
      <ScheduleIcon className="app-BaseIcon-center" />
      <BaseLabel className="font-bold leading-none">
        <TextMedium>
          <Trans i18nKey="ConfirmScheduleDetails.Title">
            Please confirm the details <br />
            of pick-up and return for your device
          </Trans>
        </TextMedium>
      </BaseLabel>
      <BaseTable title={t("ConfirmScheduleDetails.Table.Title")}>
        {tableHeaders.map((header) => (
          <BaseTR key={header.key}>
            <BaseTH>{header.text}</BaseTH>
            {tableData.map((data, i) => (
              <BaseTD key={`${header.key}_${i}`}>
                <TextRegular>{data[header.key]}</TextRegular>
              </BaseTD>
            ))}
          </BaseTR>
        ))}
      </BaseTable>
      <BaseButtonGroup>
        <BaseButton
          text={t("ChangeButton")}
          onClick={() => handleConfirmDetails("Change")}
          disabled={disabled}
          clicked={confirmationStatus === "Change"}
          className="bg-white border-app-primary text-app-primary"
        />
        <BaseButton
          className="bg-app-nova border-app-nova"
          text={t("ConfirmButton")}
          onClick={() => handleConfirmDetails("Confirm")}
          disabled={disabled}
          clicked={confirmationStatus === "Confirm"}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ConfirmDetails;
