import React from "react";
import { Trans } from "react-i18next";

import { TextMedium, TextRegular } from "../../../base/BaseText";

import { ReactComponent as CameraIcon } from "../../../images/icon-camera.svg";
import { ReactComponent as PowerIcon } from "../../../images/icon-power-button.svg";
import { ReactComponent as LiquidExposeIcon } from "../../../images/icon-liquid-expose.svg";
import { ReactComponent as PhysicalDamageIcon } from "../../../images/icon-screen-damage.svg";
import { ReactComponent as BatteryIcon } from "../../../images/ic-battery.svg";
import { ReactComponent as BloatedIcon } from "../../../images/ic-bloated.svg";
import BatteryCheckImage from "../../../images/battery-check.png";
import IOSBatteryCheckImage from "../../../images/ios-battery-health-check.png";

// For screen repair and screen-battery repair both
const CameraWorking = {
  icon: <CameraIcon className="w-16" />,
  title: <TextRegular>My device's camera is in good working condition.</TextRegular>,
  notes: {},
};

// Only for screen-repair
const PowerOn = {
  icon: <PowerIcon className="w-16" />,
  title: <TextRegular>My device is able to power on.</TextRegular>,
  notes: {},
};

// For all 3 perils
const LiquidExposure = {
  icon: <LiquidExposeIcon className="w-16" />,
  title: <TextRegular>My device has not been exposed to any liquid.</TextRegular>,
  notes: {},
};

// For all 3 perils
const PhysicalDamage = {
  icon: <PhysicalDamageIcon className="w-16" />,
  title: (
    <TextRegular>
      Other than the damage on the front screen, my device does not have any other damage such as:
    </TextRegular>
  ),
  notes: {
    ScreenRepair: [
      "device being bent",
      "cracks or damage on the back of device.",
    ],
    BatteryReplacement: ["device being bent", "any physical damage on the device such as cracks, or chips, on the device."],
    BatScrReplacement: [
      "device being bent",
      "cracks or damage on the back of device.",
    ],
  },
};

//
const DeviceBloating = {
  icon: <BloatedIcon className="w-16" />,
  title: <TextRegular>My device is not bloated or swollen.</TextRegular>,
  notes: {},
};

//
const BatteryIndicator = (showLink = true) => {
  return {
    icon: <BatteryIcon className="w-16" />,
    title: <TextRegular>The phone battery health indicator is:</TextRegular>,
    notes: {
      ScreenRepair: [],
      BatteryReplacement: ["Apple device : less than 80%.", "Samsung device: bad / weak."],
      BatScrReplacement: ["Apple device : less than 80%.", "Samsung device: bad / weak."],
    },
    link: showLink && <TextRegular>How to check my device battery health</TextRegular>,
  };
};

const IOSDevice = () => (
  <div className="flex flex-col items-center h-full overflow-y-auto">
    <div className="text-2 text-srgray-base">
      <TextRegular>Battery Health Check Guide</TextRegular>
    </div>
    <div className="text-default text-srgray-base">
      <TextRegular>Check Battery Health on Apple Devices</TextRegular>
    </div>
    <div className="mt-4 w-full flex justify-center">
      <div className="w-5/6 pl-6">
        <ol className="list-outside list-decimal text-srgray-base text-default leading-7">
          <li>
            <Trans i18nKey="BatteryHealthCheck.IOS.1" />
          </li>
          <li>
            <Trans i18nKey="BatteryHealthCheck.IOS.2" />
          </li>
          <li>
            <Trans i18nKey="BatteryHealthCheck.IOS.3" />
          </li>
          <li>
            <Trans i18nKey="BatteryHealthCheck.IOS.4" />
          </li>
        </ol>
      </div>
    </div>
    <div className="w-full pb-20 mt-8">
      <img className="mx-auto" src={IOSBatteryCheckImage} alt="ios-battery-check" />
    </div>
  </div>
);

const AndroidDevice = () => (
  <div className="flex flex-col items-center h-full overflow-y-auto">
    <div className="text-2 text-srgray-base">
      <TextMedium>Battery Health Check Guide</TextMedium>
    </div>
    <div className="text-default text-srgray-base">
      <TextRegular>Check Battery Status on Samsung Devices</TextRegular>
    </div>
    <div className="mt-4 w-full flex justify-center">
      <div className="w-5/6 pl-6">
        <ol className="list-outside list-decimal text-srgray-base text-default leading-7">
          <li>
            <Trans i18nKey="BatteryHealthCheck.Android.1">
              <TextRegular>
                Download the <span className="text-app-secondary">Samsung Members</span> app onto your device.
              </TextRegular>
            </Trans>
          </li>
          <li>
            <TextRegular>
              <Trans i18nKey="BatteryHealthCheck.Android.2" />
            </TextRegular>
          </li>
          <li>
            <TextRegular>
              <Trans i18nKey="BatteryHealthCheck.Android.3" />
            </TextRegular>
          </li>
          <li>
            <TextRegular>
              <Trans i18nKey="BatteryHealthCheck.Android.4" />
            </TextRegular>
          </li>
          <li>
            <TextRegular>
              <Trans i18nKey="BatteryHealthCheck.Android.5" />
            </TextRegular>
          </li>
          <li>
            <TextRegular>
              <Trans i18nKey="BatteryHealthCheck.Android.6" />
            </TextRegular>
          </li>
          <li>
            <TextRegular>
              <Trans i18nKey="BatteryHealthCheck.Android.7" />
            </TextRegular>
          </li>
          <li>
            <TextRegular>
              <Trans i18nKey="BatteryHealthCheck.Android.8" />
            </TextRegular>
          </li>
        </ol>
      </div>
    </div>
    <div className="w-full pb-20">
      <img className="mx-auto" src={BatteryCheckImage} alt="android-battery-check" />
    </div>
  </div>
);

const getDamagesByPerilType = (type, isDeviceAppleSamsung) => {
  if (type === "ScreenRepair") {
    return {
      PowerOn,
      CameraWorking,
      LiquidExposure,
      PhysicalDamage,
    };
  }

  if (type === "BatteryReplacement") {
    const triage = {
      LiquidExposure,
      PhysicalDamage,
      DeviceBloating,
      BatteryIndicator: BatteryIndicator(),
    };

    if(!isDeviceAppleSamsung) delete triage.BatteryIndicator;
    return triage;
  }

  if (type === "BatScrReplacement") {
    const triage = {
      CameraWorking,
      LiquidExposure,
      PhysicalDamage,
      DeviceBloating,
      BatteryIndicator: BatteryIndicator(),
    };

    if(!isDeviceAppleSamsung) delete triage.BatteryIndicator;
    return triage;
  }

  if (type === "InWarrantyScreenRepairWarranty") {
    const triage = {
      LiquidExposure,
      PhysicalDamage,
      BatteryIndicator: BatteryIndicator(false),
    };

    if(!isDeviceAppleSamsung) delete triage.BatteryIndicator;
    return triage;
  }

  if (type === "InWarrantyBatteryReplacement") {
    const triage = {
      LiquidExposure,
      PhysicalDamage,
      BatteryIndicator: BatteryIndicator(false),
    };

    if(!isDeviceAppleSamsung) delete triage.BatteryIndicator;
    return triage;
  }

  if (type === "InWarrantyBatScrReplacement") {
    const triage = {
      LiquidExposure,
      PhysicalDamage,
      BatteryIndicator: BatteryIndicator(false),
    };

    if(!isDeviceAppleSamsung) delete triage.BatteryIndicator;
    return triage;
  }
};

export default {
  getDamagesByPerilType,
  IOSDevice,
  AndroidDevice,
};
