import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import { ReactComponent as WarrantyIcon } from "../../../images/icon-warranty.svg";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import { TextMedium } from "../../../base/BaseText";
import BaseInformationBox from "../../../base/BaseInformationBox";

import { warrantyConfirmation } from "../state/operators/deviceConfirmationOperators";
import {
  INCIDENT_BATTERY_AND_SCREEN,
  INCIDENT_BATTERY_REPLACEMENT,
  INCIDENT_SCREEN_REPAIR,
  INCIDENT_SCREEN_REPAIR_WARRANTY,
} from "../../../components/EnquiryOptions/Constants";

const WarrantyConfirmation = () => {
  const { t } = useTranslation();
  const { caseType } = useSelector((state) => state.journeyMessages);
  const perilType = caseType && caseType.replace(/\s+/g, "");
  const fee = useSelector((state) => state.claim.payment.serviceFee?.[perilType]?.TotalAmount);

  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [selection, setSelection] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    setTotalAmount(fee)
  }, [])

  
  const Information = () => {
    if ([INCIDENT_SCREEN_REPAIR, INCIDENT_SCREEN_REPAIR_WARRANTY].includes(caseType) || !caseType) {
      return (
        <ul className="ml-8 list-disc list-outside text-default leading-6 font-TMedium space-y-4">
          <li>
            <Trans i18nKey="WarrantyConfirmation.ScreenRepair.1">
              The Fee is <strong>${{totalAmount}}</strong> (inclusive of GST).<br />*If you are eligible for an in-warranty request, you will not be charged.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="WarrantyConfirmation.ScreenRepair.2" />
          </li>
          <li>
            <Trans i18nKey="WarrantyConfirmation.ScreenRepair.3" />
          </li>
          <li>
            <Trans i18nKey="WarrantyConfirmation.ScreenRepair.4" />
          </li>
        </ul>
      );
    }

    if (caseType === INCIDENT_BATTERY_REPLACEMENT) {
      return (
        <ul className="ml-8 list-disc list-outside text-default leading-6 font-TMedium space-y-4">
          <li>
            <Trans i18nKey="WarrantyConfirmation.BatteryReplacement.1">
              The Fee is <strong>${{totalAmount}}</strong> (inclusive of GST).<br />*If you are eligible for an in-warranty request, you will not be charged.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="WarrantyConfirmation.BatteryReplacement.2" />
          </li>
          <li>
            <Trans i18nKey="WarrantyConfirmation.BatteryReplacement.3" />
          </li>
        </ul>
      );
    }

    if (caseType === INCIDENT_BATTERY_AND_SCREEN) {
      return (
        <ul className="ml-8 list-disc list-outside text-default leading-6 font-TMedium space-y-4">
          <li>
            <Trans i18nKey="WarrantyConfirmation.ScreenRepairAndBatteryReplacement.1">
              The Fee is <strong>${{totalAmount}}</strong> (inclusive of GST).<br />*If you are eligible for an in-warranty request, you will not be charged.
            </Trans>
          </li>
          <li>
            <Trans i18nKey="WarrantyConfirmation.ScreenRepairAndBatteryReplacement.2" />
          </li>
          <li>
            <Trans i18nKey="WarrantyConfirmation.ScreenRepairAndBatteryReplacement.3" />
          </li>
          <li>
            <Trans i18nKey="WarrantyConfirmation.ScreenRepairAndBatteryReplacement.4" />
          </li>
          <li>
            <Trans i18nKey="WarrantyConfirmation.ScreenRepairAndBatteryReplacement.5" />
          </li>
        </ul>
      );
    }
    return null;
  };

  const handleSubmit = (answer) => {
    setSelection(answer);
    dispatch(warrantyConfirmation(answer));
    setDisabled(true);
  };

  return (
    <div className="app-PrepareRequirements">
      <BaseCard className="space-y-4">
        <WarrantyIcon className="app-BaseIcon-center" />
        <BaseInformationBox>
          <Information />
        </BaseInformationBox>
        <BaseLabel>
          <Trans i18nKey="WarrantyConfirmation.DoYouWishToProceed">
            <TextMedium>Do you wish to proceed ?</TextMedium>
          </Trans>
        </BaseLabel>
        <BaseButtonGroup>
          <BaseButton
            className="px-12 bg-app-nova border-app-nova"
            text={t("YesButton")}
            onClick={() => handleSubmit("Yes")}
            disabled={disabled}
            clicked={selection === "Yes"}
          />
          <BaseButton
            className="px-12"
            text={t("NoButton")}
            onClick={() => handleSubmit("No")}
            disabled={disabled}
            clicked={selection === "No"}
          />
        </BaseButtonGroup>
      </BaseCard>
    </div>
  );
};

export default WarrantyConfirmation;
