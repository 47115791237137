import { TextRegular } from "../../../base/BaseText";
import TurnOffMyDeviceImg from "../../../images/turn-off-my-device.png";

const TurnOffFindMyDevice = ({ title }) => {
    return <div className="bg-white">
    <div className="px-0 pb-0 md:px-12 md:pb-12">
      <div className="text-4xl text-center pop-up-modal-headers">{title}</div>
      <div className="mt-8 text-center">
        <div className="text-1.6 text-left"><TextRegular>We cannot accept if your device is linked to your apple account and we'll charge you Inoperable Device Fee which is like paying for our outright in today's value. Below is the step on how to turn off the Find My iPhone feature of your device.</TextRegular></div>
        <div className="flex mt-8">
            <img className="p-0" src={TurnOffMyDeviceImg} alt="turn-off-my-device"/>
        </div>
      </div>
    </div>
  </div>
}

export default TurnOffFindMyDevice;