import {
    addJourneyMessages,
    sleep,
} from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";

import {
    API_PROGRESS,
    COMPONENTS,
    PROGRESS_DATA,
} from "../../../../helpers/constants";
import {
    clearApiProgress,
    endFlow,
    serviceUnavailable,
    updateApiProgress,
    updateFlowProgress,
} from "../../../../actions/flowWindow";
import { initializePickupSchedule } from "./scheduleOperator";
import { updateContactDetailsData } from "./contactsOperator";
import { setRepairOption } from "../../../../reducers/journeyMessagesReducer";
import {
    setSelectedAppointmentDate,
    setSelectedAppointmentDateTime,
    setSelectedStore,
} from "../reducers/walkInReducer";
import { isEmpty } from "../../../../helpers/formatUtils";
import {
    createRepairRequestApi,
    getShippingAddressApi,
    serviceOrderApi,
    standardizeAddressApi,
    updateShippingAddressApi,
} from "../actions/addressApi";
import {
    getAppointments,
    getStores,
    updateAppointment,
} from "../actions/walkInApi";
//   import {
//     getFulFillmentApi,
//     setFulFillmentApi,
//   } from "../actions/fulfillment";
import {
    getFulFillmentApi,
    setFulFillmentApi,
} from "../actions/actions";
import {
    covertToUTCDate,
    getFormatedTime,
    getFormmatedDate,
    isToday,
} from "../../../../helpers/dateUtils";
import { EnumEnquiryModules } from "../../../../components/EnquiryOptions/Constants";
import i18n from 'i18next';
import moment from "moment";

export const displayRepairDetails =
    (textPrint) => async (dispatch, getStore) => {
        const module = "REPAIR_DETAILS";

        dispatch(
            addJourneyMessages([
                createMessage("TEXT", "user", textPrint),
                createMessage(module, "system"),
            ])
        );
    };

export const displayRepairOption =
    (textPrint) => async (dispatch, getStore) => {
        const module = "SELECT_REPAIR_OPTION";
        const flowTitle = i18n.t("Constants.PROGRESS_DATA.SELECT_REPAIR_OPTIONS.title");
        const flowCount = i18n.t("Constants.PROGRESS_DATA.SELECT_REPAIR_OPTIONS.count");
        const initial = getStore().journeyMessages.flowProgress.percentage;
        dispatch(updateFlowProgress(flowTitle, flowCount, initial));
        dispatch(
            addJourneyMessages([
                createMessage("TEXT", "user", textPrint),
                createMessage(module, "system"),
            ])
        );
    };

export const confirmationOfRepairOption =
    (repairOption, textPrint) => async (dispatch, getStore) => {
        let showComponent, module, flowTitle, flowCount;

        dispatch(saveRepairOptionType(repairOption));
        dispatch(addJourneyMessages([createMessage("TEXT", "user", textPrint)]));

        const enquiryOption = getStore().journeyMessages.enquiryOption;
        const isChangeSchedule =
            enquiryOption === EnumEnquiryModules.ChangeSchedule;
        const determinDetails = getStore().serviceRequest.determineIncidentDetails;

        const { CacheId } = getStore().session.sessionData.Configurations;
        const { selectedAsset } = getStore().claim.deviceConfirmation;
        const { ServiceRequestId } = isChangeSchedule
            ? determinDetails.ServiceRequest
            : getStore().serviceRequest.serviceRequestDetails;

        const deviceMake = isChangeSchedule
            ? determinDetails.ClaimedAsset.Make
            : selectedAsset.Make.Name;

        const { RepairRequest, ServiceOrder } = determinDetails;

        if (repairOption === "PUR") {
            if (isEmpty(ServiceOrder) || isEmpty(RepairRequest)) {
                const getFulFillmentApiResponse = await dispatch(
                    getFulFillmentApi(CacheId, ServiceRequestId)
                ).catch((err) => dispatch(serviceUnavailable()));
    
                // not allowing to go ahead if response is empty
                if (isEmpty(getFulFillmentApiResponse)) {
                    return;
                }
    
                const setFulFillmentApiResponse = await dispatch(
                    setFulFillmentApi("PUR", CacheId, ServiceRequestId)
                ).catch((err) => dispatch(serviceUnavailable()));
    
                // not allowing to go ahead if response is empty
                if (isEmpty(setFulFillmentApiResponse)) {
                    return;
                }
            }
            
            await dispatch(initializePickupSchedule());

            module = "PICKUP_AND_DELIVERY";
            showComponent = "confirmPickupAddress";

            flowTitle = i18n.t("Constants.PROGRESS_DATA.CONFIRM_PICKUP_DETAILS.title");
            flowCount = i18n.t("Constants.PROGRESS_DATA.CONFIRM_PICKUP_DETAILS.count");
        } else {
            showComponent = "SelectRepairCenter";
            module = "WALK_IN";

            flowTitle = i18n.t("Constants.PROGRESS_DATA.WALK_IN.title");
            flowCount = i18n.t("Constants.PROGRESS_DATA.WALK_IN.count");

            await dispatch(
                updateApiProgress(
                    i18n.t("Constants.API_PROGRESS.GET_STORE_LIST"),
                    40,
                    COMPONENTS.STORE_LIST,
                    0
                )
            );

            // API call to get device stores list
            const getStoresResponse = await dispatch(
                getStores(CacheId, deviceMake.toUpperCase())
            ).catch((err) => dispatch(serviceUnavailable()));

            if (isEmpty(getStoresResponse)) {
                return;
            }

            // After API call
            await dispatch(
                updateApiProgress(
                    i18n.t("Constants.API_PROGRESS.GET_STORE_LIST_SUCCESS"),
                    100,
                    COMPONENTS.STORE_LIST,
                    40
                )
            );
            await sleep(1000);
            await dispatch(clearApiProgress());
        }

        dispatch(
            addJourneyMessages([createMessage(module, "system", { showComponent })])
        );

        const initial = getStore().journeyMessages.flowProgress.percentage;
        dispatch(updateFlowProgress(flowTitle, flowCount, initial));
    };

export const confirmationOfRepairCenter =
    (asp) => async (dispatch, getStore) => {
        console.log("SELECTED ASP:", asp)
        const enquiryOption = getStore().journeyMessages.enquiryOption;
        const isChangeSchedule =
            enquiryOption === EnumEnquiryModules.ChangeSchedule;
        const determinDetails = getStore().serviceRequest.determineIncidentDetails;

        const { CacheId } = getStore().session.sessionData.Configurations;
        const { ServiceRequestId } = isChangeSchedule
            ? determinDetails.ServiceRequest
            : getStore().serviceRequest.serviceRequestDetails;

        const { RepairRequest, ServiceOrder } = determinDetails;

        dispatch(
            addJourneyMessages([
                createMessage("TEXT", "user", asp.SERVICE_PROVIDER_NAME),
            ])
        );

        await dispatch(
            updateApiProgress(
                i18n.t("Constants.API_PROGRESS.TROUBLESHOOT"),
                40,
                COMPONENTS.TERMS_N_CONDITIONS
            )
        );

        if (isEmpty(ServiceOrder) || isEmpty(RepairRequest)) {
            const getFulFillmentApiResponse = await dispatch(
                getFulFillmentApi(CacheId, ServiceRequestId)
            ).catch((err) => dispatch(serviceUnavailable()));

            // not allowing to go ahead if response is empty
            if (isEmpty(getFulFillmentApiResponse)) {
                return;
            }

            const setFulFillmentApiResponse = await dispatch(
                setFulFillmentApi("WALKIN", CacheId, ServiceRequestId)
            ).catch((err) => dispatch(serviceUnavailable()));

            // not allowing to go ahead if response is empty
            if (isEmpty(setFulFillmentApiResponse)) {
                return;
            }
        }

        const getAppointmentsApiResponse = await dispatch(
            getAppointments(CacheId, asp.SERVICE_PROVIDER_ID)
        ).catch((err) => dispatch(serviceUnavailable()));

        // not allowing to go ahead if response is empty
        if (isEmpty(getAppointmentsApiResponse)) {
            return;
        }

        await dispatch(
            updateApiProgress(
                i18n.t("Constants.API_PROGRESS.TROUBLESHOOT_SUCCESS"),
                100,
                COMPONENTS.TERMS_N_CONDITIONS,
                40
            )
        );
        await sleep(1000);
        await dispatch(clearApiProgress());

        const module = "WALK_IN";

        let showComponent, flowTitle, flowCount;

        // check if there are appointment slots available or not
        if (getAppointmentsApiResponse && getAppointmentsApiResponse.length !== 0) {
            // slots available
            dispatch(saveSelectedRepaircenter(asp));

            showComponent = "BookAppointment";

            flowTitle = i18n.t("Constants.PROGRESS_DATA.SELECT_APPOINTMENT.title");
            flowCount = i18n.t("Constants.PROGRESS_DATA.SELECT_APPOINTMENT.count");
        } else {
            // no slots available shows repair center again
            showComponent = "SelectRepairCenter";

            flowTitle = i18n.t("Constants.PROGRESS_DATA.WALK_IN.title");
            flowCount = i18n.t("Constants.PROGRESS_DATA.WALK_IN.count");

            await dispatch(
                addJourneyMessages([
                    createMessage("TEXT", "system", { key: "WalkIn.NoAppointmentSlot" }),
                ])
            );
        }

        dispatch(
            addJourneyMessages([createMessage(module, "system", { showComponent })])
        );

        const initial = getStore().journeyMessages.flowProgress.percentage;
        dispatch(updateFlowProgress(flowTitle, flowCount, initial));
    };

export const appointmentSelected =
    (textPrint, date, timeSlot) => async (dispatch, getStore) => {
        const showComponent = "ConfirmAppointment";
        const module = "WALK_IN";

        const flowTitle = i18n.t("Constants.PROGRESS_DATA.CONFIRM_APPOINTMENT.title");
        const flowCount = i18n.t("Constants.PROGRESS_DATA.CONFIRM_APPOINTMENT.count");

        dispatch(
            addJourneyMessages([
                createMessage("TEXT", "user", textPrint),
                createMessage(module, "system", { showComponent }),
            ])
        );

        const initial = getStore().journeyMessages.flowProgress.percentage;
        dispatch(updateFlowProgress(flowTitle, flowCount, initial));

        dispatch(saveAppointmentDateTime(date, timeSlot));
    };

export const confirmationOfAppointment =
    (textPrint, status) => async (dispatch, getStore) => {
        dispatch(addJourneyMessages([createMessage("TEXT", "user", textPrint)]));

        if (status === "Keep") {
            await dispatch(updateContactDetailsData());

            const enquiryOption = getStore().journeyMessages.enquiryOption;
            const isChangeSchedule =
                enquiryOption === EnumEnquiryModules.ChangeSchedule;
            const determinDetails =
                getStore().serviceRequest.determineIncidentDetails;

            const { RepairRequest, ServiceOrder } = determinDetails;

            const { CacheId } = getStore().session.sessionData.Configurations;
            const { ServiceRequestId, CustomerCaseId } = isChangeSchedule
                ? determinDetails
                : getStore().serviceRequest.serviceRequestDetails;

            const {
                selectedAppointmentDate,
                selectedAppointmentSlot,
                selectedStore,
            } = getStore().claim.walkIn;
            if (isChangeSchedule) {
                const ServiceOrderId = ServiceOrder.ServiceOrderId;
                await dispatch(
                    updateApiProgress(
                        i18n.t("Constants.API_PROGRESS.UPDATE_WALKIN_SCHEDULE"),
                        40,
                        COMPONENTS.UPDATE_SCHEDULE,
                        0
                    )
                );

                const appointmentDate = `${getFormmatedDate(
                    selectedAppointmentDate,
                    "d MMM yyyy"
                )} ${selectedAppointmentSlot}`;
                const localDatetimeString = moment(`${selectedAppointmentDate} ${getFormatedTime(appointmentDate, false, "hh:mm", ":")} +07:00`);
                const utcAppointmentDateTime = localDatetimeString.utc().format('YYYY-MM-DD hh:mm:ss A')
                
                // call update appointment api here
                const UpdateRepairRequestParameter = {
                    SessionId: CacheId,
                    CustomerCaseId: CustomerCaseId,
                    ServiceRequestId: ServiceRequestId,
                    ServiceOrderId: ServiceOrderId,
                    RepairRequestType: "WALKIN",
                    AppointmentDate: utcAppointmentDateTime,
                    RepairStatus: "SCHEDULED",
                    UpdateAction: "CHANGESUBMITTEDAPPOINTMENT",
                    ServiceProviderId: selectedStore
                        ? selectedStore.SERVICE_PROVIDER_ID
                        : RepairRequest.ServiceProviderId,
                };

                let updateAppointmentResponse = await dispatch(
                    updateAppointment(UpdateRepairRequestParameter)
                ).catch((err) => dispatch(serviceUnavailable()));

                // not allowing to go ahead if response is empty
                if (isEmpty(updateAppointmentResponse)) {
                    return;
                }
                //show appointment details and thank you page
                await dispatch(
                    updateApiProgress(
                        i18n.t("Constants.API_PROGRESS.UPDATE_WALKIN_SCHEDULE_SUCCESS"),
                        100,
                        COMPONENTS.UPDATE_SCHEDULE,
                        40
                    )
                );
                await sleep(1500);
                await dispatch(clearApiProgress());

                dispatch(
                    addJourneyMessages([
                        createMessage("TEXT", "system", {
                            key: "SystemMessage.UpdateAppointmentConfirmation",
                            values: {
                                appointmentDate,
                            },
                        }),
                        createMessage("TEXT", "system", {
                            key: "SystemMessage.RequestSMSConfirmation",
                        }),
                    ])
                );

                await sleep(8000);

                dispatch(endFlow());
            } else {
                //const showComponent = "ChangeMobileNumber";
                const showComponent = "ConfirmMobileNumber";
                const module = "CONTACT_CONFIRMATION";

                const flowTitle = i18n.t("Constants.PROGRESS_DATA.CONFIRM_CONTACT.title");
                const flowCount = i18n.t("Constants.PROGRESS_DATA.CONFIRM_CONTACT.count");

                dispatch(
                    addJourneyMessages([
                        createMessage(module, "system", { showComponent }),
                    ])
                );

                const initial = getStore().journeyMessages.flowProgress.percentage;
                dispatch(updateFlowProgress(flowTitle, flowCount, initial));
            }
        } else {
            const showComponent = "BookAppointment";
            const module = "WALK_IN";

            const flowTitle = i18n.t("Constants.PROGRESS_DATA.SELECT_APPOINTMENT.title");
            const flowCount = i18n.t("Constants.PROGRESS_DATA.SELECT_APPOINTMENT.count");

            dispatch(
                addJourneyMessages([createMessage(module, "system", { showComponent })])
            );

            const initial = getStore().journeyMessages.flowProgress.percentage;
            dispatch(updateFlowProgress(flowTitle, flowCount, initial));
        }
    };

export const changeWalkInSchedule = () => async (dispatch, getStore) => {

    await dispatch(
        updateApiProgress(
            i18n.t("Constants.API_PROGRESS.GET_APPOINTMENT_DETAILS"),
            40,
            COMPONENTS.STORE_LIST,
            0
        )
    );

    const { CacheId } = getStore().session.sessionData.Configurations;
    const { RepairRequest } = getStore().serviceRequest.determineIncidentDetails;

    const getAppointmentsApiResponse = await dispatch(
        getAppointments(CacheId, RepairRequest.ServiceProviderId)
    ).catch((err) => dispatch(serviceUnavailable()));

    // not allowing to go ahead if response is empty
    if (isEmpty(getAppointmentsApiResponse)) {
        return;
    }

    await dispatch(
        updateApiProgress(
            i18n.t("Constants.API_PROGRESS.GET_APPOINTMENT_DETAILS_SUCCESS"),
            100,
            COMPONENTS.STORE_LIST,
            40
        )
    );
    await sleep(1000);
    await dispatch(clearApiProgress());

    if (getAppointmentsApiResponse && getAppointmentsApiResponse.length !== 0) {
        // slots available
        dispatch(
            addJourneyMessages([
                createMessage("WALK_IN", "system", {
                    showComponent: "BookAppointment",
                }),
            ])
        );
    } else {
        await dispatch(
            addJourneyMessages([
                createMessage("TEXT", "system", { key: "WalkIn.NoAppointmentSlot" }),
            ])
        );
    }
};

export const saveRepairOptionType = (repairOption) => (dispatch) => {
    dispatch({
        type: setRepairOption.toString(),
        payload: repairOption,
    });
};

const saveSelectedRepaircenter = (repairCenter) => (dispatch) => {
    dispatch({
        type: setSelectedStore.toString(),
        payload: repairCenter,
    });
};

export const saveAppointmentDateTime = (date, slot) => async (dispatch) => {
    await dispatch({
        type: setSelectedAppointmentDateTime.toString(),
        payload: { date, slot },
    });
    return date;
};
