import React from "react";
// import { ReactComponent as ThankYouImage } from "../images/image-thank-you.svg";
// import { ReactComponent as ThankYou } from "../images/thankyou.svg";
import ThankYouImg from "../images/true/img-mobile-thankyou.jpg";
// import { ReactComponent as CloseIcon } from "../images/true/icon-close-dark.svg";
import { useTranslation } from "react-i18next";
import BaseButton from "../base/BaseButton/BaseButton";

const ThankYouNote = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full bg-white flex flex-col overflow-hidden relative">
      <div className="w-full h-full">

        {/*<div className="flex justify-between w-full">
          <div className="w-px"></div>
          <div className="cursor-pointer p-6" onClick={onClose}>
            <CloseIcon />
          </div>
        </div>*/}

        <div className="bottom-0 h-full z-0 mx-auto w-full">
          <div className="h-full flex flex-col">
            <p className="text-24px font-bold text-center mt-16 mb-6">
              {t("ThankYouNote.Title")}
            </p>
            <BaseButton
              text={t("CloseButton")}
              onClick={onClose}
              autoFocus
              className="mx-auto w-11r mb-8"
            />
            <img
              className="object-cover h-full p-0 mx-auto"
              src={ThankYouImg}
              alt="Thank You"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouNote;
