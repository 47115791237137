import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isEmpty } from "../../helpers/formatUtils";

const BaseDropdown = ({
  label,
  placeHolder,
  value,
  onChange,
  options,
  className,
  identifier,
  printer,
  pureArray,
  disabled,
  ...restProps
}) => {
  const extraClasses = className ? className.split(" ") : [];
  return (
    <>
      {!isEmpty(label) && (
        <div className={classNames(
          "app-BaseDropdown__label",
          {"app-BaseDropdown__label__disabled": disabled}
        )}>{label}</div>
      )}
      <div
        className={classNames(
          "app-BaseDropdown",
          { "app-BaseDropdown__disabled": disabled },
          ...extraClasses
        )}
      >
        <select
          className="app-BaseDropdown__select"
          value={value}
          required
          disabled={disabled}
          {...restProps}
          onChange={(e) => onChange(e.target.value)}
        >
          {placeHolder && (
            <option value="" disabled hidden>
              {placeHolder}
            </option>
          )}
          {options.map((op) => {
            return pureArray ? (
              <option key={op} value={op} className="hover:text-app-nova">
                {op}
              </option>
            ) : (
              <option key={op[identifier]} value={op[identifier]}>
                {op[printer]}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );
};

BaseDropdown.defaultProps = {
  identifier: "id",
  printer: "text",
  value: "",
  pureArray: false,
  disabled: false,
  placeHolder: "Select",
};

BaseDropdown.propTypes = {
  label: PropTypes.string,
  placeHolder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  identifier: PropTypes.string,
  printer: PropTypes.string,
  pureArray: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default BaseDropdown;
