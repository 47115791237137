import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "./../images/true/icon-close-dark.svg";
import CloseWhiteIcon from "./../images/true/icon-close-white.svg";
import { TextRegular } from "../base/BaseText";
import BaseProgressBar from "../base/BaseProgressBar/BaseProgressBar";
import { useSelector } from "react-redux";
import { COMPONENTS } from "../helpers/constants";
import { getFormmatedDate, getFormatedTime } from "../helpers/dateUtils";
import { isEmpty } from "../helpers/formatUtils";
import { useTranslation, Trans } from "react-i18next";
import moment from "moment";
import 'moment/locale/th';

const HeaderProgress = ({ text, percentage, initial = 0 }) => {
  const { t } = useTranslation();
  return (
    <>
      {percentage > 0 && percentage < 100 ? (
        <div className="flex flex-row w-full">
          <div className="text-40px mr-10 relative font-bold leading-none">
            {percentage}
            <span className="font-TMedium text-22px font-normal absolute top-0 mt-2"><span>%</span></span>
          </div>
          <div className="pt-2 w-full pr-4">
            <div className="text-20px text-white font-normal leading-none">
              <TextRegular>{text}</TextRegular>
            </div>
            <BaseProgressBar
              className="h-2 mt-2 mb-0 w-full"
              percentage={percentage}
              initial={initial}
              type={COMPONENTS.FLOW_PROGRESS}
              uniqueId={COMPONENTS.FLOW_PROGRESS}
            />
          </div>
        </div>
      ) : percentage === 100 ? (
        <>{t("Constants.API_PROGRESS.REQUEST_SUBMIT_SUCCESS")}</>
      ) : (
        <div className="text-28px leading-none pl-2">
          <TextRegular>{t("WelcomeHeaderTitle")}</TextRegular>
        </div>
      )}
    </>
  );
};

const HeaderTime = () => {
  const [currentDateStr, setCurrentDateStr] = useState();
  let sessionStorageLang = sessionStorage.getItem("language") || "th-TH";
  moment.locale(sessionStorageLang === "en-US" ? "en" : "th");
  
  useEffect(() => {
    setInterval(() => setCurrentDateStr(new Date().toString()), 1000);
  }, []);

  const toMomentLocaleDate = (dateVal) => {
    let format = sessionStorageLang === "en-US" ? "DD MMM YYYY hh:mm a" : "lll";
    return moment(dateVal).format(format)
    // return moment(dateVal).format("DD MMM YYYY hh:mm a");
  }

  return (
    <div>
      {/*currentDateStr &&
        `${getFormmatedDate(
          currentDateStr,
          "dd MMM yyyy"
        )} ${getFormatedTime(currentDateStr)}`*/}
      {currentDateStr &&
        `${toMomentLocaleDate(currentDateStr)}`}
    </div>
  )
}

const FlowHeader = ({ onClose }) => {
  const [caseNo, setCaseNo] = useState();

  const caseNumberD = useSelector((state) =>
    state.serviceRequest.determineIncidentDetails
      ? state.serviceRequest.determineIncidentDetails.ServiceRequestNumber
      : ""
  );

  const caseNumberR = useSelector((state) =>
    state.serviceRequest.serviceRequestDetails
      ? state.serviceRequest.serviceRequestDetails.ServiceRequestNumber
      : ""
  );

  useEffect(() => {
    if (!isEmpty(caseNumberR)) {
      setCaseNo(caseNumberR);
    } else if (!isEmpty(caseNumberD)) {
      setCaseNo(caseNumberD);
    }
  }, [caseNumberD, caseNumberR]);

  const flowProgressData = useSelector(
    (state) => state.journeyMessages.flowProgress
  );

  useEffect(() => {
    console.log("flowProgressData", flowProgressData)
  }, [flowProgressData])

  return (
    <>
      <div className={`app__flow-header w-full ${flowProgressData && flowProgressData.text ? "text-white pb-4 bg-gradient-to-l from-truegradient-fromred to-truegradient-toyellow" : "text-srgray-base bg-white lg:rounded-t-3xl from-white to-white"}`}>
        <div className="app__flow-content">
          <HeaderProgress {...flowProgressData} />
        </div>
        <div className="app__flow-exit-button" onClick={onClose}>
          {flowProgressData && flowProgressData.text ?
            (<>
              <img className="inline-block" src={CloseWhiteIcon} alt="Close" />
            </>)
            :
            (<>
              <img className="inline-block" src={CloseIcon} alt="Close" />
            </>)
          }
        </div>
      </div>
      <div className="bg-white py-0 px-8 flex justify-between text-20px border-b-2 border-app-primary">
        <HeaderTime />
        {!isEmpty(caseNo) && <div>SR No. {caseNo}</div>}
      </div>
    </>
  );
};

FlowHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default FlowHeader;
